import React, { Component } from "react";
import BackofficeBanner from "../backofficeBanner";
import { Backdrop, Button, CircularProgress } from "@material-ui/core";
import moment from "moment";
import InputDate from "../../common/utils/inputDate";
import {
  PedidosPorEntregar,
  PrintPedido,
  PutOrdenPreparacionPedido,
  TienePermiso,
} from "../../services/backofficeService";

import "./pedidosPorEntregar.css";
import NumberFormat from "react-number-format";
import { saveAs } from "file-saver";
import { Card } from "react-bootstrap";
import { withRouter } from "react-router-dom";
import { FechaEntregaDefault } from "../../services/backoffice/bakofficeHelpers";

//TODO: Formatear total

class PedidosPorEntregarPage extends Component {
  state = {
    loading: false,
    pedidos: [],
    todosLosPedidos: [],
    total: 0.0,
    searchText: "",
    puedeCambiarOrden: false,
  };

  async componentDidMount() {
    var respuesta = await TienePermiso(
      this.props.sessionId,
      "Pedidos/porEntregar"
    );
    if (respuesta !== "") {
      alert(respuesta);
      window.location.href = "/backoffice";
      return;
    }

    var respuestaOrden = await TienePermiso(
      this.props.sessionId,
      "Pedidos/porEntregar/Orden"
    );
    this.setState({ puedeCambiarOrden: respuestaOrden === "" });

    await this.cargarPedidos(FechaEntregaDefault());
  }

  async cargarPedidos(date) {
    if (date == null) return;
    this.setState({ loading: true });
    try {
      let pedidos = await PedidosPorEntregar(date);

      pedidos = pedidos.sort(function (a, b) {
        return a.ordenPreparacion > b.ordenPreparacion ? 1 : -11;
      });
      let suma = this.totalPedidos(pedidos);
      this.setState({
        pedidos,
        fechaEntrega: date,
        total: suma,
        todosLosPedidos: pedidos,
      });
    } catch (exception) {
      //TODO: Dialog con el error
      alert("Error al cargar los pedidos por entregar");
      console.log("Error al cargar los pedidos por entregar", exception);
    } finally {
      this.setState({ loading: false });
    }
  }

  handleSearchTextChanged(searchText) {
    var searchTextLowerCase = searchText.toLowerCase();
    const peds = [...this.state.todosLosPedidos];
    const pedidos = peds.filter(
      (m) =>
        m.cliente.toLowerCase().includes(searchTextLowerCase) ||
        m.colonia.toLowerCase().includes(searchTextLowerCase) ||
        m.municipio.toLowerCase().includes(searchTextLowerCase) ||
        m.folio.toLowerCase().includes(searchTextLowerCase)
    );
    var suma = this.totalPedidos(pedidos);
    this.setState({ searchText, pedidos, total: suma });
  }

  totalPedidos(pedidos) {
    var suma = 0;
    pedidos.map((p) => {
      suma = suma + p.total;
    });

    return suma;
  }

  async setOrdenPreparacion(pedido) {
    const listPedidos = [...this.state.pedidos];
    const pedidoIndex = listPedidos.findIndex(
      (x) => x.pedidoId === pedido.pedidoId
    );
    listPedidos[pedidoIndex].ordenPreparacion = pedido.ordenPreparacion;
    this.setState({ pedidos: listPedidos });
    await PutOrdenPreparacionPedido(pedido.pedidoId, pedido.ordenPreparacion);
  }

  render() {
    return (
      <div className="App">
        <BackofficeBanner title="Pedidos" showProgress={this.state.loading}>
          <div id="pedidosPorEntregarPage-mainFilter">
            <InputDate
              id="pedidosPorEntregarPage-dateFilter"
              label="Fecha de entrega"
              value={this.state.fechaEntrega}
              onChange={async (date) => {
                await this.cargarPedidos(date);
              }}
            />
            <Button
              id="pedidosPorEntregarPage-btnRefresh"
              variant="contained"
              color="inherit"
              onClick={async () =>
                await this.cargarPedidos(this.state.fechaEntrega)
              }
            >
              Refrescar
            </Button>
          </div>
        </BackofficeBanner>
        <div className="container-md mb-2">
          <input
            className="form-control form-control-lg"
            type="text"
            placeholder="Buscar"
            value={this.state.searchText}
            onChange={(e) =>
              this.handleSearchTextChanged(e.currentTarget.value)
            }
          />
        </div>
        <div id="pedidosPorEntregar-container" className="container">
          {this.state.pedidos.map((pedido) => (
            <div key={pedido.id}>
              <Card className="pedidoPorEntregar-card">
                <div className="pedidoPorEntregar-card-Pedido">
                  <div className="pedidoPorEntregar-card-Folio">
                    {pedido.folio}
                  </div>
                  <div className="pedidoPorEntregar-card-Nombre">
                    {pedido.cliente}
                  </div>
                  <div className="pedidoPorEntregar-card-ColoniaMunicipio-Total">
                    <div className="pedidoPorEntregar-card-ColoniaMunicipio">
                      <div className="pedidoPorEntregar-card-Colonia">
                        {pedido.colonia}
                      </div>
                      <div className="pedidoPorEntregar-card-Municipio">
                        {pedido.municipio}
                      </div>
                    </div>
                    <div className="pedidoPorEntregar-card-Total">
                      $
                      <NumberFormat
                        value={pedido.total}
                        displayType="text"
                        decimalScale="2"
                        fixedDecimalScale="2"
                        thousandSeparator
                      />
                    </div>
                  </div>
                  <div className="pedidoPorEntregar-card-ordenPreparacion">
                    <button
                      disabled={!this.state.puedeCambiarOrden}
                      className="btn btn-warning"
                      id="btnSubtract"
                      onClick={async () => {
                        if (pedido.ordenPreparacion <= 0) return;
                        pedido.ordenPreparacion--;
                        await this.setOrdenPreparacion(pedido);
                      }}
                    >
                      -
                    </button>
                    <input
                      disabled={!this.state.puedeCambiarOrden}
                      className="form-control form-control-lg"
                      type="text"
                      placeholder="Orden de preparación"
                      value={pedido.ordenPreparacion}
                      onChange={async (e) => {
                        if (!isNaN(e.currentTarget.value)) {
                          pedido.ordenPreparacion = e.currentTarget.value;
                          await this.setOrdenPreparacion(pedido);
                        }
                      }}
                    />
                    <button
                      disabled={!this.state.puedeCambiarOrden}
                      className="btn btn-success"
                      id="btnAdd"
                      onClick={async () => {
                        pedido.ordenPreparacion++;
                        await this.setOrdenPreparacion(pedido);
                      }}
                    >
                      +
                    </button>
                  </div>
                  <div className="pedidoPorEntregar-card-buttons"></div>
                </div>
                <Button
                  variant="contained"
                  color="secondary"
                  onClick={() => {
                    this.props.history.push(
                      `/backoffice/pedidos/pedidoPorEntregar/${pedido.pedidoId}`
                    );
                  }}
                >
                  Editar
                </Button>
                <Button
                  variant="contained"
                  color="primary"
                  style={{ marginTop: "3px" }}
                  onClick={async () => {
                    var blob = await PrintPedido(pedido.pedidoId);
                    saveAs(blob, `Pedido ${pedido.folio}.pdf`);
                  }}
                >
                  Imprimir
                </Button>
              </Card>
            </div>
          ))}
        </div>
        <div className="pedidosPorEntregar-footer">
          <div id="pedidosPorEntregar-count">
            <strong>{this.state.pedidos.length} </strong> Pedidos
          </div>
          <div id="pedidosPorEntregar-total">
            Total{" "}
            <strong>
              $
              <NumberFormat
                value={this.state.total}
                displayType="text"
                decimalScale="2"
                fixedDecimalScale="2"
                thousandSeparator
              />
            </strong>
          </div>
        </div>
        <Backdrop open={this.state.loading}>
          <CircularProgress color="inherit" />
        </Backdrop>
      </div>
    );
  }
}

export default withRouter(PedidosPorEntregarPage);

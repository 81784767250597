import React from "react";

import { Button, Form } from "react-bootstrap";
import { Dash, Trash } from "react-bootstrap-icons";

import "./commonControls.css";

const QuantityInput = ({
  cantidad,
  step = 1,
  max = Number.MAX_SAFE_INTEGER,
  decimals = 2,
  unidad,
  allowDelete,
  readOnly,
  onChange,
  onDelete,
}) => {
  const disminuirCantidad = () => {
    let nuevaCantidad = Number(cantidad - step).toFixed(decimals);
    if (nuevaCantidad >= 0) cambiarCantidad(nuevaCantidad);
  };

  const aumentarCantidad = () => {
    let nuevaCantidad = Number(cantidad + step).toFixed(decimals);
    if (nuevaCantidad < max) cambiarCantidad(nuevaCantidad);
  };

  const cambiarCantidad = (cantidad) => {
    if (isNaN(cantidad)) cantidad = Number(0);
    if (onChange) onChange(Number(cantidad));
  };

  const eliminar = () => {
    if (onDelete) onDelete(cantidad);
  };

  const botonDisminuir = () => {
    let variant = "warning";
    let buttonDisabled = false;
    let icon = <Dash />;
    let handler = disminuirCantidad;
    if (cantidad === 0) {
      if (allowDelete) {
        variant = "danger";
        icon = <Trash />;
        handler = eliminar;
      } else buttonDisabled = true;
    }
    if (readOnly) buttonDisabled = true;

    return (
      <Button variant={variant} disabled={buttonDisabled} onClick={handler}>
        {icon}
      </Button>
    );
  };

  return (
    <div className="quantityInput">
      {botonDisminuir()}
      <div className="commonInput__wrapper">
        <input
          className="commonInput__input centered"
          type="number"
          pattern="[0-9]*"
          inputMode="decimal"
          value={cantidad}
          onChange={(e) => cambiarCantidad(e.currentTarget.value)}
        />
        <div className="quantityInput__unidad">{unidad}</div>
      </div>

      <Button variant="success" disabled={readOnly} onClick={aumentarCantidad}>
        +
      </Button>
    </div>
  );
};

export default QuantityInput;

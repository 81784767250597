import React from "react";

import { Card, CardContent } from "@material-ui/core";
import NumberFormat from "react-number-format";

const OrdenCompraPartidaCard = ({ partida, onPartidaSeleccionada }) => {
  return (
    <Card
      className="comprasPage_Card"
      key={partida.id}
      onClick={(event) => onPartidaSeleccionada(partida)}
    >
      <CardContent className="comprasPage_Card_Content">
        <div className="comprasPage_Card_Content">
          <div id="comprasPage_Card_Content_imgDiv">
            <img
              src={partida.producto.imagenUrl}
              alt={partida.producto.descripcion}
            />
          </div>

          <div id="comprasPage_Card_Content_Producto">
            <div id="descripcion">{partida.producto.descripcion}</div>
            <div id="cantidad">
              {partida.cantidadSurtida > 0 && (
                <div className="cantidadSurtida">
                  Surtido:{" "}
                  <strong>
                    {" "}
                    <NumberFormat
                      value={partida.cantidadSurtida.toFixed(2)}
                      displayType="text"
                      decimalScale="2"
                      fixedDecimalScale="2"
                      thousandSeparator
                    />
                  </strong>{" "}
                  {partida.producto.unidad}{" "}
                  {partida.referencia && (
                    <span>
                      Ref: <strong>{partida.referencia}</strong>
                    </span>
                  )}
                </div>
              )}
              <div className="cantidadPendiente">
                Pendiente:{" "}
                <NumberFormat
                  value={(
                    partida.cantidadSolicitada - partida.cantidadSurtida
                  ).toFixed(2)}
                  displayType="text"
                  decimalScale="2"
                  fixedDecimalScale="2"
                  thousandSeparator
                />{" "}
                {partida.producto.unidad}
              </div>
            </div>
          </div>
        </div>
      </CardContent>
    </Card>
  );
};

export default OrdenCompraPartidaCard;

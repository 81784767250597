import React, { Component } from "react";
import MenuCategoriesList from "../common/menuCategoriesList";
import SearchBar from "../common/searchBar";
import AreaEntregaButton from "../common/areaEntrega/areaEntregaButton";
import ViewCartButton from "../tienda/pedido/viewCartButton";
import { Link, withRouter } from "react-router-dom";

class PageHeader extends Component {
  componentDidUpdate() {
    this.ocultaParaPortada();
  }

  //modifica el header para la portada
  ocultaParaPortada() {
    var heads = document.getElementById("menuCats");
    var areasb = document.getElementById("areaEntregaButton");
    var busq = document.getElementById("menuBus");
    var menc = document.getElementById("menuCarts");
    var heds = document.getElementById("header");
    var logs = document.getElementById("headerLogo");

    var enHome = this.props.location.pathname === "/";

    if (!enHome) {
      heads.style.display = "block";
      areasb.style.display = "none";
      busq.style.display = "block";
      heds.classList.add("header");
      heds.classList.remove("headerPortada");
      menc.classList.remove("carritoHeaderPortada");
      logs.style.height = "80px";
      logs.src = "/img/logo.svg";
    } else {
      heads.style.display = "none";
      areasb.style.display = "block";
      heds.classList.add("headerPortada");
      heds.classList.remove("header");
      menc.classList.add("carritoHeaderPortada");
      logs.style.height = "120px";
      logs.src = "/img/logoBlanco.svg";
    }
  }

  headerStyle = {
    minHeight: this.props.height,
    backgroundImage: "url(" + this.props.imageUrl + ")",
    backgroundRepeat: "no-repeat",
    backgroundPosition: "left center",
  };

  render() {
    return (
      <div className="header" id="header">
        <div className="cont">
          <div className="contF">
            <div className="headerLogo">
              <Link to="/">
                <img id="headerLogo" src="/img/logo.svg" height="80"></img>
              </Link>
            </div>

            <div id="headerMens" className="right">
              <div className="right" id="menuCarts">
                <ViewCartButton
                  contadorItems={this.props.pedidoContadorItems}
                />
              </div>

              <div className="right" id="menuBus">
                <SearchBar></SearchBar>
              </div>

              <div className="clear5"></div>
              <div className="right" id="menuCats">
                <MenuCategoriesList />
              </div>

              <div className="clear"></div>
              <div className="right" id="menuAres">
                <AreaEntregaButton />
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
window.addEventListener("scroll", function () {
  var distancia = window.pageYOffset;
  var element = document.getElementById("header");

  if (element)
    if (distancia > 150) {
      element.classList.add("headerP");
    } else {
      element.classList.remove("headerP");
    }
});

export default withRouter(PageHeader);

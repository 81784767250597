import React, { Component } from "react";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import Slide from "@material-ui/core/Slide";
import { TextField, DialogActions, DialogTitle } from "@material-ui/core";
import { CodigosPostales } from "../../services/codigosPostalesService";
const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="down" ref={ref} {...props} />;
});

class BuscarCodigoPostalDialog extends Component {
  state = { colonia: "", codigoSeleccionado: "", colonias: [] };
  handleBuscar = async () => {
    const colonias = CodigosPostales(this.state.colonia);
    this.setState({ colonias: colonias });
  };

  render() {
    return (
      <div id="areaEntregaDialog">
        <Dialog
          open={this.props.mostrar}
          onClose={this.props.onClose}
          onButtonClick={this.props.onClose}
          TransitionComponent={Transition}
          modal={false}
        >
          <DialogContent>
            <DialogTitle>
              <TextField
                autoFocus
                label="Colonia"
                onChange={({ currentTarget: input }) =>
                  this.setState({ colonia: input.value })
                }
                value={this.state.colonia}
              />
              <button onClick={this.handleBuscar}>Buscar</button>
            </DialogTitle>
          </DialogContent>
        </Dialog>
      </div>
    );
  }
}

export default BuscarCodigoPostalDialog;

import React, { Component, Fragment } from "react";
import { Link } from "react-router-dom";
import CategoriasProductos from "../services/categoriasService";
import { RegistrarEvento } from "../services/eventosServices";

class MenuCategoriesList extends Component {
  state = {
    categorias: [],
  };

  async componentDidMount() {
    try {
      const categorias = await CategoriasProductos();
      this.setState({ categorias: categorias });
    } catch (e) {
      console.log(e.response);
    }
  }

  render() {
    return (
      <Fragment>
        {this.state.categorias.map((categoria) => (
          <div
            className="menuElemento"
            key={categoria.id}
            id={`landingPageCategoriesListItem_${categoria.id}`}
          >
            <Link
              onClick={async () =>
                await RegistrarEvento(3, categoria.id, categoria)
              }
              to={"/productos?categoria=" + categoria.nombre}
            >
              <span>{categoria.nombre}</span>
            </Link>
          </div>
        ))}
      </Fragment>
    );
  }
}

export default MenuCategoriesList;

import moment from "moment";
import { roundToTwo } from "../mathHelper";

// Regresa la fecha de entrega mas proxima, segun la fecha actual
export function FechaEntregaDefault() {
  // Por omisión sugiere la fecha de entrega de mañana
  let fechaEntrega = moment().add(1, "days");
  // Si la fecha de entrega es domingo, pasa al lunes
  if (moment(fechaEntrega).day() === 0)
    fechaEntrega = moment(fechaEntrega).add(1, "days");
  return fechaEntrega;
}

/* 
   Analiza el pedido, calcula y regresa un objeto con:
   {sumaPagosEfectivo  - total de pagos en efectivo
    sumaPagosOtros     - total de pagos con formas distintas a efectivo
    sumaPagos          - total de pagos (todas las formas de pago)
    porPagar           - saldo pendiente por pagar
    cambio             - importe del cambio (efectivo)
    excedente}         - monto de pagos que excedentes al total entregado del pedido
*/
export function CalcularPagosPedido(pedido) {
  let sumaPagosEfectivo = pedido.pagos.reduce(
    (acumulado, pago) =>
      pago.formaPago.efectivo
        ? acumulado + Number(pago.monto ? pago.monto : 0)
        : acumulado,
    0
  );
  sumaPagosEfectivo = roundToTwo(Number(sumaPagosEfectivo));

  let sumaPagosOtros = pedido.pagos.reduce(
    (acumulado, pago) =>
      !pago.formaPago.efectivo
        ? acumulado + Number(pago.monto ? pago.monto : 0)
        : acumulado,
    0
  );
  sumaPagosOtros = roundToTwo(Number(sumaPagosOtros));

  let sumaPagos = sumaPagosEfectivo + sumaPagosOtros;

  let excedente = 0.0;
  let cambio = 0.0;
  let porPagar = pedido.totalEntregado - sumaPagos;

  // Si excede el total calcula el cambio o excedente
  if (porPagar < 0) {
    porPagar = 0;
    cambio = sumaPagos - pedido.totalEntregado;

    if (cambio > sumaPagosEfectivo) {
      excedente = cambio - sumaPagosEfectivo;
      cambio = 0;
    }
  }

  return {
    sumaPagosEfectivo,
    sumaPagosOtros,
    sumaPagos,
    porPagar,
    cambio,
    excedente,
  };
}

// Cambia la cantidad entregada y recalcula el importe de la partida
export function CambiarPartidaCantidadEntregada(partida, cantidadNueva) {
  let cantidad = Number(parseFloat(cantidadNueva));

  if (cantidad < 0) partida.cantidadEntregada = 0;
  else partida.cantidadEntregada = cantidad;

  partida.importeEntregado = CalcularImportePartidaEntregado(partida);
}

// Regresa el importe entregado de la partida
export function CalcularImportePartidaEntregado(partida) {
  return roundToTwo(
    Number(partida.cantidadEntregada * partida.precioUnitarioSurtido)
  );
}

// Calcula y cambia el subTotal y total del pedido dado
export function CalcularPedidoTotalEntregado(pedido) {
  let subTotal = pedido.partidas.reduce(
    (acumulator, partida) =>
      acumulator + Number(CalcularImportePartidaEntregado(partida)),
    0
  );
  pedido.subTotalEntregado = roundToTwo(Number(subTotal));
  pedido.totalEntregado = pedido.subTotalEntregado + pedido.gastosEnvio;

  return pedido;
}

// Regresa el total pagado del pedido
export function CalcularPedidoTotalPagado(pedido) {
  let total = pedido.pagos.reduce(
    (acumulador, pago) => acumulador + Number(pago.monto),
    0
  );

  return total;
}

// Regresa el saldo por pagar
export function CalcularSaldoPedido(pedido) {
  return pedido.totalEntregado - CalcularPedidoTotalPagado(pedido);
}

export async function TotalesRuta(pedidosRuta) {
  console.log(pedidosRuta);
  const rValue = {
    totalPedidos: 0.0,
    countPedidos: 0,
    totalEntregado: 0.0,
    countEntregados: 0,
    totalPagos: 0.0,
    totalPorCobrar: 0.0,
    countPorCobrar: 0,
    totalPropinas: 0.0,
    totalPagosNoEfectivo: 0.0,
    countPagosNoEfectivo: 0,
    pagos: [],
  };
  const mapPagos = new Map();

  rValue.countPedidos = pedidosRuta.length;
  pedidosRuta.forEach((pedido) => {
    rValue.totalPedidos += roundToTwo(Number(pedido.total));
    rValue.totalEntregado += roundToTwo(Number(pedido.totalEntregado));
    rValue.totalPropinas += roundToTwo(Number(pedido.propinaTarjeta));

    if (pedido.estatus === 4) rValue.countEntregados += 1;

    let propinaIncluida = false;
    pedido.totalPagos = 0;
    pedido.pagos.forEach((pago) => {
      let p = mapPagos.get(pago.formaPago.nombre);

      let montoPago = Number(pago.monto);
      if (!pago.formaPago.efectivo && !propinaIncluida) {
        montoPago += pedido.propinaTarjeta;
        propinaIncluida = true;
      }
      if (!p)
        mapPagos.set(pago.formaPago.nombre, {
          id: pago.formaPago.id,
          monto: roundToTwo(Number(montoPago)),
        });
      else
        mapPagos.set(pago.formaPago.nombre, {
          id: pago.formaPago.id,
          monto: roundToTwo(p.monto + Number(montoPago)),
        });

      if (!pago.formaPago.efectivo) {
        rValue.totalPagosNoEfectivo = roundToTwo(
          rValue.totalPagosNoEfectivo + Number(montoPago)
        );
        rValue.countPagosNoEfectivo += 1;
      }

      rValue.totalPagos = roundToTwo(rValue.totalPagos + Number(pago.monto));
      pedido.totalPagos = roundToTwo(pedido.totalPagos + Number(pago.monto));
    });
    if (pedido.estatus === 4 && pedido.totalPagos < pedido.totalEntregado)
      rValue.countPorCobrar += 1;
  });

  let arrPagos = Array.from(mapPagos, ([name, value]) => ({
    nombre: name,
    id: value.id,
    monto: value.monto,
  }));

  rValue.pagos = arrPagos.sort((a, b) => (a.id > b.id ? 1 : -1));

  rValue.totalPorCobrar = rValue.totalEntregado - rValue.totalPagos;

  return rValue;
}

import React, { useEffect, useState } from "react";
import { Form } from "react-bootstrap";

import { GetFormasDePago } from "../../../../services/backoffice/formasPagoServices";

const EntregarPedidoPago = ({ pago, onChange }) => {
  const [formasDePago, setFormasDePago] = useState();
  const [formaPago, setFormaPago] = useState(pago.formaPago);
  const [montoPago, setMontoPago] = useState(pago.monto);
  useEffect(() => {
    cargarFormasDePago();
  }, []);

  const cargarFormasDePago = async () => {
    const formasPagoCargadas = await GetFormasDePago();
    setFormasDePago(formasPagoCargadas);
  };

  const cambiarFormaPago = (e) => {
    let fp = formasDePago.find((fp) => fp.id == e.currentTarget.value);
    if (fp) {
      pago.formaPago = fp;
      setFormaPago(fp);
      onChange();
    }
  };

  const cambiarMontoPago = (e) => {
    pago.monto = Number(e.currentTarget.value);
    setMontoPago(pago.monto);
    onChange();
  };

  if (!formasDePago) return <div />;
  return (
    <div style={{ display: "flex" }}>
      <Form.Control
        as="select"
        value={formaPago.id}
        onChange={(e) => cambiarFormaPago(e)}
      >
        {formasDePago.map((fp) => (
          <option key={fp.id} value={fp.id}>
            {fp.nombre}
          </option>
        ))}
      </Form.Control>
      <Form.Control
        type="number"
        pattern="[0-9]*"
        inputMode="decimal"
        placeholder="Monto"
        value={montoPago}
        onChange={(e) => cambiarMontoPago(e)}
      />
    </div>
  );
};

export default EntregarPedidoPago;

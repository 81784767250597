import webAPIHelper from "../../common/utils/webAPIHelper";
const controller = "/Backoffice/RecepcionCompra";
const appsvc = new webAPIHelper();

export async function GetOrdenesCompra(fecha) {
  return await appsvc.Get(`${controller}/OrdenesCompra?fecha=${fecha}`);
}

export async function GetRecepcionesCompra(ordenCompraId) {
  return await appsvc.Get(`${controller}/${ordenCompraId}`);
}

export async function GuardarRecepcionCompra(recepciones) {
  return await appsvc.Put(`${controller}/`, recepciones);
}

import React, { Component } from "react";
import { NavLink as RouterNavLink } from "react-router-dom";

import {
  Collapse,
  Container,
  Navbar,
  NavbarToggler,
  NavbarBrand,
  Nav,
  NavItem,
  NavLink,
  Button,
  UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from "reactstrap";
import { LogoutBackofficeUserSession } from "../common/utils/sessionHelper";

class BackofficeNavBar extends Component {
  state = { isOpen: false };

  toggle() {
    this.setState({ isOpen: !this.state.isOpen });
  }

  async logout() {
    LogoutBackofficeUserSession(this.props.sessionId);
    window.location.href = "/backoffice";
  }

  async componentDidMount() {
    if (!this.props.sessionId) window.location.href = "/backoffice";
  }

  render() {
    const { isOpen } = this.state;
    return (
      <div className="nav-container">
        <Navbar color="navbar navbar-dark bg-dark" light expand="md">
          <Container>
            <NavbarBrand href="/backoffice">
              <img src="/img/logo.svg" width="70" alt="Backoffice" />
            </NavbarBrand>

            <NavbarToggler onClick={() => this.toggle()} />
            <Collapse isOpen={isOpen} navbar>
              <Nav className="mr-auto" navbar>
                <NavItem>
                  <NavLink
                    tag={RouterNavLink}
                    to="/backoffice/precios"
                    activeClassName="nav-item nav-link active"
                    className="nav-item nav-link"
                  >
                    Precios
                  </NavLink>
                </NavItem>
                <UncontrolledDropdown nav inNavbar>
                  <DropdownToggle nav caret>
                    Catálogos
                  </DropdownToggle>
                  <DropdownMenu right>
                    <DropdownItem
                      tag={RouterNavLink}
                      to="/backoffice/productos"
                    >
                      Productos
                    </DropdownItem>
                    <DropdownItem
                      tag={RouterNavLink}
                      to="/backoffice/proveedores"
                    >
                      Proveedores
                    </DropdownItem>
                    <DropdownItem tag={RouterNavLink} to="/backoffice/usuarios">
                      Usuarios
                    </DropdownItem>
                    <DropdownItem
                      tag={RouterNavLink}
                      to="/backoffice/perfilesUsuarios"
                    >
                      Perfiles
                    </DropdownItem>
                  </DropdownMenu>
                </UncontrolledDropdown>
                <UncontrolledDropdown nav inNavbar>
                  <DropdownToggle nav caret>
                    Pedidos
                  </DropdownToggle>

                  <DropdownMenu>
                    <DropdownItem
                      tag={RouterNavLink}
                      to="/backoffice/pedidos/porEntregar"
                    >
                      por Entregar
                    </DropdownItem>
                    <DropdownItem
                      tag={RouterNavLink}
                      to="/backoffice/pedidos/rutas"
                    >
                      Rutas
                    </DropdownItem>
                    <DropdownItem
                      tag={RouterNavLink}
                      to="/backoffice/pedidos/rutas/misrutas"
                    >
                      Mis Rutas
                    </DropdownItem>
                  </DropdownMenu>
                </UncontrolledDropdown>
                <UncontrolledDropdown nav inNavbar>
                  <DropdownToggle nav caret>
                    Compras
                  </DropdownToggle>
                  <DropdownMenu right>
                    <DropdownItem
                      tag={RouterNavLink}
                      to="/backoffice/compras/shoppinglist"
                    >
                      Lista de compras
                    </DropdownItem>
                    <DropdownItem
                      tag={RouterNavLink}
                      to="/backoffice/compras/analisiscompras"
                    >
                      Análisis de compras
                    </DropdownItem>
                    <DropdownItem
                      tag={RouterNavLink}
                      to="/backoffice/compras/recepcioncompras"
                    >
                      Recepción de compras
                    </DropdownItem>
                  </DropdownMenu>
                </UncontrolledDropdown>
                <NavItem>
                  <NavLink
                    tag={RouterNavLink}
                    to="/backoffice/inventario/inventariosfisicos"
                    activeClassName="nav-item nav-link active"
                    className="nav-item nav-link"
                  >
                    Inventario fisico
                  </NavLink>
                  <NavLink
                    tag={RouterNavLink}
                    to="/backoffice/inventario/existencias"
                    activeClassName="nav-item nav-link active"
                    className="nav-item nav-link"
                  >
                    Existencias
                  </NavLink>
                </NavItem>
              </Nav>
              <Nav className="d-none d-md-block" navbar>
                <NavItem>
                  <Button
                    color="primary"
                    className="btn-margin"
                    onClick={() => this.logout()}
                  >
                    Salir
                  </Button>
                </NavItem>

                <NavItem>
                  <NavLink
                    id="qsLoginBtn"
                    color="primary"
                    className="btn-margin"
                    onClick={() => {
                      //this.props.auth.logout();
                      this.props.history.push("/");
                    }}
                  >
                    {/* <img
                        src={user.picture}
                        alt="Profile"
                        className="nav-user-profile rounded-circle"
                        width="50"
                      /> */}
                  </NavLink>
                </NavItem>
              </Nav>
            </Collapse>
          </Container>
        </Navbar>
      </div>
    );
  }
}

export default BackofficeNavBar;

import React from "react";
import NumberFormat from "react-number-format";
import { Link } from "react-router-dom";

const CheckoutSummary = (props) => {
  const { pedido } = props;

  const renderGastosEnvio = () => {
    if (pedido.gastosEnvio === 0)
      return (
        <span style={{ color: "green" }}>
          <strong>Gratis!</strong>
        </span>
      );
    return (
      <span>
        <NumberFormat
          value={pedido.gastosEnvio}
          displayType="text"
          decimalScale="2"
          fixedDecimalScale="2"
          thousandSeparator
        />
      </span>
    );
  };

  const renderDescuento = () => {
    if (pedido.totalDescuento > 0)
      return (
        <span>
          <strong>Descuento:</strong>
          <span style={{ color: "green" }}>
            <strong>
              <NumberFormat
                value={pedido.totalDescuento}
                displayType="text"
                decimalScale="2"
                prefix={"$"}
                fixedDecimalScale="2"
                thousandSeparator
              />
            </strong>
          </span>
        </span>
      );
  };

  return (
    <div id="pedidoPageTotales">
       <div className="clear10"></div>
      <span>
        Sub-total:{" "}
        <NumberFormat
          value={pedido.subTotal}
          displayType="text"
          prefix={"$"}
          decimalScale="2"
          fixedDecimalScale="2"
          thousandSeparator
        />
      </span>
      <div className="clear5"></div>
      <span>Gastos de envío: {renderGastosEnvio()}</span>
      {renderDescuento()}

      <div className="clear5"></div>
      <span>
        Total:{" "}
        <strong>
          <NumberFormat
            value={pedido.total}
            displayType="text"
            prefix={"$"}
            decimalScale="2"
            fixedDecimalScale="2"
            thousandSeparator
          />
        </strong>
      </span>
      <div className="clear5"></div>
      <Link to="/">
        <button className="seguir">Seguir comprando</button>
      </Link>
      <div className="clear10"></div>
    </div>
  );
};

export default CheckoutSummary;

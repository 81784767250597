import React, { useState, useEffect } from "react";

import { Modal } from "react-bootstrap";
import {
  Checkbox,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  TextField,
} from "@material-ui/core";
import {
  ListaPermisos,
  PostPerfilUsuario,
  PutPerfilUsuario,
  PatchPermisosPerfilUsuario,
  PermisosPerfilUsuario,
} from "../../services/backofficeService";

const UpdatePerfil = ({ perfil, editAction, onChange, onHide, onSave }) => {
  const [inicializado, setInicializado] = useState(false);
  const [errorMessage, setErrorMessage] = useState();
  const [permisos, setPermisos] = useState();
  const [checked, setChecked] = useState([]);

  useEffect(() => {
    async function cargarPermisos() {
      // Marca los que tenga asignados el perfil
      if (editAction === "edit") {
        let permisosPerfil = await PermisosPerfilUsuario(perfil);
        let currentPermisos = [];
        permisosPerfil.forEach((permiso) => {
          currentPermisos.push(permiso.permisoId);
        });
        setChecked(currentPermisos);
      }

      // Carga la lista con todos los permisos
      let listaPermisos = await ListaPermisos();
      listaPermisos.sort((a, b) => (a.clave > b.clave ? 1 : -1));

      setPermisos(listaPermisos);
    }

    if (!editAction) return;
    if (!inicializado) cargarPermisos();
  }, []);

  const handleToggle = (permiso) => {
    const currentIndex = checked.indexOf(permiso.id);
    const newChecked = [...checked];

    if (currentIndex === -1) {
      newChecked.push(permiso.id);
    } else {
      newChecked.splice(currentIndex, 1);
    }

    setChecked(newChecked);
  };

  if (!perfil || !editAction || !permisos) return <span />;

  return (
    <Modal show={editAction} onHide={() => onHide()}>
      <Modal.Header closeButton>
        <Modal.Title>
          {editAction === "add" ? "Nuevo " : "Editar "} usuario
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div>
          <TextField
            label="Nombre"
            value={perfil.nombre}
            required
            fullWidth
            onChange={(e) => {
              perfil.nombre = e.currentTarget.value;
              onChange();
            }}
          />
          <hr />
          <div>
            <List>
              {permisos.map((permiso) => (
                <ListItem key={permiso.id} role={undefined} dense button>
                  <ListItemIcon>
                    <Checkbox
                      edge="start"
                      checked={checked.indexOf(permiso.id) !== -1}
                      tabIndex={-1}
                      disableRipple
                      onClick={() => handleToggle(permiso)}
                    />
                  </ListItemIcon>
                  <ListItemText primary={`${permiso.clave}`} />
                </ListItem>
              ))}
            </List>
          </div>
        </div>
      </Modal.Body>
      <Modal.Footer>
        {errorMessage && <div style={{ color: "red" }}>{errorMessage}</div>}
        <button
          className="btn btn-primary"
          onClick={async () => {
            setErrorMessage("");
            if (!perfil.nombre) {
              setErrorMessage("Escriba el nombre del perfil");
              return;
            }
            try {
              if (editAction === "add") {
                let added = await PostPerfilUsuario(perfil);
                perfil.id = added.id;
              }
              if (editAction === "edit") await PutPerfilUsuario(perfil);
              let checkedCollection = [];
              checked.forEach((permisoId) =>
                checkedCollection.push({ id: permisoId })
              );
              await PatchPermisosPerfilUsuario(perfil, checkedCollection);
            } catch (error) {
              let msg = "";
              if (error.response) msg = error.response.data;
              setErrorMessage("Error al guardar los cambios" + msg);
              return;
            }
            onSave();
          }}
        >
          Guardar
        </button>
      </Modal.Footer>
    </Modal>
  );
};

export default UpdatePerfil;

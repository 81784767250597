import { Backdrop, CircularProgress } from "@material-ui/core";
import React, { useEffect, useState } from "react";
import { Card, Button, Form } from "react-bootstrap";
import {
  CrearInventarioFisico,
  GuardarInventarioFisico,
  CargarInventarioFisico,
} from "../../../services/backoffice/inventariosServices";

import "./inventariofisico.css";
import moment from "moment";
import { useHistory, useParams } from "react-router";
import { ArrowLeftSquare, Plus, Trash } from "react-bootstrap-icons";
import SearchInput from "../../../common/utils/searchInput";
import LookUpProducto from "../../productos/lookupProducto";
import QuantityInput from "../../../common/utils/quantityInput";
import { TienePermiso } from "../../../services/backofficeService";

const InventarioFisico = ({ sessionId }) => {
  const [inventarioFisico, setInventarioFisico] = useState({});
  const [showPartidas, setShowPartidas] = useState([]);
  const [loading, setLoading] = useState(true);
  const [searchText, setSearchText] = useState("");
  const [showLookupProducto, setShowLookupProducto] = useState(false);
  const estatusPorAplicar = 0;
  const history = useHistory();

  async function inicializarInventarioFisico(id) {
    let inventario = {};
    if (!id) {
      inventario = await CrearInventarioFisico();
    } else {
      inventario = await CargarInventarioFisico(id);
    }
    setInventarioFisico(inventario);
    showPartidasConCantidad(inventario);
    setLoading(false);
  }

  const params = useParams();

  useEffect(() => {
    async function tienePermiso() {
      var respuesta = await TienePermiso(
        sessionId,
        "Inventarios/InventarioFisico/ABC"
      );
      if (respuesta !== "") {
        alert(respuesta);
        window.location.href = "/backoffice/inventario/inventariosfisicos";
        return;
      }
      let { id } = params;
      inicializarInventarioFisico(id);
    }

    tienePermiso();
  }, []);

  const search = async (text) => {
    setSearchText(text);

    if (!text) {
      showPartidasConCantidad(inventarioFisico);
      return;
    }

    let textLower = text.toLowerCase();
    let r = inventarioFisico.partidas.filter((p) =>
      p.producto.descripcion.toLowerCase().includes(textLower)
    );
    setShowPartidas(r);
  };

  const showPartidasConCantidad = (inventario) => {
    setShowPartidas(inventario.partidas);
  };

  const cambiarCantidad = (partida, cantidadNueva) => {
    if (cantidadNueva === "") cantidadNueva = 0;

    if (cantidadNueva < 0) return;
    const indexPartidas = inventarioFisico.partidas.findIndex(
      (p) => p.productoId === partida.productoId
    );
    if (indexPartidas === -1) return;

    let cantidad = Number(parseFloat(cantidadNueva));

    inventarioFisico.partidas[indexPartidas].cantidad = cantidad;
    const indexShow = showPartidas.findIndex(
      (p) => p.productoId === partida.productoId
    );
    showPartidas[indexShow].cantidad = cantidad;
    setShowPartidas([...showPartidas]);
  };

  const quitarPartida = (partida) => {
    let indexPartida = inventarioFisico.partidas.findIndex(
      (p) => p.productoId === partida.productoId
    );
    if (indexPartida < 0) return;

    inventarioFisico.partidas.splice(indexPartida, 1);

    let indexShowPartidas = showPartidas.findIndex(
      (p) => p.productoId === partida.productoId
    );
    if (indexShowPartidas >= 0) {
      showPartidas.splice(indexShowPartidas, 1);
      setShowPartidas([...showPartidas]);
    }

    setInventarioFisico({ ...inventarioFisico });
  };

  const quitarPartidas = () => {
    if (!window.confirm("¿ Esta seguro que desea eliminar las partidas ?"))
      return;
    inventarioFisico.partidas = [];
    setShowPartidas([]);
    setInventarioFisico({ ...inventarioFisico });
  };

  const agregarProducto = (producto) => {
    // Si ya existe, lo ignora
    let index = inventarioFisico.partidas.findIndex(
      (x) => x.productoId === producto.id
    );
    if (index > -1) return;

    let nuevaPartida = {
      productoId: producto.id,
      cantidad: 0.0,
      producto: producto,
    };

    inventarioFisico.partidas.push(nuevaPartida);
    showPartidas.push(nuevaPartida);
    setShowPartidas([...showPartidas]);
    setInventarioFisico({ ...inventarioFisico });
  };

  const guardar = async () => {
    setLoading(true);
    await GuardarInventarioFisico(inventarioFisico);
    setLoading(false);
    history.push("/backoffice/inventario/inventariosfisicos");
  };

  return (
    <div>
      <div className="backoffice-header fixed-header ">
        <div className="inventario-fisico-header">
          <ArrowLeftSquare
            color="white"
            size="24px"
            onClick={() => history.goBack()}
          />
          <h1>
            Inventario físico {inventarioFisico.id ? inventarioFisico.id : ""}
          </h1>
        </div>
        <div>
          {inventarioFisico && (
            <input
              type="date"
              value={moment(inventarioFisico.fecha).format("YYYY-MM-DD")}
              className="form-control"
              disabled={inventarioFisico.estatus !== estatusPorAplicar}
              onChange={(e) => {
                inventarioFisico.fecha = e.currentTarget.value;
                setInventarioFisico({ ...inventarioFisico });
              }}
            />
          )}
          <div className="inventario-fisico__buscar-agregar">
            <SearchInput value={searchText} onChange={(text) => search(text)} />

            <Button
              className="inventario-fisico_agregar-button"
              disabled={inventarioFisico.estatus !== estatusPorAplicar}
              onClick={() => {
                setShowLookupProducto(true);
              }}
            >
              <Plus color="white" />
            </Button>
            <Button
              className="inventario-fisico_quitar-button"
              variant="danger"
              disabled={
                inventarioFisico.estatus !== estatusPorAplicar &&
                showPartidas.length > 0
              }
              onClick={() => quitarPartidas()}
            >
              <Trash color="white" />
            </Button>
          </div>
        </div>
      </div>
      <div className="lista-partidas">
        {showPartidas.map((p) => {
          return (
            <Card key={p.productoId} className="inventarioFisicoPartida">
              <Card.Body>
                <div className="inventarioFisicoPartida__body">
                  <img src={p.producto.imagenUrl} alt="" />
                  <div className="inventarioFisicoPartida__body_details">
                    <div className="inventarioFisicoPartida__body_descripcion">
                      {p.producto.descripcion}
                    </div>
                    {inventarioFisico.estatus === estatusPorAplicar ? (
                      <QuantityInput
                        cantidad={p.cantidad}
                        unidad={p.producto.unidad}
                        allowDelete
                        onChange={(cantidad) => cambiarCantidad(p, cantidad)}
                        onDelete={() => quitarPartida(p)}
                      />
                    ) : (
                      <div className="inventarioFisicoPartida__body_cantidad_diferencia">
                        <div>Cantidad:</div>
                        <div>
                          <strong>{p.cantidad}</strong>
                        </div>
                        <div>{p.producto.unidad}</div>
                        <div>Diferencia:</div>
                        <div
                          className={
                            p.diferencia > 0
                              ? "inventarioFisicoPartida__diferencia-positiva"
                              : p.diferencia < 0
                              ? "inventarioFisicoPartida__diferencia-negativa"
                              : "inventarioFisicoPartida__diferencia-cero"
                          }
                        >
                          {p.diferencia}
                        </div>
                      </div>
                    )}
                  </div>
                </div>
              </Card.Body>
            </Card>
          );
        })}
      </div>
      <div className="fixed-footer">
        <textarea
          rows="3"
          placeholder="Notas"
          className="form-control"
          value={!inventarioFisico.notas ? "" : inventarioFisico.notas}
          disabled={inventarioFisico.estatus !== estatusPorAplicar}
          onChange={(e) => {
            inventarioFisico.notas = e.currentTarget.value;
            setInventarioFisico({ ...inventarioFisico });
          }}
        ></textarea>
        {inventarioFisico.estatus === estatusPorAplicar && (
          <Button
            variant="primary"
            block
            disabled={inventarioFisico.estatus !== estatusPorAplicar}
            onClick={guardar}
          >
            Guardar
          </Button>
        )}
      </div>
      <Backdrop open={loading} style={{ zIndex: "999" }}>
        <CircularProgress color="inherit" />
      </Backdrop>
      <LookUpProducto
        show={showLookupProducto}
        onHide={() => setShowLookupProducto(false)}
        onProductoSeleccionado={(row) => agregarProducto(row)}
      />
    </div>
  );
};

export default InventarioFisico;

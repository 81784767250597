import React, { useEffect, useState } from "react";
import { saveAs } from "file-saver";
import InputDate from "../../common/utils/inputDate";
import BackofficeBanner from "../backofficeBanner";
import moment from "moment";
import { AnalisisCompras } from "../../services/backofficeService";
import { TienePermiso } from "../../services/backofficeService";
const AnalisisComprasPage = ({ sessionId }) => {
  const [fecha, setFecha] = useState(moment());

  useEffect(() => {
    async function validarPermiso() {
      let respuesta = await TienePermiso(sessionId, "Compras/AnalisisCompras");
      if (respuesta !== "") {
        alert(respuesta);
        window.location.href = "/backoffice";
        return;
      }
    }
    validarPermiso();
  }, []);

  return (
    <div className="App">
      <BackofficeBanner title="Pedidos" showProgress={false}>
        <InputDate
          id="pedidosPorEntregarPage-dateFilter"
          label="Fecha de entrega"
          value={fecha}
          onChange={(date) => {
            setFecha(date);
          }}
        />
        <button
          className="btn btn-primary"
          onClick={async () => {
            var content = await AnalisisCompras(fecha);
            var blob = new Blob([content]);
            saveAs(blob, `AnalisisCompras${fecha.format("YYYY-MM-DD")}.csv`);
          }}
        >
          Descargar
        </button>
      </BackofficeBanner>
    </div>
  );
};

export default AnalisisComprasPage;

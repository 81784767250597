import React from "react";
import { TextField } from "@material-ui/core";
const Input = ({
  name,
  label,
  value,
  error,
  onChange,
  multiline,
  ...props
}) => {
  return (
    <div>
      <TextField
        label={label}
        value={value == null ? "" : value}
        onChange={onChange}
        id={name}
        name={name}
        type="text"
        multiline={multiline}
        error={error}
        helperText={error}
        {...props}
      />
    </div>
  );
};

export default Input;

import React from "react";
import { Link } from "react-router-dom";
import { RegistrarEvento } from "../../services/eventosServices";
//TODO: Link al pedido
const ViewCartButton = (props) => {
  const contador = props.contadorItems;
  if (contador > 0)
    return (
      <div id="carritoHeaderT" className="carritoHeader">
        <Link onClick={async () => await RegistrarEvento(13)} to="/pedido">
          <div className="material-icons">shopping_cart</div>
          <div className="div100">{props.contadorItems} productos</div>
        </Link>
      </div>
    );
  return "";
};

export default ViewCartButton;

import React from "react";

import { Modal, Button } from "react-bootstrap";

const Aviso = ({ show, onHide, mensaje }) => {
  if (!show) return <span />;

  return (
    <Modal
      show={true}
      onHide={onHide}
      style={{ marginTop: "10rem" }}
      aria-labelledby="contained-modal-title-vcenter"
    >
      <Modal.Header closeButton style={{ backgroundColor: "#00cc00" }}>
        <Modal.Title>Aviso</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div>
          <p>{mensaje}</p>
        </div>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="success" onClick={onHide}>
          Aceptar
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default Aviso;

import React, { Component } from "react";
import BackofficeBanner from "../backofficeBanner";
import {
  GetPedido,
  PutPedido,
  DeletePedido,
  TienePermiso,
} from "../../services/backofficeService";
import {
  Button,
  Dialog,
  DialogTitle,
  DialogContentText,
  DialogContent,
  DialogActions,
  Paper,
  Grid,
  Table,
  TableContainer,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
} from "@material-ui/core";
import Input from "../../common/utils/input";
import LookUpProducto from "../productos/lookupProducto";
import InputDate from "../../common/utils/inputDate";
import { withRouter } from "react-router-dom";

class PedidoPorEntregarPage extends Component {
  state = {
    loading: false,
    pedido: { partidas: [] },
    agregarProductoDialogOpen: false,
    cancelarDialogOpen: false,
    razonesCancelacion: "",
  };

  async componentDidMount() {
    var respuesta = await TienePermiso(
      this.props.sessionId,
      "Pedidos/porEntregar/Editar"
    );
    if (respuesta !== "") {
      alert(respuesta);
      this.props.history.goBack();
      return;
    }
    const { id } = this.props.match.params;
    var pedido = await GetPedido(id);
    this.setState({ pedido });
  }

  handleChange = ({ currentTarget: input }) => {
    const pedido = { ...this.state.pedido };
    pedido[input.name] = input.value;

    this.setState({ pedido });
  };

  partidaCantidadPrefix = "cantidad_";
  partidaNotasPrefix = "notas_";

  handleCantidadChanged = async ({ currentTarget: input }) => {
    try {
      const prefixLength = this.partidaCantidadPrefix.length;
      const partidaId = parseInt(
        input.name.substring(prefixLength, input.name.length)
      );

      var pedido = this.state.pedido;
      var partidas = pedido.partidas;
      const partida = partidas.find((element) => {
        return element.id === partidaId;
      });
      if (!partida) return;

      partida.cantidad = parseFloat(input.value);
      partida.importe = partida.cantidad * partida.precioUnitario;
      partidas = partidas.map((p) => (p.id !== partidaId ? p : partida));
      this.setState({ pedido });
    } catch (error) {
      //TODO: Log
      console.log(error);
    }
  };

  handleNotasPartidaChanged = async ({ currentTarget: input }) => {
    try {
      const prefixLength = this.partidaNotasPrefix.length;
      const partidaId = parseInt(
        input.name.substring(prefixLength, input.name.length)
      );

      var pedido = this.state.pedido;
      var partidas = pedido.partidas;
      const partida = partidas.find((element) => {
        return element.id === partidaId;
      });
      if (!partida) return;

      partida.notas = input.value;
      partidas = partidas.map((p) => (p.id !== partidaId ? p : partida));
      this.setState({ pedido });

      console.log(pedido);
    } catch (error) {
      //TODO: Log
      console.log(error);
    }
  };

  handleGuardarPedido = async () => {
    const enviarCorreo = window.confirm(
      "¿ Enviar el correo al cliente con los cambios?"
    );
    try {
      await PutPedido(this.state.pedido, enviarCorreo);
      this.props.history.push("/backoffice/pedidos/porEntregar");
    } catch (exception) {
      alert(exception);
    }
  };

  handleAgregarProducto = (producto) => {
    var pedido = this.state.pedido;
    const partidaId = Math.max(...pedido.partidas.map((o) => o.id), 0) + 1;

    const partida = {
      id: partidaId,
      productoId: producto.id,
      producto: producto,
      cantidad: 0.0,
      precioUnitario: producto.precio,
      costoUnitario: producto.costoUnitario,
      importe: 0.0,
    };

    console.log("partida", partida);
    pedido.partidas = [...pedido.partidas, partida];
    this.setState({ pedido, agregarProductoDialogOpen: false });
  };

  handleCancelarPedido = async () => {
    try {
      this.setState({ cancelarDialogOpen: false });
      await DeletePedido(this.state.pedido.id, this.state.razonesCancelacion);
    } catch (exception) {
      alert(exception);
    }
  };

  render() {
    const { pedido } = this.state;

    return (
      <div className="App">
        <BackofficeBanner
          title={"Pedido " + pedido.folio}
          showProgress={this.state.loading}
        />
        <div className="container">
          <Grid container spacing={3}>
            <Grid item xs={6}>
              <Input
                label="Nombre"
                value={pedido.nombre}
                name="nombre"
                fullWidth
                onChange={this.handleChange}
              />
            </Grid>
            <Grid item xs={4}>
              <Input
                label="eMail"
                value={pedido.eMail}
                name="eMail"
                fullWidth
                onChange={this.handleChange}
              />
            </Grid>
            <Grid item xs={2}>
              <Input
                label="Teléfono"
                value={pedido.telefono}
                name="telefono"
                fullWidth
                onChange={this.handleChange}
              />
            </Grid>
            <Grid item xs={8}>
              <Input
                label="Calle"
                value={pedido.calle}
                name="calle"
                fullWidth
                onChange={this.handleChange}
              />
            </Grid>
            <Grid item xs={2}>
              <Input
                label="No. Exterior"
                value={pedido.noExterior}
                name="noExterior"
                onChange={this.handleChange}
              />
            </Grid>
            <Grid item xs={2}>
              <Input
                label="No. Interior"
                value={pedido.noInterior}
                name="noInterior"
                onChange={this.handleChange}
              />
            </Grid>
            <Grid item xs={2}>
              <Input
                label="C.P."
                value={pedido.codigoPostal}
                name="codigoPostal"
                onChange={this.handleChange}
              />
            </Grid>
            <Grid item xs={5}>
              <Input
                label="Colonia"
                value={pedido.colonia}
                name="colonia"
                fullWidth
                onChange={this.handleChange}
              />
            </Grid>
            <Grid item xs={5}>
              <Input
                label="municipio"
                value={pedido.municipio}
                name="municipio"
                fullWidth
                onChange={this.handleChange}
              />
            </Grid>
            <Grid item xs={4}>
              <Input
                label="Entre calle 1"
                value={pedido.entreCalle1}
                name="entreCalle1"
                fullWidth
                onChange={this.handleChange}
              />
            </Grid>{" "}
            <Grid item xs={4}>
              <Input
                label="Entre calle 2"
                value={pedido.entreCalle2}
                name="entreCalle2"
                fullWidth
                onChange={this.handleChange}
              />
            </Grid>
            <Grid item xs={4}>
              <Input
                label="Referencias"
                value={pedido.referencias}
                name="referencias"
                fullWidth
                onChange={this.handleChange}
              />
            </Grid>
            <Grid item xs={12}>
              <Input
                label="Notas"
                value={pedido.notas}
                name="notas"
                multiline
                rows={3}
                fullWidth
                onChange={this.handleChange}
              />
            </Grid>
            <Grid item xs={2}>
              <InputDate
                label="Fecha de entrega"
                value={pedido.fechaComprometidaEntrega}
                onChange={(date) => {
                  pedido.fechaComprometidaEntrega = date;
                  this.setState({ pedido });
                }}
              />
            </Grid>
          </Grid>

          <br></br>

          <TableContainer component={Paper}>
            <Table size="small">
              <TableHead>
                <TableRow>
                  <TableCell>Código</TableCell>
                  <TableCell>Descripción</TableCell>
                  <TableCell align="right">Cantidad</TableCell>
                  <TableCell>Unidad</TableCell>
                  <TableCell align="right">Precio Unitario</TableCell>
                  <TableCell align="right">Importe</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {pedido.partidas.map((partida) => (
                  <TableRow key={partida.id}>
                    <TableCell>{partida.productoId}</TableCell>
                    <TableCell>
                      {partida.producto.descripcion}
                      <br />
                      <Input
                        value={partida.notas}
                        name={this.partidaNotasPrefix + partida.id}
                        fullWidth
                        onChange={this.handleNotasPartidaChanged}
                      />
                    </TableCell>
                    <TableCell align="right">
                      <Input
                        value={partida.cantidad}
                        name={this.partidaCantidadPrefix + partida.id}
                        fullWidth
                        onChange={this.handleCantidadChanged}
                      />
                    </TableCell>
                    <TableCell>{partida.producto.unidad}</TableCell>
                    <TableCell align="right">
                      {partida.precioUnitario}
                    </TableCell>
                    <TableCell align="right">{partida.importe}</TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
          <div>
            <br></br>
          </div>
          <Grid container justify="space-between">
            <Button
              variant="contained"
              color="primary"
              onClick={() => this.setState({ agregarProductoDialogOpen: true })}
            >
              Agregar producto
            </Button>
            <Button
              variant="contained"
              color="secondary"
              onClick={() => this.setState({ cancelarDialogOpen: true })}
            >
              Cancelar pedido
            </Button>
            <Button
              variant="contained"
              color="primary"
              onClick={() => this.handleGuardarPedido()}
            >
              Guardar cambios
            </Button>
          </Grid>

          <LookUpProducto
            show={this.state.agregarProductoDialogOpen}
            onClose={() => this.setState({ agregarProductoDialogOpen: false })}
            onProductoSeleccionado={(row) => this.handleAgregarProducto(row)}
          />
          <Dialog
            open={this.state.cancelarDialogOpen}
            close={() => {
              this.setState({ cancelarDialogOpen: false });
            }}
          >
            <DialogTitle>Cancelar pedido</DialogTitle>
            <DialogContent>
              <DialogContentText>
                ¿ Está seguro que desea cancelar el pedido ?
              </DialogContentText>
              <Input
                autoFocus
                label="Razones"
                value={this.state.razonesCancelacion}
                name="razonesCancelacion"
                fullWidth
                onChange={(event) =>
                  this.setState({
                    razonesCancelacion: event.currentTarget.value,
                  })
                }
              />
              <DialogActions>
                <Button
                  variant="contained"
                  color="primary"
                  onClick={() => {
                    this.handleCancelarPedido();
                  }}
                >
                  Si
                </Button>
                <Button
                  variant="contained"
                  color="primary"
                  onClick={() => {
                    this.setState({ cancelarDialogOpen: false });
                  }}
                >
                  No
                </Button>
              </DialogActions>
            </DialogContent>
          </Dialog>
        </div>
      </div>
    );
  }
}

export default withRouter(PedidoPorEntregarPage);

import React, { Component } from "react";
import { Link } from "react-router-dom";
import CategoriasProductos from "../../services/categoriasService";
import { RegistrarEvento } from "../../services/eventosServices";

class LandingPageCategoriesList extends Component {
  state = {
    categorias: [],
  };

  async componentDidMount() {
    try {
      const categorias = await CategoriasProductos();
      this.setState({ categorias: categorias });
    } catch (e) {
      console.log(e.response);
    }
  }

  render() {
    return (
      <div className="div100">
        {this.state.categorias.map((categoria) => (
          <div
            className="categoriasPortada"
            key={categoria.id}
            id={`landingPageCategoriesListItem_${categoria.id}`}
          >
            <Link
              onClick={async () =>
                await RegistrarEvento(2, categoria.id, categoria)
              }
              to={"/productos?categoria=" + categoria.nombre}
            >
              <div className="categoriasPortadaInt">
                <div className="categoriasPortadaImgCont ">
                  <div
                    className="categoriasPortadaImg "
                    style={{
                      backgroundImage: "url(" + categoria.imageUrl + ")",
                    }}
                  ></div>
                </div>

                <div className="clear"></div>
                <div className="categoriasPortadaTit">{categoria.nombre}</div>
              </div>
            </Link>
          </div>
        ))}
      </div>
    );
  }
}

export default LandingPageCategoriesList;

import React, { Component } from "react";
import { Route, Switch, BrowserRouter } from "react-router-dom";

import PageFooter from "../common/pageFooter";
import PageHeader from "../common/pageHeader";

import LandingPage from "./landingPage/landingPage";
import PedidoDomicilioPage from "./pedido/pedidoDomicilio/pedidoDomicilioPage";
import CheckoutPage from "./pedido/checkout/checkoutPage";
import ProductosPage from "./productosPage/productosPage";

import { RegistrarEvento } from "../services/eventosServices";
import Aviso from "./aviso";
import CartReset from "./pedido/cartReset";
import AvisoDiaInhabil from "./avisoDiaInhabil";

class TiendaNav extends Component {
  state = { showAviso: false };

  componentDidMount() {
    let hoy = new Date();
    if (hoy > new Date(2021, 8, 14) && hoy < new Date(2021, 8, 17))
      this.setState({ showAviso: true });

    RegistrarEvento(1, window.location.href);
  }

  render() {
    return (
      <div>
        <AvisoDiaInhabil />
        <Aviso
          show={this.state.showAviso}
          onHide={() => this.setState({ showAviso: false })}
        />
        <BrowserRouter>
          <div>
            <PageHeader pedidoContadorItems={this.state.pedidoContadorItems} />
          </div>
          <Switch>
            <Route
              path="/productos"
              render={(props) => {
                return (
                  <ProductosPage
                    {...props}
                    onCantidadChanged={(nuevaCantidad, totalItemsDelPedido) =>
                      this.setState({
                        pedidoContadorItems: totalItemsDelPedido,
                      })
                    }
                  />
                );
              }}
            />
            <Route path="/pedido/domicilio" component={PedidoDomicilioPage} />
            <Route
              path="/pedido"
              render={(props) => (
                <CheckoutPage
                  {...props}
                  onCantidadChanged={(nuevaCantidad, totalItemsDelPedido) =>
                    this.setState({
                      pedidoContadorItems: totalItemsDelPedido,
                    })
                  }
                />
              )}
            />
            <Route path="/reset" component={CartReset} />

            <Route
              path="/"
              render={(props) => (
                <LandingPage
                  {...props}
                  onCantidadChanged={(nuevaCantidad, totalItemsDelPedido) =>
                    this.setState({
                      pedidoContadorItems: totalItemsDelPedido,
                    })
                  }
                />
              )}
            />
          </Switch>
        </BrowserRouter>
        <div>
          <PageFooter />
        </div>
      </div>
    );
  }
}

export default TiendaNav;

import React, { useState, useEffect } from "react";
import NumberFormat from "react-number-format";
import { Modal, Button, Form, Alert, Col, Card } from "react-bootstrap";

import { ValidarRuta } from "./rutasHelpers";
import {
  ActualizarRuta,
  QuitarRutaPedido,
  GetPedidosDeLaRuta,
  CambiarOrdenPedidos,
  GetChoferes,
} from "../../../services/backoffice/rutasServices";
import RutaMapa from "./rutaMapa";
import VerPedidoRuta from "./verPedidoRuta";

import {
  ArrowDownSquare,
  ArrowUpSquare,
  TrashFill,
  Search,
} from "react-bootstrap-icons";

const EditarRuta = ({
  ruta,
  pedidos,
  onHide,
  onComplete,
  onPedidosPorAsignarChanged,
  onPedidoUbicacionChanged,
}) => {
  const [numero, setNumero] = useState(ruta.numero);
  const [nombre, setNombre] = useState(ruta.nombre);
  const [choferId, setChoferId] = useState(ruta.choferId);
  const [choferes, setChoferes] = useState();
  const [error, setError] = useState();
  const [pedidosRuta, setPedidosRuta] = useState();
  const [minOrden, setMinOrden] = useState();
  const [maxOrden, setMaxOrden] = useState();
  const [pedidoConsultar, setPedidoConsultar] = useState();

  useEffect(() => {
    const cargarChoferes = async () => {
      // Obtiene la lista de choferes
      const choferes = await GetChoferes();
      setChoferes(choferes);
    };
    filtrarPedidosRuta();
    cargarChoferes();
  }, [pedidos]);

  function filtrarPedidosRuta() {
    let ped = GetPedidosDeLaRuta(pedidos, ruta);
    setPedidosRuta(ped);
    if (ped && ped.length > 0) {
      let min = ped.reduce(
        (min, p) => (p.ordenRuta < min ? p.ordenRuta : min),
        ped[0].ordenRuta
      );
      setMinOrden(min);
      let max = ped.reduce(
        (max, p) => (p.ordenRuta > max ? p.ordenRuta : max),
        ped[0].ordenRuta
      );
      setMaxOrden(max);
    }
  }

  async function subirPedido(pedido) {
    let index = pedidosRuta.findIndex((x) => x.id === pedido.id);
    if (index <= 0) return;

    let pedidoAnterior = pedidosRuta[index - 1];
    let estePedido = pedidosRuta[index];
    try {
      await CambiarOrdenPedidos(pedidoAnterior.id, estePedido.id);

      let ordenAnterior = pedidosRuta[index - 1].ordenRuta;
      let esteOrden = pedidosRuta[index].ordenRuta;

      pedidosRuta[index].ordenRuta = ordenAnterior;
      pedidosRuta[index - 1].ordenRuta = esteOrden;

      onPedidosPorAsignarChanged();
      filtrarPedidosRuta();
    } catch (error) {
      alert("Error " + error);
    }
  }

  async function bajarPedido(pedido) {
    let index = pedidosRuta.findIndex((x) => x.id === pedido.id);

    if (index <= pedidosRuta.length - 1) {
      let pedidoSiguiente = pedidosRuta[index + 1];
      let estePedido = pedidosRuta[index];
      try {
        await CambiarOrdenPedidos(pedidoSiguiente.id, estePedido.id);

        let ordenSiguiente = pedidosRuta[index + 1].ordenRuta;
        let esteOrden = pedidosRuta[index].ordenRuta;

        pedidosRuta[index].ordenRuta = ordenSiguiente;
        pedidosRuta[index + 1].ordenRuta = esteOrden;

        onPedidosPorAsignarChanged();
        filtrarPedidosRuta();
      } catch (error) {
        alert("Error " + error);
      }
    }
  }

  async function quitarPedido(pedido) {
    try {
      var respuesta = await QuitarRutaPedido(pedido.id);

      // Quita de la ruta el pedido
      let index = pedidos.findIndex((x) => x.id === pedido.id);
      pedidos[index].rutaEnvio = null;

      // Toma la nueva numeracion de pedidos
      respuesta.map((p) => {
        let index = pedidos.findIndex((x) => x.id === p.id);
        pedidos[index].ordenRuta = p.ordenRuta;
      });

      filtrarPedidosRuta();
      onPedidosPorAsignarChanged();
    } catch (error) {
      alert("Error al quitar pedido de la ruta " + error);
    }
  }

  async function handleSave() {
    var err = ValidarRuta(numero, nombre, choferId);
    setError(err);
    if (err) return;

    ruta.numero = numero;
    ruta.nombre = nombre;
    ruta.choferId = choferId;

    try {
      await ActualizarRuta(ruta);
      onComplete(ruta);
    } catch (error) {
      alert("Error " + error);
    }
  }

  if (!choferes) return <h1>Cargando ...</h1>;

  return (
    <Modal show={true} onHide={() => onHide()}>
      <Modal.Header closeButton>
        <Modal.Title>Editar ruta {ruta.numero}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div>
          <div>
            <RutaMapa
              pedidos={pedidosRuta}
              height="30vh"
              width="100%"
              setColorOnEstatus
            ></RutaMapa>
          </div>
          <hr />
          <Form>
            <Form.Row>
              <Form.Group as={Col}>
                <Form.Label>Numero</Form.Label>
                <Form.Control
                  value={numero}
                  type="number"
                  onChange={(e) => setNumero(e.currentTarget.value)}
                />
              </Form.Group>
              <Form.Group as={Col}>
                <Form.Label>Nombre</Form.Label>
                <Form.Control
                  type="text"
                  value={nombre}
                  onChange={(e) => setNombre(e.currentTarget.value)}
                />
              </Form.Group>
              <Form.Group as={Col}>
                <Form.Label>Chofer</Form.Label>
                <Form.Control
                  as="select"
                  custom
                  value={choferId}
                  onChange={(e) => {
                    setChoferId(e.currentTarget.value);
                  }}
                >
                  {choferes.map((chofer) => (
                    <option key={chofer.id} value={chofer.id}>
                      {chofer.nombre}
                    </option>
                  ))}
                </Form.Control>
              </Form.Group>
            </Form.Row>
            <Form.Row>
              <div style={{ flexDirection: "column" }}>
                {error && (
                  <p>
                    <Alert variant="danger">{error}</Alert>
                  </p>
                )}
                <Button
                  id="editarRuta-btnSave"
                  variant="primary"
                  onClick={() => handleSave()}
                >
                  Guardar
                </Button>
              </div>
            </Form.Row>
          </Form>
          <hr />
          <div className="editarRuta-Pedidos">
            <div className="sectionHeader">
              <h5>
                Pedidos de la ruta &nbsp;
                {pedidosRuta && "(" + pedidosRuta.length + " pedidos)"}
              </h5>
            </div>
            {pedidosRuta &&
              pedidosRuta.map((pedido) => (
                <Card
                  key={pedido.id}
                  className="cardStyle"
                  style={{
                    backgroundColor: pedido.estatus == "4" ? "#d8fcd2" : "",
                  }}
                >
                  <Card.Body>
                    <Card.Title>
                      {pedido.id}
                      &nbsp;
                      {pedido.nombreCliente}
                    </Card.Title>
                    <div className="cardStyle-subTitle">
                      <div id="editarRuta-Pedido-Titulo">
                        <div id="editarRuta-Pedido-Titulo-Consecutivo">
                          R{pedido.rutaEnvio.numero}-{pedido.ordenRuta}
                        </div>
                        <div id="editarRuta-Pedido-Titulo-Domicilio">
                          <p>{pedido.domicilio}</p>
                          {pedido.notas != "" && (
                            <p>
                              <strong>{pedido.notas}</strong>
                            </p>
                          )}
                          <p>
                            {"Total: $"}
                            <strong>
                              <NumberFormat
                                value={pedido.total}
                                displayType="text"
                                decimalScale="2"
                                fixedDecimalScale="2"
                                thousandSeparator
                              />
                            </strong>
                          </p>
                        </div>
                      </div>
                      <div className="editarRuta-Pedido-Buttons">
                        <Button
                          id="btnQuitarPedidoRuta"
                          variant="danger"
                          onClick={async (e) => quitarPedido(pedido)}
                        >
                          <TrashFill color="white" size="24px" />
                        </Button>
                        <div>
                          <Button
                            id="btnVerPedidoRuta"
                            variant="info"
                            onClick={() => setPedidoConsultar(pedido)}
                          >
                            <Search color="white" size="24px" />
                          </Button>
                          <Button
                            id="btnSubirPedidoRuta"
                            variant="secondary"
                            disabled={pedido.ordenRuta == minOrden}
                            onClick={async () => subirPedido(pedido)}
                          >
                            <ArrowUpSquare color="white" size="24px" />
                          </Button>
                          <Button
                            id="btnBajarPedidoRuta"
                            variant="secondary"
                            disabled={pedido.ordenRuta == maxOrden}
                            onClick={async () => bajarPedido(pedido)}
                          >
                            <ArrowDownSquare color="white" size="24px" />
                          </Button>
                        </div>
                      </div>
                    </div>
                  </Card.Body>
                </Card>
              ))}
          </div>
          {pedidoConsultar && (
            <VerPedidoRuta
              pedido={pedidoConsultar}
              onHide={() => setPedidoConsultar(null)}
              onPedidoUbicacionChanged={onPedidoUbicacionChanged}
            />
          )}
        </div>
      </Modal.Body>
    </Modal>
  );
};

export default EditarRuta;

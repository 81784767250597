import React from "react";
import YesNoDialog from "../../../common/yesNoDialog";
import { EliminarRutaService } from "../../../services/backoffice/rutasServices";

const EliminarRuta = ({ ruta, onRutaDeleted, onHide }) => {
  return (
    <YesNoDialog
      title="Eliminar ruta"
      content="¿ Esta seguro que desea eliminar la ruta ?"
      onDialogResponse={(response) => {
        if (response == "yes") {
          EliminarRutaService(ruta);
          onRutaDeleted(ruta);
        } else {
          onHide();
        }
      }}
    />
  );
};

export default EliminarRuta;

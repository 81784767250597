import React, { useEffect, useState } from "react";

import { Modal, Card } from "react-bootstrap";
import { CargarPedidosDeLaRuta } from "../../../../services/backofficeService";

import { GetChoferes } from "../../../../services/backoffice/rutasServices";
import { TotalesRuta } from "../../../../services/backoffice/bakofficeHelpers";
import VerPedidoRuta from "../verPedidoRuta";
import LiquidarRutaPedido from "./liquidarRutaPedido";
import LiquidarRutaTotales from "./liquidarRutaTotales";

const LiquidarRuta = ({ ruta, onHide }) => {
  const [chofer, setChofer] = useState();
  const [totales, setTotales] = useState();
  const [pedidos, setPedidos] = useState();
  const [pedidoConsultar, setPedidoConsultar] = useState();

  const cargarChoferes = async () => {
    // Obtiene la lista de choferes
    const choferes = await GetChoferes();
    const chofer = choferes.find((c) => c.id == ruta.choferId);
    setChofer(chofer);
  };

  const cargarPedidos = async () => {
    const pedidos = await CargarPedidosDeLaRuta(ruta);
    const totales = await TotalesRuta(pedidos);
    setTotales(totales);
    setPedidos(pedidos);
  };

  useEffect(() => {
    cargarChoferes();
    cargarPedidos();
  }, []);

  if (!totales || !pedidos) return <div>Cargando ...</div>;

  return (
    <Modal show={true} onHide={() => onHide()} id="LiquidarRuta">
      <Modal.Header closeButton>
        <Modal.Title>Liquidar ruta {ruta.numero}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Card>
          <Card.Body id="LiquidarRuta-Ruta">
            <p id="ruta-numero">{ruta.numero}</p>
            <div>
              <p>{ruta.nombre} </p>
              <p>{chofer && chofer.nombre}</p>
            </div>
          </Card.Body>
        </Card>

        {pedidos
          .filter((p) => p.estatus === 4 || p.estatus === 1 || p.estatus === 2) // Entregado, enTransito, porEnviar
          .sort((a, b) => (a.ordenRuta > b.ordenRuta ? 1 : -1))
          .map((pedido) => (
            <LiquidarRutaPedido
              key={pedido.id}
              pedido={pedido}
              onPedidoClicked={(p) => setPedidoConsultar(p)}
              onPedidoChanged={(p) => setPedidos([...pedidos])}
            />
          ))}

        <LiquidarRutaTotales totales={totales} ruta={ruta} />

        {pedidoConsultar && (
          <VerPedidoRuta
            pedido={pedidoConsultar}
            onHide={() => setPedidoConsultar(null)}
          />
        )}
      </Modal.Body>
    </Modal>
  );
};

export default LiquidarRuta;

import React from "react";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import Slide from "@material-ui/core/Slide";
import { DialogActions } from "@material-ui/core";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="down" ref={ref} {...props} />;
});

const DentroDelAreaEntregaDialog = (props) => {
  if (!props.open) return "";
  return (
    <div id="dentroDelAreaDialog">
      <Dialog
        open={props.open}
        onClose={props.onClose}
        onButtonClick={props.onClose}
        TransitionComponent={Transition}
      >
        <DialogContent>
          <h1>Felicidades!</h1>
          <h3>Si estás dentro de nuestra área de cobertura</h3>
          <h4>Agrega al carrito los productos que deseas recibir</h4>
        </DialogContent>
        <DialogActions>
          <button
            className="areas"
            style={{ display: "block" }}
            onClick={props.onClose}
          >
            Continuar
          </button>{" "}
          <button
            className="botonCerrar material-icons"
            onClick={props.onClose}
          >
            clear
          </button>
        </DialogActions>
      </Dialog>
    </div>
  );
};
export default DentroDelAreaEntregaDialog;

import React, { Component } from "react";
import { Select, InputLabel, MenuItem } from "@material-ui/core";
import CategoriasProductos from "../../services/categoriasService";

//TODO: Ordenar categorías por nombre

class CategoriaProductoCombo extends Component {
  state = { categorias: [] };
  async componentDidMount() {
    var categorias = await CategoriasProductos();
    categorias.sort((a, b) => (a.nombre > b.nombre ? 1 : -1));
    this.setState({ categorias });
  }

  render() {
    return (
      <div>
        <InputLabel id="categoriaProductoCombo-Label">Categoría</InputLabel>
        <Select
          labelId="categoriaProductoCombo-Label"
          id="categoriaProductoCombo"
          value={this.props.value ? this.props.value : " "}
          onChange={this.props.onChange}
        >
          {this.state.categorias.map((categoria) => (
            <MenuItem key={categoria.id} value={categoria.id}>
              {categoria.nombre}
            </MenuItem>
          ))}
        </Select>
      </div>
    );
  }
}

export default CategoriaProductoCombo;

import React from "react";
import PageBanner from "../../common/pageBanner";
const LandingPageBanner = (props) => {
  return (
    <PageBanner
      height={200}
      imageUrl={"/img/landingPage/LandingPage_Banner.jpg"}
    ></PageBanner>
  );
};

export default LandingPageBanner;

import {
  Backdrop,
  CircularProgress,
  Select,
  Switch,
  TextField,
  IconButton,
} from "@material-ui/core";
import Close from "@material-ui/icons/Close";
import React, { Component } from "react";
import { GetOrdenesCompra } from "../../services/backofficeService";
import NumberFormat from "react-number-format";

import "./comprasPage.css";
import OrdenCompraPartidaDialog from "./ordenCompraPartidaDialog";
import OrdenCompraPartidaCard from "./ordenCompraPartidaCard";
import { TienePermiso } from "../../services/backofficeService";

class ListaComprasPage extends Component {
  state = {
    ordenesCompra: null,
    ordenSeleccionada: null,
    partidaSeleccionada: null,
    verSurtidos: false,
    buscar: "",
    partidas: [],
    partidasFiltradas: [],
  };

  async componentDidMount() {
    let respuesta = await TienePermiso(
      this.props.sessionId,
      "Compras/ListaCompras"
    );
    if (respuesta !== "") {
      alert(respuesta);
      window.location.href = "/backoffice";
      return;
    }

    const queryString = require("query-string");
    const parsed = queryString.parse(this.props.location.search);
    let ordenes = await GetOrdenesCompra(parsed.fecha);

    this.setState({ ordenesCompra: ordenes });

    // Si tiene ordenes carga la primera
    if (ordenes.length > 0) this.seleccionarOrden(ordenes[0].id);
  }

  // Carga la orden dada
  seleccionarOrden = (ordenId) => {
    let orden = null;
    let partidas = [];
    if (ordenId !== "") {
      orden = this.state.ordenesCompra.find((x) => x.id == ordenId);
      partidas = orden.partidas;
      this.setState({
        ordenSeleccionada: orden,
        partidas: partidas,
      });
    } else {
      this.state.ordenesCompra.forEach((orden) => {
        partidas = partidas.concat(orden.partidas);
      });
      this.setState({
        ordenSeleccionada: undefined,
        partidas,
      });
    }
    this.filtrarPartidas("", this.state.verSurtidos, partidas);
  };

  filtrarPartidas(buscar, verSurtidos, partidas) {
    if (!partidas) partidas = this.state.partidas;
    if (buscar) buscar = buscar.toLocaleLowerCase();
    else buscar = "";
    let partidasFiltradas = [];

    let buscarRef;
    if (buscar.startsWith("ref."))
      buscarRef = buscar.substring(4).toLocaleLowerCase();

    partidas.forEach((p) => {
      if (!verSurtidos && p.cantidadSurtida >= p.cantidadSolicitada) return;
      if (buscarRef && !p.referencia) return;
      if (
        p.referencia &&
        buscarRef &&
        !p.referencia.toLocaleLowerCase().startsWith(buscarRef)
      )
        return;

      if (
        buscar &&
        !buscarRef &&
        !p.producto.descripcion.toLocaleLowerCase().includes(buscar)
      )
        return;

      partidasFiltradas.push(p);
    });

    partidasFiltradas = partidasFiltradas.sort((a, b) =>
      a.cantidadSolicitada < b.cantidadSolicitada ? 1 : -1
    );

    this.setState({ buscar, partidasFiltradas, verSurtidos });
  }
  render() {
    const {
      ordenesCompra: ordenes,
      ordenSeleccionada,
      partidaSeleccionada,
      verSurtidos,
      buscar,
      partidas,
      partidasFiltradas,
    } = this.state;
    if (!ordenes)
      return (
        <Backdrop open={true}>
          <CircularProgress color="inherit" />
        </Backdrop>
      );
    if (ordenes.length === 0)
      return <h3>No hay órdenes de compra pendientes para el día de hoy</h3>;

    return (
      <div id="comprasPage">
        <div id="comprasPage_Header">
          <h1>Compras</h1>
          <div style={{ padding: "0.5rem" }}>
            <Select
              fullWidth
              variant="filled"
              native
              value={ordenSeleccionada ? ordenSeleccionada.id : ""}
              onChange={(event) => this.seleccionarOrden(event.target.value)}
            >
              <option value={""}>Todas</option>
              {ordenes.map((o) => (
                <option key={o.id} value={o.id}>
                  {o.proveedor.nombre}
                </option>
              ))}
            </Select>

            {ordenSeleccionada && (
              <div style={{ textAlign: "left" }}>
                <span>Orden:</span>
                <strong>{ordenSeleccionada.id}</strong>
              </div>
            )}

            <div>
              <TextField
                fullWidth
                label="Buscar"
                value={buscar}
                onChange={(e) =>
                  this.filtrarPartidas(e.target.value, verSurtidos)
                }
                InputProps={{
                  endAdornment: (
                    <React.Fragment>
                      <IconButton
                        onClick={() => this.filtrarPartidas("", verSurtidos)}
                        style={{ margin: "-0.5em" }}
                      >
                        <Close />
                      </IconButton>
                    </React.Fragment>
                  ),
                }}
                InputAdornmentProps={{
                  position: "start",
                  style: {
                    display: "none",
                  },
                }}
              />
            </div>
            <hr />
          </div>
        </div>
        <div id="comprasPage_ProductsList">
          {partidasFiltradas.map((p) => {
            return (
              <OrdenCompraPartidaCard
                key={p.id}
                partida={p}
                onPartidaSeleccionada={(partida) =>
                  this.setState({ partidaSeleccionada: partida })
                }
              />
            );
          })}
        </div>
        <div id="comprasPage_Footer">
          <div>
            <label htmlFor="switchSurtidos">
              {!verSurtidos ? "Ver surtidos" : "Ocultar surtidos"}
            </label>
            <Switch
              id="switchSurtidos"
              value={verSurtidos}
              variant="success"
              onChange={(e) => {
                this.filtrarPartidas(buscar, !verSurtidos);
              }}
            ></Switch>
          </div>
          <div id="comprasPage_Footer_Total">
            Total:{" "}
            <strong>
              $
              <NumberFormat
                value={partidasFiltradas.reduce(
                  (suma, partida) =>
                    suma +
                    Number(partida.cantidadSurtida * partida.precioSurtido),
                  0
                )}
                displayType="text"
                decimalScale="2"
                fixedDecimalScale="2"
                thousandSeparator
              />
            </strong>
          </div>
        </div>
        {partidaSeleccionada && (
          <OrdenCompraPartidaDialog
            partida={partidaSeleccionada}
            onHide={() => this.setState({ partidaSeleccionada: undefined })}
            onSave={() =>
              this.setState({
                partidaSeleccionada: undefined,
                ...partidas,
              })
            }
          />
        )}
      </div>
    );
  }
}

export default ListaComprasPage;

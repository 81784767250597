import React, { useState, useEffect } from "react";
import { Card, Button, Form } from "react-bootstrap";
import EditarRuta from "./editarRuta";
import EliminarRuta from "./eliminarRuta";
import NuevaRuta from "./nuevaRuta";
import {
  GetPedidosDeLaRuta,
  CambiarOrdenRutas,
} from "../../../services/backoffice/rutasServices";
import { GetRouteColor } from "./rutasHelpers";

import {
  PencilFill,
  TrashFill,
  CartCheckFill,
  ArrowUpSquare,
  ArrowDownSquare,
} from "react-bootstrap-icons";
import LiquidarRuta from "./liquidarRuta/liquidarRuta";
import PedidosPorAsignar from "./pedidosPorAsignar";

const editModes = {
  None: "none",
  Add: "add",
  Edit: "edit",
  Delete: "delete",
  Finish: "finish",
};

const ListaRutas = ({
  planeacion,
  selectedRutaChanged,
  onPedidosPorAsignarChanged,
  onPedidoUbicacionChanged,
}) => {
  const [currentEditMode, setCurrentEditMode] = useState(editModes.None);
  const [selectedRuta, setSelectedRuta] = useState();
  const [minOrden, setMinOrden] = useState();
  const [maxOrden, setMaxOrden] = useState();

  useEffect(() => {
    let rutas = planeacion.rutas;
    if (rutas && rutas.length > 0) {
      let min = rutas.reduce(
        (min, p) => (p.numero < min ? p.numero : min),
        rutas[0].numero
      );
      setMinOrden(min);
      let max = rutas.reduce(
        (max, p) => (p.numero > max ? p.numero : max),
        rutas[0].numero
      );
      setMaxOrden(max);
    }
  }, []);

  function countPedidosEnRuta(ruta) {
    let pedidosEnRuta = GetPedidosDeLaRuta(planeacion.pedidos, ruta);
    if (!pedidosEnRuta || pedidosEnRuta.length === 0) return 0;
    else return pedidosEnRuta.length;
  }

  function countPedidosEntregados(ruta) {
    let pedidosEnRuta = GetPedidosDeLaRuta(planeacion.pedidos, ruta);
    return pedidosEnRuta.filter((p) => p.estatus === 4).length;
  }

  async function subirRuta(ruta) {
    let rutas = planeacion.rutas;
    let index = rutas.findIndex((x) => x.id === ruta.id);
    if (index <= 0) return;

    let rutaAnterior = rutas[index - 1];
    let estaRuta = rutas[index];
    try {
      await CambiarOrdenRutas(rutaAnterior.id, estaRuta.id);
      let ordenAnterior = rutaAnterior.numero;
      let esteOrden = estaRuta.numero;

      rutas[index].numero = ordenAnterior;
      rutas[index - 1].numero = esteOrden;

      onPedidosPorAsignarChanged();
    } catch (error) {
      alert("Error " + error);
    }
  }

  async function bajarRuta(ruta) {
    let rutas = planeacion.rutas;
    let index = rutas.findIndex((x) => x.id === ruta.id);
    if (index > rutas.length - 1) return;

    let rutaSiguiente = rutas[index + 1];
    let estaRuta = rutas[index];
    try {
      await CambiarOrdenRutas(rutaSiguiente.id, estaRuta.id);

      let ordenSiguiente = rutaSiguiente.numero;
      let esteOrden = estaRuta.numero;

      estaRuta.numero = ordenSiguiente;
      rutaSiguiente.numero = esteOrden;

      onPedidosPorAsignarChanged();
    } catch (error) {
      alert("Error " + error);
    }
  }

  return (
    <div>
      <div className="sectionHeader">
        <h5>Rutas</h5>
        <Button
          variant="primary"
          onClick={() => {
            setCurrentEditMode(editModes.Add);
          }}
        >
          Nueva ruta
        </Button>
      </div>

      <div>
        {planeacion &&
          planeacion.rutas &&
          planeacion.rutas
            .sort((a, b) => (a.numero > b.numero ? 1 : -1))
            .map((ruta) => (
              <Card key={ruta.id} className="cardStyle">
                <Card.Body>
                  <Card.Title>
                    <Form.Check
                      label={`R${ruta.numero} - ${ruta.nombre} - ${ruta.chofer.nombre}`}
                      checked={ruta.id == selectedRuta}
                      onChange={(e) => {
                        setSelectedRuta(ruta.id);
                        selectedRutaChanged(ruta);
                      }}
                    ></Form.Check>
                  </Card.Title>
                  <div className="cardStyle-subTitle">
                    <div className="cardStyle-subTitle">
                      <div
                        className="pin"
                        style={{
                          backgroundColor: GetRouteColor(ruta),
                        }}
                      />
                      <p>
                        {countPedidosEnRuta(ruta) +
                          " pedidos / " +
                          countPedidosEntregados(ruta) +
                          " entregados"}
                      </p>
                    </div>
                  </div>
                  <div>
                    <Button
                      id="btnLiquidarRuta"
                      onClick={(e) => {
                        setSelectedRuta(ruta);
                        setCurrentEditMode(editModes.Finish);
                      }}
                    >
                      <CartCheckFill color="white" size="24px" />
                    </Button>
                    <Button
                      id="btnEditarRuta"
                      onClick={(e) => {
                        setSelectedRuta(ruta);
                        setCurrentEditMode(editModes.Edit);
                      }}
                    >
                      <PencilFill color="white" size="24px" />
                    </Button>
                    <Button
                      id="btnEliminarRuta"
                      variant="danger"
                      onClick={(e) => {
                        setSelectedRuta(ruta);
                        setCurrentEditMode(editModes.Delete);
                      }}
                    >
                      <TrashFill color="white" size="24px" />
                    </Button>
                    <Button
                      id="btnSubirPedidoRuta"
                      variant="secondary"
                      disabled={ruta.numero === minOrden}
                      onClick={async () => subirRuta(ruta)}
                    >
                      <ArrowUpSquare color="white" size="24px" />
                    </Button>
                    <Button
                      id="btnBajarPedidoRuta"
                      variant="secondary"
                      disabled={ruta.numero === maxOrden}
                      onClick={async () => bajarRuta(ruta)}
                    >
                      <ArrowDownSquare color="white" size="24px" />
                    </Button>
                  </div>
                </Card.Body>
              </Card>
            ))}
      </div>

      {currentEditMode === editModes.Add && (
        <NuevaRuta
          planeacion={planeacion}
          onComplete={(nuevaRuta) => {
            planeacion.rutas.push(nuevaRuta);
            setCurrentEditMode(editModes.None);
            onPedidosPorAsignarChanged();
          }}
          onHide={() => setCurrentEditMode(editModes.None)}
        />
      )}
      {currentEditMode === editModes.Edit && (
        <EditarRuta
          ruta={selectedRuta}
          pedidos={planeacion.pedidos}
          onHide={() => setCurrentEditMode(editModes.None)}
          onComplete={(ruta) => {
            let currentIndex = planeacion.rutas.findIndex(
              (r) => r.id === ruta.id
            );
            planeacion[currentIndex] = ruta;
            setCurrentEditMode(editModes.None);
          }}
          onPedidosPorAsignarChanged={() => onPedidosPorAsignarChanged()}
          onPedidoUbicacionChanged={() => onPedidoUbicacionChanged()}
        />
      )}
      {currentEditMode === editModes.Delete && (
        <EliminarRuta
          ruta={selectedRuta}
          onHide={() => setCurrentEditMode(editModes.None)}
          onRutaDeleted={(ruta) => {
            let currentIndex = planeacion.rutas.findIndex(
              (r) => r.id === ruta.id
            );
            planeacion.rutas.splice(currentIndex, 1);
            planeacion.pedidos.forEach((pedido) => {
              if (pedido.rutaEnvio && pedido.rutaEnvio.id === ruta.id)
                pedido.rutaEnvio = null;
            });

            setCurrentEditMode(editModes.None);
            onPedidosPorAsignarChanged();
          }}
        />
      )}
      {currentEditMode === editModes.Finish && (
        <LiquidarRuta
          ruta={selectedRuta}
          onHide={() => setCurrentEditMode(editModes.None)}
        />
      )}
    </div>
  );
};

export default ListaRutas;

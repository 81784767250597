import React from "react";
import { Backdrop, CircularProgress, Paper } from "@material-ui/core";
import { LlenarProductos } from "../../services/backofficeService";
import {
  Grid,
  Table,
  TableHeaderRow,
  TableFilterRow,
  PagingPanel,
  SearchPanel,
  Toolbar,
  DragDropProvider,
  TableColumnReordering,
  VirtualTable,
} from "@devexpress/dx-react-grid-material-ui";
import {
  FilteringState,
  IntegratedFiltering,
  IntegratedPaging,
  PagingState,
  SearchState,
  TableColumnResizing,
  IntegratedSorting,
} from "@devexpress/dx-react-grid";
import { fade } from "@material-ui/core/styles/colorManipulator";
import { withStyles } from "@material-ui/core/styles";

const DxGrid = (props) => {
  return (
    <Paper>
      <Grid
        rows={props.rows}
        columns={props.columns}
        getRowId={(row) => row.id}
      >
        <DragDropProvider />
        <FilteringState defaultFilters={[]} />
        <SearchState />
        <IntegratedFiltering />
        <VirtualTable
          height={props.height}
          rowComponent={({ row, ...restProps }) => (
            <Table.Row
              {...restProps}
              onClick={() => props.onRowClick(row)}
              style={{
                cursor: "pointer",
              }}
            />
          )}
          messages={{ noData: "No hay registros coincidentes" }}
        />
        <TableColumnReordering
          defaultOrder={props.columns.map((column) => column.name)}
        />
        <TableColumnResizing defaultColumnWidths={props.defaultColumnWidths} />
        <TableHeaderRow />
        <TableFilterRow
          showFilterSelector={props.showFilterSelector}
          messages={{
            filterPlaceholder: "Filtrar...",
            contains: "Contiene",
            notContains: "No contiene",
            startsWith: "Comienza con",
            endsWith: "Termina con",
            equal: "Igual",
            notEqual: "No es igual",
            greaterThan: "Mayor que",
            greaterThanOrEqual: "Mayor o igual que",
            lessThan: "Menor que",
            lessThanOrEqual: "Menor o igual que",
          }}
        />
        <Toolbar />
        <SearchPanel messages={{ searchPlaceholder: "Buscar" }} />
      </Grid>
    </Paper>
  );
};

export default DxGrid;

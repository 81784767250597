import React from "react";

const PageFooter = () => {
  return (
    <div className="div100">
      <a
        href="https://api.whatsapp.com/send?phone=523326117667"
        target="_blank"
      >
        <div
          className="superWhats"
          style={{
            backgroundImage: "url(/img/WhatsApp.svg)",
            backgroundSize: "cover",
            height: "64px",
            width: "64px",
          }}
        >
          <i className="im im-whatsapp"></i>
        </div>
      </a>

      <div className="clear20"></div>
      <div className="div100 foots">
        <div className="cont">
          <div className="contF padd10">
            <div
              className="left centrado padd10"
              style={{ marginRight: "30px", fontSize: "10px" }}
            >
              <img id="headerLogo" src="/img/logo.svg" height="100"></img>
              <div className="clear5"></div>© 2020 Todo a mi puerta.com
            </div>

            <div className="left  textAlignLeft padd10">
              <div className="clear20"></div>
              Acerca de Todo a mi puerta
              <div className="clear5"></div>
              Términos y condiciones
              <div className="clear5"></div>
              Dudas frecuentes
              <div className="clear5"></div>
            </div>

            <div className="right  textAlignLeft padd10">
              <div className="redes">
                <a
                  href="https://api.whatsapp.com/send?phone=523326117667"
                  target="_blank"
                >
                  <i className="im im-whatsapp"></i>
                </a>
              </div>

              <div className="redes">
                <a
                  href="https://www.facebook.com/Todo-a-mi-puerta"
                  target="_blank"
                >
                  <i className="im im-facebook"></i>
                </a>
              </div>

              <div className="redes">
                <a
                  href="https://www.instagram.com/todoamipuerta/?hl=es-la"
                  target="_blank"
                >
                  <i className="im im-instagram"></i>
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PageFooter;

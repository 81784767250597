import React, { useState, useEffect } from "react";
import { Button } from "react-bootstrap";
import { Login } from "../../services/backofficeService";
import Input from "../utils/input";
import {
  GetBackofficeUserSession,
  SetBackofficeUserSession,
} from "../utils/sessionHelper";

const SecureArea = ({ children }) => {
  const [currentSession, setCurrentSession] = useState();
  const [userName, setUserName] = useState();
  const [password, setPassword] = useState();
  const [errorText, setErrorText] = useState();

  useEffect(() => {
    if (currentSession) return;

    let backofficeUserSession = GetBackofficeUserSession();

    if (backofficeUserSession) setCurrentSession(backofficeUserSession);
  }, []);

  if (currentSession)
    return (
      <div>
        {React.cloneElement(children, { sessionId: currentSession.id })}
      </div>
    );

  const login = async () => {
    try {
      setErrorText();
      let response = await Login(userName, password);
      SetBackofficeUserSession(response);

      setCurrentSession(response);
    } catch (error) {
      let errorMessage = "Ocurrió un error al iniciar sesión";
      if (error.response && error.response.data)
        errorMessage = error.response.data;
      setErrorText(errorMessage);
    }
  };

  return (
    <div
      className="div100"
      style={{
        height: "100vh",
        backgroundImage:
          "url(/img/landingPage/bannerImages/LandingPage_Banner.jpg)",
        backgroundSize: "cover",
        backgroundRepeat: "no-repeat",
        backgroundPosition: "left center",
      }}
    >
      <div className="headerLogo">
        <a href="/">
          <img id="headerLogo" src="/img/logoBlanco.svg" height="120"></img>
        </a>
      </div>
      <div
        style={{
          backgroundColor: "rgba(255, 255, 255, 0.8)",
          width: "350px",
          marginTop: "140px",
          marginLeft: "auto",
          marginRight: "auto",
          padding: "12px",
        }}
      >
        <h2>Iniciar sesión</h2>
        {/* <form autoComplete="on" onSubmit={(e) => login(e)}> */}
        <div style={{ marginTop: "10px" }}>
          <Input
            autoComplete="username"
            label="Usuario"
            value={userName}
            onChange={(e) => setUserName(e.currentTarget.value)}
          />
          <Input
            autoComplete="password"
            label="Contraseña"
            type="password"
            value={password}
            onChange={(e) => setPassword(e.currentTarget.value)}
          />
        </div>
        <div style={{ marginTop: "40px" }}>
          <hr />
          <p style={{ color: "red", fontWeight: "bold" }}>{errorText}</p>
          <Button type="submit" onClick={() => login()}>
            Ingresar
          </Button>
        </div>

        {/* </form> */}
      </div>
    </div>
  );
};

export default SecureArea;

import React, { useState, useEffect } from "react";
import { Button, Modal } from "react-bootstrap";
import NumberFormat from "react-number-format";

import { RegistrarCompraSurtida } from "../../services/backofficeService";

const OrdenCompraPartidaDialog = ({ partida, onHide, onSave }) => {
  const [cantidadSurtida, setCantidadSurtida] = useState();
  const [precioUnitario, setPrecioUnitario] = useState(0.0);
  const [referencia, setReferencia] = useState();
  const [errorMessage, setErrorMessage] = useState("");

  useEffect(() => {
    if (!partida) return;
    let porSurtir = partida.cantidadSurtida;
    if (porSurtir === 0) porSurtir = partida.cantidadSolicitada;
    setCantidadSurtida(porSurtir);

    let precio = partida.precioSurtido;
    if (partida.precioSurtido <= 0) precio = partida.producto.costoUnitario;
    setPrecioUnitario(precio);

    setReferencia(partida.referencia);
  }, []);

  if (!partida || !partida.producto) return <span />;
  return (
    <Modal show={true} onHide={() => onHide()}>
      <Modal.Header closeButton>
        <Modal.Title>{partida.producto.descripcion}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div id="comprasPage_Modal_Body">
          <img src={partida.producto.imagenUrl} alt={partida.producto.id} />
          <hr />
          <div>
            Cantidad solicitada:
            <strong>
              {partida.cantidadSolicitada} {partida.producto.unidad}
            </strong>
          </div>
          <div id="comprasPage_Modal_Cantidad">
            Cantidad comprada:
            <Button
              variant="warning"
              onClick={() => {
                let cantidad = parseFloat(cantidadSurtida);
                if (isNaN(cantidad)) cantidad = 0;
                if (cantidad > 0) cantidad--;
                setCantidadSurtida(cantidad);
              }}
            >
              -
            </Button>
            <input
              type="text"
              value={cantidadSurtida}
              onChange={(e) => setCantidadSurtida(e.target.value)}
            />
            <Button
              variant="success"
              onClick={() => {
                let cantidad = parseFloat(cantidadSurtida);
                if (isNaN(cantidad)) cantidad = 0;
                cantidad++;
                setCantidadSurtida(cantidad);
              }}
            >
              +
            </Button>
          </div>
          <div id="comprasPage_Modal_Precio">
            Precio unitario:
            <input
              type="text"
              value={precioUnitario}
              onChange={(e) => setPrecioUnitario(e.target.value)}
            />
          </div>
          <div id="comprasPage_Modal_Importe">
            Importe:{" "}
            <strong>
              $
              <NumberFormat
                value={(precioUnitario * cantidadSurtida).toFixed(2)}
                displayType="text"
                decimalScale="2"
                fixedDecimalScale="2"
                thousandSeparator
              />
            </strong>
          </div>
          <div id="comprasPage_Modal_Referencia">
            Referencia:
            <input
              type="text"
              value={referencia}
              onChange={(e) => setReferencia(e.target.value)}
            />
          </div>
        </div>
      </Modal.Body>
      <Modal.Footer>
        <div id="comprasPage_Modal_Footer">
          <div>
            <span class="inputError">{errorMessage}</span>
          </div>
          <div id="comprasPage_Modal_Footer_buttons">
            {/* TODO: Crear orden para esta partida */}
            <Button variant="secondary">Crear orden</Button>
            <Button
              variant="primary"
              onClick={async () => {
                setErrorMessage("");
                if (isNaN(cantidadSurtida) | (cantidadSurtida < 0)) {
                  setErrorMessage("Cantidad invalida");
                  return;
                }
                if (
                  isNaN(precioUnitario) ||
                  (cantidadSurtida > 0 && precioUnitario <= 0)
                ) {
                  setErrorMessage("Precio invalido");
                  return;
                }

                // Si excede la cantidad solicitada, pide confirmacion
                if (cantidadSurtida > partida.cantidadSolicitada)
                  if (
                    !window.confirm(
                      "¿ Esta seguro que desea exceder la cantidad solicitada?"
                    )
                  )
                    return;

                // Guarda el cambio en backend
                try {
                  await RegistrarCompraSurtida(
                    partida.ordenCompraId,
                    partida.id,
                    cantidadSurtida,
                    precioUnitario,
                    referencia
                  );
                } catch (Error) {
                  setErrorMessage("Error al guardar");
                  return;
                }

                // Guarda el cambio en el estado
                partida.cantidadSurtida = cantidadSurtida;
                partida.precioSurtido = precioUnitario;
                partida.referencia = referencia;

                onSave();
              }}
            >
              Guardar
            </Button>
          </div>
        </div>
      </Modal.Footer>
    </Modal>
  );
};

export default OrdenCompraPartidaDialog;

import React, { Component } from "react";

import ProductosPageListItem from "./productosPageListItem";

class ProductosPageList extends Component {
  state = {};
  render() {
    return (
      <div id="productosPageList">
        {this.props.productos.map((producto) => (
          <ProductosPageListItem
            key={producto.id}
            onCantidadChanged={this.props.onCantidadChanged}
            productoId={producto.id}
            imagenUrl={producto.imagenUrl}
            descripcion={producto.descripcion}
            cantidad={producto.cantidad}
            precio={producto.precio}
            unidad={producto.unidad}
            intervalo={producto.intervalo}
            agotado={producto.agotado}
          />
        ))}
      </div>
    );
  }
}

export default ProductosPageList;

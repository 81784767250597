import React, { Component } from "react";
import { Backdrop, CircularProgress } from "@material-ui/core";
import {
  LlenarPrecios,
  RecalcularPrecio,
  TienePermiso,
} from "../../services/backofficeService";
import BackofficeBanner from "../backofficeBanner";

import "./preciosPage.css";
import { AddAlertRounded } from "@material-ui/icons";

class PreciosPage extends Component {
  state = {
    todosLosProductos: [],
    productos: [],
    searchText: "",
    showBackdrop: true,
  };

  async componentDidMount() {
    var respuesta = await TienePermiso(
      this.props.sessionId,
      "Catálogos/Productos/Precios"
    );
    if (respuesta !== "") {
      alert(respuesta);
      window.location.href = "/backoffice";
      return;
    }

    var productos = await LlenarPrecios();
    this.setState({
      todosLosProductos: productos,
      productos: productos,
      showBackdrop: false,
    });
  }

  handleSearchTextChanged(e) {
    var searchText = e.currentTarget.value;

    var searchTextLowerCase = searchText.toLowerCase();
    const prods = [...this.state.todosLosProductos];
    const productos = prods.filter((m) =>
      m.descripcion.toLowerCase().includes(searchTextLowerCase)
    );

    this.setState({ searchText, productos });
  }

  handleCostoMargenChanged(e, propiedad, index) {
    const productos = [...this.state.productos];
    const producto = productos[index];
    if (!isNaN(e.currentTarget.value))
      producto[propiedad] = e.currentTarget.value;
    this.setState({ productos });
  }

  handleCostoMargenOnFocus(e, propiedad, index) {
    const producto = this.state.productos[index];
    if (!producto[propiedad + "_original"])
      producto[propiedad + "_original"] = e.currentTarget.value;
  }

  //TODO: Validar cambio de precio cuando la lista está filtrada
  // ahorita solo estoy cambiando en Productos pero falta ver que afecte a TodosLosProductos

  async handleCostoMargenLostFocus(e, propiedad, index) {
    // Si ha cambiado el valor lo manda al servidor
    const producto = this.state.productos[index];
    const valorNuevo = producto[propiedad];
    const valorAnterior = producto[propiedad + "_original"];
    if (valorNuevo != valorAnterior) {
      // Si la diferencia entre los precios es mayor al 20%, pide confirmación al usuario
      if (valorAnterior !== 0) {
        var diferencia = Math.abs((valorNuevo - valorAnterior) / valorAnterior);
        if (diferencia > 0.2) {
          var mensaje = `El diferencial entre el precio anterior (${valorAnterior}) y el nuevo precio (${valorNuevo}) es mayor al 20%. ¿ Aún así desea aplicar el cambio ?`;
          if (!window.confirm(mensaje)) {
            producto[propiedad] = valorAnterior;
            e.currentTarget.value = valorAnterior;
            return;
          }
        }
      }

      try {
        var nuevoPrecio = await RecalcularPrecio(
          producto.id,
          propiedad,
          valorNuevo
        );
        console.log(nuevoPrecio);
        const productos = [...this.state.productos];
        productos[index].precio = nuevoPrecio;
        this.setState({ productos });
      } catch (exception) {
        alert(exception);
        e.preventDefault();
        return;
      }
      producto[propiedad + "_original"] = valorNuevo;
    }
  }

  render() {
    return (
      <div className="App">
        <BackofficeBanner title="Precios y costos" />

        <div className="container-md mb-2">
          <input
            className="form-control form-control-lg"
            type="text"
            placeholder="Buscar"
            onChange={(e) => this.handleSearchTextChanged(e)}
          />
        </div>
        <div className="container">
          <Backdrop open={this.state.showBackdrop}>
            <CircularProgress color="inherit" />
          </Backdrop>

          <table id="preciosTable" className="table">
            <thead className="thead-dark">
              <tr>
                <th>Categoría</th>
                <th>Id</th>
                <th>Descripción</th>
                <th>Intervalo</th>
                <th>Unidad</th>
                <th>Costo</th>
                <th>Margen</th>
                <th>Precio</th>
              </tr>
            </thead>
            <tbody>
              {this.state.productos.map((producto, index) => (
                <tr key={producto.id}>
                  <td className="text-left">{producto.categoria}</td>
                  <td className="text-left">{producto.id}</td>
                  <td className="text-left">{producto.descripcion}</td>
                  <td className="text-left">{producto.intervalo}</td>
                  <td className="text-left">{producto.unidad}</td>
                  <td>
                    <input
                      value={producto.costoUnitario}
                      className="form-control text-right"
                      type="number"
                      onChange={(e) =>
                        this.handleCostoMargenChanged(e, "costoUnitario", index)
                      }
                      onFocus={(e) =>
                        this.handleCostoMargenOnFocus(e, "costoUnitario", index)
                      }
                      onBlur={(e) =>
                        this.handleCostoMargenLostFocus(
                          e,
                          "costoUnitario",
                          index
                        )
                      }
                    />
                  </td>
                  <td className="text-right">
                    <input
                      value={producto.margen}
                      className="form-control text-right"
                      type="text"
                      onChange={(e) =>
                        this.handleCostoMargenChanged(e, "margen", index)
                      }
                      onFocus={(e) =>
                        this.handleCostoMargenOnFocus(e, "margen", index)
                      }
                      onBlur={(e) =>
                        this.handleCostoMargenLostFocus(e, "margen", index)
                      }
                    />
                  </td>
                  <td className="text-right">{producto.precio}</td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
    );
  }
}

export default PreciosPage;

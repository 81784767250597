import React from "react";
import NumberFormat from "react-number-format";
import { TextField } from "@material-ui/core";

const InputNumeric = ({
  name,
  label,
  value,
  error,
  onChange,
  fixedDecimalScale,
  decimalScale,
  allowNegative,
  ...props
}) => {
  return (
    <NumberFormat
      name={name}
      label={label}
      value={value}
      error={error}
      onValueChange={(values) => onChange(name, values)}
      fixedDecimalScale={fixedDecimalScale}
      decimalScale={decimalScale}
      allowNegative={allowNegative}
      customInput={TextField}
      isNumericString
      {...props}
    />
  );
};

export default InputNumeric;

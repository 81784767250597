import React, { useEffect, useState } from "react";

import { Modal, Button, Card, Form, Col } from "react-bootstrap";
import NumberFormat from "react-number-format";

import RutaMapa, { Marker } from "./rutaMapa";
import { GetPedido } from "../../../services/backofficeService";
import { GuardarUbicacionPedido } from "../../../services/backoffice/rutasServices";
import { GeoCodeFromAddress } from "../../../services/mapsHelper";

import {
  CalcularPedidoTotalEntregado,
  CalcularImportePartidaEntregado,
  CalcularPedidoTotalPagado,
  CalcularSaldoPedido,
} from "../../../services/backoffice/bakofficeHelpers";

import moment from "moment";

const VerPedidoRuta = ({ pedido, onHide, onPedidoUbicacionChanged }) => {
  const [nuevaUbicacion, setNuevaUbicacion] = useState();
  const [currentPedido, setCurrentPedido] = useState(pedido);
  const [pedidoCompleto, setPedidoCompleto] = useState();
  const [buscar, setBuscar] = useState(pedido.domicilio);
  const [mapCenter, setMapCenter] = useState({
    lat: pedido.latitude,
    lng: pedido.longitude,
  });
  const [mapZoom, setMapZoom] = useState(11);

  useEffect(() => {
    async function CargarPedido(id) {
      let ped = await GetPedido(pedido.id);
      setPedidoCompleto(ped);
    }
    CargarPedido(pedido.id);
  }, [pedido]);

  function handleOnMapClick(e) {
    if (e.lat != currentPedido.latitude || e.lng != currentPedido.longitude)
      changePedidoLocation(e.lat, e.lng);
  }

  function changePedidoLocation(lat, lng) {
    let newPed = { ...currentPedido, latitude: lat, longitude: lng };
    setCurrentPedido(newPed);
    setNuevaUbicacion({ lat: lat, lng: lng });
  }

  async function guardarNuevaUbicacion() {
    await GuardarUbicacionPedido(currentPedido);
    if (onPedidoUbicacionChanged) onPedidoUbicacionChanged(currentPedido);
  }

  async function Buscar() {
    if (!buscar) return;

    try {
      const coords = await GeoCodeFromAddress(buscar);
      changePedidoLocation(coords.lat, coords.lng);

      setMapCenter({ lat: coords.lat, lng: coords.lng });
      setMapZoom(16);
    } catch (error) {
      alert("No fue posible encontrar el domicilio");
      console.log(error);
    }
  }

  if (!pedidoCompleto) return <div />;

  return (
    <Modal show={true} onHide={() => onHide()}>
      <Modal.Header closeButton>
        <Modal.Title>Ver pedido {pedido.id}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div>
          <div>
            <RutaMapa
              pedidos={[currentPedido]}
              height="30vh"
              width="100%"
              center={mapCenter}
              zoom={mapZoom}
              onMapClick={handleOnMapClick}
            >
              <Marker
                lat={pedidoCompleto.latitudeEntrega}
                lng={pedidoCompleto.longitudeEntrega}
                color="#5efc03"
              />
            </RutaMapa>
            <div id="verPedidoRuta-Pedido-Buscar">
              <Form inline>
                <Form.Row>
                  <Col xs="auto">
                    <Form.Control
                      id="verPedidoRuta-Pedido-Buscar-Input"
                      value={buscar}
                      placeholder="Buscar"
                      onChange={(e) => setBuscar(e.target.value)}
                      onClick={() => Buscar()}
                    ></Form.Control>
                  </Col>
                  <Col xs="auto">
                    <Button onClick={Buscar}>Buscar</Button>
                  </Col>
                </Form.Row>
              </Form>
              <Button
                id="verPedidoRuta-Pedido-Buscar-Button"
                hidden={!nuevaUbicacion}
                onClick={() => guardarNuevaUbicacion()}
              >
                Guardar
              </Button>
            </div>
          </div>
        </div>
        <div>
          <Card className="cardStyle">
            <Card.Body>
              <Card.Title>{pedidoCompleto.nombre}</Card.Title>
              <div
                className="cardStyle-subTitle"
                id="verPedidoRuta-Pedido-SubTitle"
              >
                <p>{pedido.domicilio}</p>
                {pedidoCompleto.notas && (
                  <p id="verPedidoRuta-Pedido-Notas">{pedidoCompleto.notas}</p>
                )}
              </div>
            </Card.Body>
          </Card>

          {pedidoCompleto.estatus === 4 && (
            <Card className="cardStyle">
              <Card.Body>
                <div>
                  <div>
                    Fecha entrega:{" "}
                    <a
                      href="/"
                      onClick={(e) => {
                        e.preventDefault();
                        setMapCenter({
                          lat: pedidoCompleto.latitudeEntrega,
                          lng: pedidoCompleto.longitudeEntrega,
                        });
                        setMapZoom(16);
                      }}
                    >
                      <strong>
                        {moment(pedidoCompleto.fechaEntregaReal).format(
                          "DD/MM/YY HH:mm"
                        )}
                      </strong>
                    </a>
                  </div>
                  <div>
                    Notas entrega:{" "}
                    <strong>{pedidoCompleto.notasEntrega}</strong>
                  </div>
                </div>
              </Card.Body>{" "}
            </Card>
          )}

          <Card className="cardStyle">
            <Card.Body>
              {pedidoCompleto.partidas &&
                pedidoCompleto.partidas
                  .sort((a, b) =>
                    a.producto.descripcion
                      .toLowerCase()
                      .localeCompare(b.producto.descripcion.toLowerCase())
                  )
                  .map((partida) => (
                    <div key={partida.id} className="verPedidoRuta-Partida">
                      <div id="verPedidoRuta-Partida-Image">
                        <img src={partida.producto.imagenUrl} />
                      </div>
                      <div id="verPedidoRuta-Partida-Detalles">
                        <div id="verPedidoRuta-Partida-Detalles-1">
                          <p id="verPedidoRuta-Partida-Descripcion">
                            {partida.producto.descripcion}
                          </p>
                          <div>
                            <p>
                              <strong>
                                {partida.cantidad} {partida.producto.unidad}
                              </strong>
                            </p>
                            <p>
                              <strong>
                                {"$ "}
                                <NumberFormat
                                  value={partida.importe}
                                  displayType="text"
                                  decimalScale="2"
                                  fixedDecimalScale="2"
                                  thousandSeparator
                                />
                              </strong>
                            </p>
                          </div>
                        </div>
                        <div
                          id="verPedidoRuta-Partida-Detalles-2"
                          className={
                            partida.cantidad > partida.cantidadEntregada
                              ? "color-diferencia"
                              : "color-ok"
                          }
                        >
                          {partida.cantidad != partida.cantidadEntregada && (
                            <div>
                              {partida.cantidadEntregada}{" "}
                              {partida.producto.unidad}{" "}
                              <p id="verPedidoRuta-Partida-Importe">
                                {partida.cantidadEntregada > partida.cantidad
                                  ? "$ +"
                                  : "$ "}
                                <NumberFormat
                                  value={
                                    CalcularImportePartidaEntregado(partida) -
                                    partida.importe
                                  }
                                  displayType="text"
                                  decimalScale="2"
                                  fixedDecimalScale="2"
                                  thousandSeparator
                                />
                              </p>
                            </div>
                          )}
                        </div>
                      </div>
                    </div>
                  ))}
            </Card.Body>
          </Card>
        </div>
        <div>
          <Card className="cardStyle">
            <Card.Body>
              <div className="tablaTotales">
                <div>
                  <div className="tablaTotales-title">Total:</div>
                  <div className="tablaTotales-value">
                    {" "}
                    <NumberFormat
                      value={pedidoCompleto.total}
                      displayType="text"
                      decimalScale="2"
                      fixedDecimalScale="2"
                      thousandSeparator
                    />
                  </div>
                </div>
                <div>
                  <div className="tablaTotales-title">Entregado:</div>
                  <div className="tablaTotales-value">
                    <NumberFormat
                      value={
                        CalcularPedidoTotalEntregado(pedidoCompleto)
                          .totalEntregado
                      }
                      displayType="text"
                      decimalScale="2"
                      fixedDecimalScale="2"
                      thousandSeparator
                    />
                  </div>
                </div>
                <div>
                  <div className="tablaTotales-title">Pagos:</div>
                  <div className="tablaTotales-value">
                    <NumberFormat
                      value={CalcularPedidoTotalPagado(pedidoCompleto)}
                      displayType="text"
                      decimalScale="2"
                      fixedDecimalScale="2"
                      thousandSeparator
                    />
                  </div>
                </div>
                <div>
                  <div className="tablaTotales-title">Saldo:</div>
                  <div className="tablaTotales-value">
                    <NumberFormat
                      value={CalcularSaldoPedido(pedidoCompleto)}
                      displayType="text"
                      decimalScale="2"
                      fixedDecimalScale="2"
                      thousandSeparator
                    />
                  </div>
                </div>
              </div>

              <div style={{ textAlign: "center" }}>
                <strong>Pagos</strong>
              </div>

              <div className="tablaTotales">
                {pedidoCompleto.pagos.map((pago) => (
                  <div key={pago.formaPago.nombre}>
                    <div className="tablaTotales-title">
                      {pago.formaPago.nombre}{" "}
                    </div>
                    <div className="tablaTotales-value">
                      <NumberFormat
                        value={pago.monto}
                        displayType="text"
                        decimalScale="2"
                        fixedDecimalScale="2"
                        thousandSeparator
                      />
                    </div>
                  </div>
                ))}
                <div>
                  <div className="tablaTotales-title">Propinas tarjetas:</div>
                  <div className="tablaTotales-value">
                    <NumberFormat
                      value={pedidoCompleto.propinaTarjeta}
                      displayType="text"
                      decimalScale="2"
                      fixedDecimalScale="2"
                      thousandSeparator
                    />
                  </div>
                </div>
              </div>
            </Card.Body>
          </Card>
        </div>
      </Modal.Body>
    </Modal>
  );
};

export default VerPedidoRuta;

import React from "react";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import Slide from "@material-ui/core/Slide";
import { TextField, DialogActions } from "@material-ui/core";
import { validateEmail } from "../utils/validationHelpers";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="down" ref={ref} {...props} />;
});

const FueraDelAreaDialog = (props) => {
  const [nombre, setNombre] = React.useState("");
  const [nombreValidationMessage, setNombreValidationMessage] = React.useState(
    ""
  );
  const [email, setEmail] = React.useState("");
  const [emailValidationMessage, setEmailValidationMessage] = React.useState(
    ""
  );

  if (!props.open) return "";

  const handleNombreChanged = (event) => {
    setNombre(event.target.value);
    let validacion = validar();
    if (validacion !== null && validacion.campo === "nombre")
      setNombreValidationMessage(validacion.mensaje);
    else if (nombreValidationMessage !== "") setNombreValidationMessage("");
  };
  const handleEmailChanged = (event) => {
    setEmail(event.target.value);
    let validacion = validar();
    if (validacion === null) setEmailValidationMessage("");
    else if (validacion !== null && validacion.campo === "email")
      setEmailValidationMessage(validacion.mensaje);
    else if (emailValidationMessage !== "") setEmailValidationMessage("");
  };

  const handleNotificarme = () => {
    let validacion = validar();
    if (validacion !== null) {
      if (validacion.campo === "nombre")
        setNombreValidationMessage(validacion.mensaje);
      else if (nombreValidationMessage !== "") setNombreValidationMessage("");

      if (validacion.campo === "email")
        setEmailValidationMessage(validacion.mensaje);
      else if (emailValidationMessage !== "") setEmailValidationMessage("");

      return;
    }

    //TODO: Guarda props.codigoPostal, nombre, email

    props.onClose();
  };

  const validar = () => {
    if (nombre === "") return { campo: "nombre", mensaje: "Escribe tu nombre" };

    if (nombre.length < 10)
      return { campo: "nombre", mensaje: "Nombre inválido" };

    if (email === "")
      return { campo: "email", mensaje: "Escribe tu dirección de correo" };

    if (validateEmail(email) === false)
      return { campo: "email", mensaje: "Dirección de correo inválida" };

    return null;
  };

  return (
    <div id="dentroDelAreaDialog">
      <Dialog
        open={props.open}
        onClose={props.onClose}
        onButtonClick={props.onClose}
        TransitionComponent={Transition}
      >
        <DialogContent>
          <h3>Lo sentimos, pero no estás en nuestra área de cobertura</h3>
          <h4>
            Continuamente estamos creciendo nuestra área de servicio, por favor
            deja tu nombre y correo y te avisaremos en cuanto tengamos cobertura
            en tu zona.
          </h4>
          <div className="div75">
            <TextField
              label="Nombre"
              onChange={handleNombreChanged}
              value={nombre}
              helperText={nombreValidationMessage}
              className="div100"
            />
            <TextField
              label="eMail"
              type="email"
              onChange={handleEmailChanged}
              value={email}
              helperText={emailValidationMessage}
              className="div100"
            />
          </div>
        </DialogContent>
        <DialogActions>
          <button
            className="areas"
            style={{ display: "block" }}
            onClick={handleNotificarme}
            disabled={validar() != null}
          >
            Notificarme
          </button>

          <button
            className="botonCerrar material-icons"
            onClick={props.onClose}
          >
            clear
          </button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default FueraDelAreaDialog;

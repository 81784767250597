import React, { useState } from "react";
import { XCircleFill } from "react-bootstrap-icons";

import "./commonControls.css";

const SearchInput = ({ value, placeholder, onChange }) => {
  return (
    <div className="commonInput__wrapper">
      <input
        className="commonInput__input searchInput__input"
        type="text"
        placeholder={!placeholder ? "Buscar" : placeholder}
        value={value}
        onChange={(e) => {
          onChange(e.currentTarget.value);
        }}
      />
      <button
        className="searchInput__button"
        disabled={!value}
        onClick={() => onChange("")}
      >
        <XCircleFill className="searchInput__buttonIcon" />
      </button>
    </div>
  );
};

export default SearchInput;

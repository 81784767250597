import React, { useState, useEffect } from "react";
import { LlenarProductos } from "../../services/backofficeService";
import { Modal } from "react-bootstrap";
import SearchInput from "../../common/utils/searchInput";

import "./lookupProducto.css";

const LookupProducto = ({ show, onHide, onProductoSeleccionado }) => {
  const [productos, setProductos] = useState([]);
  const [showProductos, setShowProductos] = useState([]);
  const [searchText, setSearchText] = useState("");
  useEffect(() => {
    cargarProductos();
  }, []);

  const cargarProductos = async () => {
    try {
      let r = await LlenarProductos();
      let f = r.filter((x) => x.activo);
      setProductos(f);
      setShowProductos(f);
    } catch (error) {
      let message;
      if (error.response && error.response.data) {
        message = error.response.data;
        console.log("Error al cargar los productos", error.response);
      } else {
        message = error;
        console.log("Error al cargar los productos", error);
      }

      alert(message);
    }
  };

  const search = (text) => {
    setSearchText(text);
    let textLower = text.toLowerCase();
    let f = productos.filter((x) =>
      x.descripcion.toLowerCase().includes(textLower)
    );
    setShowProductos(f);
  };

  const handleOnHide = () => {
    productos.forEach((p) => (p.selected = false));

    if (onHide) onHide();
  };

  return (
    <Modal show={show} onHide={handleOnHide}>
      <Modal.Header closeButton>
        <div className="lookupProducto-header">
          <h2>Seleccionar producto</h2>
          <SearchInput
            className="lookupProducto-header__searchInput"
            value={searchText}
            onChange={(text) => search(text)}
          />
        </div>
      </Modal.Header>
      <Modal.Body>
        {showProductos.map((p) => (
          <div
            key={p.id}
            className={
              "lookupProducto__list-item " + (p.selected ? "item-selected" : "")
            }
            onClick={() => {
              if (p.selected) p.selected = false;
              else {
                p.selected = true;
                onProductoSeleccionado(p);
              }
              setShowProductos([...showProductos]);
            }}
          >
            {p.descripcion}
          </div>
        ))}
      </Modal.Body>
    </Modal>
  );
};

export default LookupProducto;

import React, { Component } from "react";
import { CargarPedido, CerrarPedido } from "../../../services/pedidoService";
import moment from "moment";
import "moment/locale/es";
import { AsYouType } from "libphonenumber-js";
import { validatePhone } from "../../../common/utils/validationHelpers";
import CodigosPostalesCombo from "../../../common/codigosPostales/codigosPostalesCombo";
import { Link } from "react-router-dom";
import Input from "../../../common/utils/input";
import Joi from "joi-browser";

import BuscarCodigoPostalDialog from "../../../common/codigosPostales/buscarCodigoPostalDialog";
import DialogBox from "../../../common/dialogBox";

class PedidoDomicilioPage extends Component {
  state = {
    pedido: null,
    errors: {},
    mostrarBuscarCodigoPostalDialog: false,
    dialogShow: false,
    dialogTitle: "",
    dialogContent: "",
    dialogShouldClose: false,
  };

  async componentDidMount() {
    try {
      const pedido = await CargarPedido();
      if (pedido.partidas == null || pedido.partidas.length === 0) {
        alert("No se ha agregado ningún producto a su pedido");
        this.props.history.push("/");
        return;
      }

      this.setState({ pedido });
    } catch (error) {
      //TODO: Log del error
      //TODO: Cambiar alert x dialog
      alert("No fue posible cargar su pedido. Por favor intente nuevamente.");
      this.props.history.push("/");
      return;
    }
  }

  handleChange = ({ currentTarget: input }) => {
    const errors = { ...this.state.errors };
    const errorMessage = this.validateProperty(input);
    if (errorMessage) errors[input.name] = errorMessage;
    else delete errors[input.name];

    const pedido = { ...this.state.pedido };
    if (input.name === "telefono") {
      if (input.value.length > 12) return;

      pedido.telefono = new AsYouType("MX").input(input.value);
    } else pedido[input.name] = input.value;

    this.setState({ pedido, errors });
  };

  handleCodigoPostalChanged = async (e) => {
    const codigoPostal = e.currentTarget.value;
    if (codigoPostal && !Number(codigoPostal)) return;
    if (codigoPostal.length > 5) return;
    this.handleChange(e);
  };

  handleCodigoPostalError = (message) => {
    const errors = { ...this.state.errors };
    errors.codigoPostal = message;
    this.setState({ errors });
  };

  schema = {
    nombre: Joi.string()
      .required()
      .min(10)
      .max(100)
      .error((err) => {
        const newError = { ...err[0] };
        switch (newError.type) {
          case "string.base":
          case "any.empty":
          case "string.empty":
            newError.message = "Escribe tu nombre";
            break;
          default:
            newError.message = "Nombre inválido";
            break;
        }
        return newError;
      }),
    eMail: Joi.string()
      .required()
      .email({ minDomainAtoms: 2 })
      .error((err) => {
        const newError = { ...err[0] };
        switch (newError.type) {
          case "string.base":
          case "any.empty":
          case "string.empty":
            newError.message = "Escribe tu correo electrónico";
            break;
          default:
            newError.message = "Correo inválido";
            break;
        }
        return newError;
      }),
    telefono: Joi.string()
      .required()
      .min(10)
      .max(12)
      .error((err) => {
        const newError = { ...err[0] };
        switch (newError.type) {
          case "string.base":
          case "any.empty":
          case "string.empty":
            newError.message = "Escribe tu número telefónico";
            break;
          case "string.min":
          case "string.max":
            newError.message = "Escribe los 10 dígitos de tu número telefónico";
            break;
          default:
            newError.message = "Número de teléfono inválido";
            break;
        }
        return newError;
      }),
    calle: Joi.string()
      .required()
      .min(5)
      .error((err) => {
        const newError = { ...err[0] };
        newError.message = "Escribe el nombre de tu calle";
        return newError;
      }),
    noExterior: Joi.string()
      .required()
      .error((err) => {
        const newError = { ...err[0] };
        newError.message = "Escribe el número exterior de tu domicilio";
        return newError;
      }),
    codigoPostal: Joi.string()
      .required()
      .trim()
      .regex(/^(?:0[1-9]|[1-4]\d|5[0-2])\d{3}$/)
      .error((err) => {
        const newError = { ...err[0] };
        newError.message = "Escribe un código postal válido";
        return newError;
      }),
    colonia: Joi.string()
      .required()
      .error((err) => {
        const newError = { ...err[0] };
        newError.message = "Selecciona la colonia de tu domicilio";
        return newError;
      }),
  };

  validate = () => {
    const pedido = this.state.pedido;
    const pedidoValidar = {};
    for (let item of Object.keys(this.schema))
      pedidoValidar[item] = pedido[item];

    const result = Joi.validate(pedidoValidar, this.schema, {
      abortEarly: false,
    });

    if (!result.error) {
      const errorTelefono = validatePhone(pedido.telefono);
      return errorTelefono;
    }

    const errors = {};
    for (let item of result.error.details) errors[item.path[0]] = item.message;
    return errors;
  };

  validateProperty = ({ name, value }) => {
    if (!this.schema[name]) return null;

    if (name === "telefono") {
      const errorTelefono = validatePhone(value);
      if (errorTelefono) return errorTelefono;
    }

    const obj = { [name]: value };
    const schema = { [name]: this.schema[name] };
    const { error } = Joi.validate(obj, schema);
    return error ? error.details[0].message : null;
  };

  handleSubmit = async (e) => {
    e.preventDefault();

    const errors = this.validate();
    this.setState({ errors });
    if (errors !== null) return;

    const pedido = this.state.pedido;

    // Guarda los datos en el navegador
    localStorage.datosPedidoNombre = pedido.nombre;
    localStorage.datosPedidoEmail = pedido.email;

    console.log("pedido", pedido.nombre);
    console.log("local", localStorage.datosPedidoNombre);

    return;

    // Cierra el pedido
    const response = await CerrarPedido(pedido);
    if (response.error) {
      this.setState({
        dialogShow: true,
        dialogTitle: "¡ Error !",
        dialogShouldClose: false,
        dialogContent: (
          <span>
            <b>NO</b> se ha podido guardar el pedido
            <br />
            <b>{response.error}</b>
            <br />
            {!response.errorValidacion && <span>Intente nuevamente.</span>}
          </span>
        ),
      });
    } else {
      this.setState({
        dialogShow: true,
        dialogTitle: "Se ha creado el pedido",
        dialogShouldClose: true,
        dialogContent: (
          <span>
            Se ha creado el pedido <b>{response.folioPedido}</b>
            <br />
            Se entregará el día{" "}
            <b>
              {moment(response.fechaEntrega)
                .locale("es")
                .format("dddd, D [de] MMMM [de] YYYY")}
            </b>
            <br />
            Hemos enviado a tu correo una copia del pedido.
            <br />
          </span>
        ),
      });
    }
  };

  onDialogClick = () => {
    this.setState({ dialogShow: false });
    if (this.state.dialogShouldClose) this.props.history.push("/");
  };

  render() {
    if (this.state.pedido == null) return "";
    const pedido = this.state.pedido;
    let errors = this.state.errors;
    if (errors == null) errors = {};

    return (
      <div>
        <Link to="/">
          <button>Seguir comprando</button>
        </Link>

        <form onSubmit={this.handleSubmit}>
          <label>
            Fecha de entrega:
            <strong>
              {moment()
                .locale("es")
                .format(
                  "dddd, D [de] MMMM [de] YYYY",
                  pedido.fechaEstimadaEntrega
                )}
            </strong>
          </label>
          <div id="generales">
            <h3>Generales</h3>
            <Input
              name="nombre"
              label="Nombre"
              value={pedido.nombre}
              onChange={this.handleChange}
              error={errors.nombre}
            />
            <Input
              name="eMail"
              label="eMail"
              value={pedido.eMail}
              type="email"
              onChange={this.handleChange}
              error={errors.eMail}
            />
            <Input
              label="Celular"
              value={pedido.telefono}
              type="tel"
              name="telefono"
              onChange={this.handleChange}
              error={errors.telefono}
            />
          </div>
          <div id="domicilio">
            <h3>Domicilio de entrega</h3>
            <Input
              label="Calle"
              value={pedido.calle}
              type="text"
              name="calle"
              onChange={this.handleChange}
              error={errors.calle}
            />
            <Input
              label="No. Exterior"
              value={pedido.noExterior}
              name="noExterior"
              onChange={this.handleChange}
              error={errors.noExterior}
            />
            <Input
              label="No. Interior"
              value={pedido.noInterior}
              name="noInterior"
              onChange={this.handleChange}
              error={errors.noInterior}
            />
            <Input
              label="Código postal"
              value={pedido.codigoPostal}
              name="codigoPostal"
              onChange={this.handleCodigoPostalChanged}
              error={errors.codigoPostal}
            />
            <button
              onClick={(e) => {
                e.preventDefault();
                const mostrar = !this.state.mostrarBuscarCodigoPostalDialog;
                this.setState({
                  mostrarBuscarCodigoPostalDialog: mostrar,
                });
              }}
            >
              Buscar código postal
            </button>
            <BuscarCodigoPostalDialog
              mostrar={this.state.mostrarBuscarCodigoPostalDialog}
              onClose={(e) => {
                this.setState({ mostrarBuscarCodigoPostalDialog: false });
              }}
            />
            <span>Municipio: {pedido.municipio}</span>
            <label htmlFor="colonia">Colonia:</label>
            <CodigosPostalesCombo
              name="colonia"
              codigoPostal={pedido.codigoPostal}
              colonia={pedido.colonia}
              errors={errors.colonia}
              onChange={this.handleChange}
              onError={this.handleCodigoPostalError}
            />

            <Input
              label="Entre calle"
              value={pedido.entreCalle1}
              name="entreCalle1"
              onChange={this.handleChange}
              errors={errors.entreCalle1}
            />
            <Input
              label="y calle"
              value={pedido.entreCalle2}
              name="entreCalle2"
              onChange={this.handleChange}
              errors={errors.entreCalle2}
            />
            <Input
              label="Referencias"
              value={pedido.referencias}
              name="referencias"
              onChange={this.handleChange}
              errors={errors.referencias}
            />
            <Input
              label="notas"
              value={pedido.notas}
              multiline
              rows={3}
              name="notas"
              onChange={this.handleChange}
              errors={errors.notas}
            />
          </div>

          <div id="botones">
            <input
              type="submit"
              value="Terminar"
              //disabled={this.validate()}
            />
          </div>
        </form>

        <DialogBox
          open={this.state.dialogShow}
          onClose={() => this.onDialogClick()}
          title={this.state.dialogTitle}
          buttonText="Aceptar"
          onButtonClick={() => this.onDialogClick()}
        >
          <div>{this.state.dialogContent}</div>
        </DialogBox>
      </div>
    );
  }
}
export default PedidoDomicilioPage;

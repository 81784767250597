import React, { Component } from "react";
import { Route, Switch, BrowserRouter } from "react-router-dom";
import PreciosPage from "./productos/preciosPage";
import BackofficeNavBar from "./backofficeNavBar";
import Productos from "./productos/productos";
import PedidosPorEntregarPage from "./pedidos/pedidosPorEntregarPage";

import { MuiPickersUtilsProvider } from "@material-ui/pickers";
import MomentUtils from "@date-io/moment";
import PedidoPorEntregarPage from "./pedidos/pedidoPorEntregarPage";
import ProveedoresPage from "./proveedores/proveedoresPage";
import UsuariosPage from "./usuarios/usuariosPage";
import PerfilesUsuariosPage from "./usuarios/perfilesUsuariosPage";
import ListaComprasPage from "./compras/comprasPage";
import AnalisisComprasPage from "./compras/analisisComprasPage";
import Rutas from "./pedidos/rutas/rutas";
import MisRutas from "./pedidos/rutas/misRutas/misRutas";
import InventarioFisico from "./inventario/inventariofisico/inventariofisico";
import InventariosFisicos from "./inventario/inventariofisico/inventariosfisicos";
import Existencias from "./inventario/existencias/existencias";
import RecepcionCompra from "./compras/recepcionCompra/recepcionCompra";

class BackofficePage extends Component {
  state = {};

  render() {
    return (
      <MuiPickersUtilsProvider utils={MomentUtils}>
        <div className="App">
          <BrowserRouter>
            <BackofficeNavBar sessionId={this.props.sessionId} />
            <Switch>
              <Route path="/backoffice/precios">
                <PreciosPage sessionId={this.props.sessionId} />
              </Route>
              <Route path="/backoffice/productos">
                <Productos sessionId={this.props.sessionId} />
              </Route>
              <Route path="/backoffice/proveedores">
                <ProveedoresPage sessionId={this.props.sessionId} />
              </Route>
              <Route path="/backoffice/pedidos/porEntregar">
                <PedidosPorEntregarPage sessionId={this.props.sessionId} />
              </Route>
              <Route path="/backoffice/pedidos/pedidoPorEntregar/:id">
                <PedidoPorEntregarPage sessionId={this.props.sessionId} />
              </Route>
              <Route path="/backoffice/pedidos/rutas/misrutas">
                <MisRutas sessionId={this.props.sessionId} />
              </Route>
              <Route path="/backoffice/pedidos/rutas">
                <Rutas sessionId={this.props.sessionId} />
              </Route>
              <Route path="/backoffice/usuarios">
                <UsuariosPage sessionId={this.props.sessionId} />
              </Route>
              <Route path="/backoffice/perfilesUsuarios">
                <PerfilesUsuariosPage sessionId={this.props.sessionId} />
              </Route>
              <Route
                path="/backoffice/compras/shoppinglist"
                component={({ ...others }) => (
                  <ListaComprasPage
                    sessionId={this.props.sessionId}
                    {...others}
                  />
                )}
              ></Route>
              <Route path="/backoffice/compras/analisiscompras">
                <AnalisisComprasPage sessionId={this.props.sessionId} />
              </Route>
              <Route path="/backoffice/compras/recepcioncompras">
                <RecepcionCompra sessionId={this.props.sessionId} />
              </Route>
              <Route path="/backoffice/inventario/inventariosfisicos">
                <InventariosFisicos sessionId={this.props.sessionId} />
              </Route>
              <Route
                path="/backoffice/inventario/inventariofisico/:id"
                children={<InventarioFisico sessionId={this.props.sessionId} />}
              ></Route>
              <Route path="/backoffice/inventario/inventariofisico">
                <InventarioFisico sessionId={this.props.sessionId} />
              </Route>
              <Route path="/backoffice/inventario/existencias">
                <Existencias />
              </Route>
            </Switch>
          </BrowserRouter>
        </div>
      </MuiPickersUtilsProvider>
    );
  }
}

export default BackofficePage;

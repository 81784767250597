import React, { useState, useEffect } from "react";
import {
  ListaPerfilesUsuario,
  TienePermiso,
} from "../../services/backofficeService";
import { Backdrop, CircularProgress } from "@material-ui/core";

import BackofficeBanner from "../backofficeBanner";
import UpdatePerfil from "./updatePerfil";

const PerfilesUsuariosPage = ({ sessionId }) => {
  const [showBackdrop, setShowBackdrop] = useState(true);
  const [perfiles, setPerfiles] = useState([]);
  const [perfil, setPerfil] = useState({});
  const [editAction, setEditAction] = useState();

  useEffect(() => {
    cargarPerfiles();
  }, []);

  const cargarPerfiles = async () => {
    let respuesta = await TienePermiso(
      sessionId,
      "Catálogos/Usuarios/Perfiles"
    );
    if (respuesta !== "") {
      alert(respuesta);
      window.location.href = "/backoffice";
      return;
    }

    let listaPerfiles = await ListaPerfilesUsuario();
    listaPerfiles.sort((a, b) => (a.nombre > b.nombre ? 1 : -1));
    setPerfiles(listaPerfiles);
    setShowBackdrop(false);
  };

  if (!perfiles)
    return (
      <div>
        <Backdrop open={true}>
          <CircularProgress color="inherit" />
        </Backdrop>
      </div>
    );

  return (
    <div className="App">
      <BackofficeBanner
        title="Catálogo de perfiles"
        showProgress={showBackdrop}
      />
      <div className="container">
        <table className="table">
          <thead className="thead-dark">
            <tr>
              <th>Perfil</th>
              <th>
                <button
                  className="btn btn-success"
                  onClick={() => {
                    setPerfil({ id: 0, nombre: "" });
                    setEditAction("add");
                  }}
                >
                  Agregar
                </button>
              </th>
            </tr>
          </thead>
          <tbody>
            {perfiles.map((perfil) => (
              <tr key={perfil.id}>
                <td className="text-left">{perfil.nombre}</td>
                <td>
                  <button
                    className="btn btn-primary"
                    onClick={() => {
                      setPerfil(perfil);
                      setEditAction("edit");
                    }}
                  >
                    Editar
                  </button>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>

      {editAction && (
        <UpdatePerfil
          perfil={perfil}
          editAction={editAction}
          onChange={() => setPerfil({ ...perfil })}
          onHide={() => setEditAction()}
          onSave={async () => {
            const perfs = [...perfiles];
            const perfIndex = perfs.findIndex((p) => p.id === perfil.id);
            if (perfIndex === -1) perfs.push(perfil);
            else perfs[perfIndex] = perfil;
            perfs.sort((a, b) => (a.nombre > b.nombre ? 1 : -1));
            setPerfiles(perfs);
            setEditAction();
            setPerfil();
          }}
        />
      )}
    </div>
  );
};

export default PerfilesUsuariosPage;

import React, { Component } from "react";
import ProductosPageBanner from "./productosPageBanner";
import queryString from "query-string";
import { CategoriaProducto } from "../../services/categoriasService";
import {
  ProductosBuscar,
  ProductosPorCategoria,
} from "../../services/productosService";
import ProductosPageList from "./productosPageList";
import { ContadorItems } from "../../services/pedidoService";
import { RegistrarEvento } from "../../services/eventosServices";

//function para el lazy

/* function isInViewport(element) {
  var rect = element.getBoundingClientRect();
  var html = document.documentElement;
  var posas = "";
  if (html.clientHeight > rect.top / 3) {
    posas = 1;
  }
  return posas;
}
 */
//function para mostrar las imagenes
function muestraImgs() {
  const sections = document.querySelectorAll(".productosImgAparece");

  for (const section of sections) {
    var src = section.getAttribute("data-src");

    if (section.getBoundingClientRect().top <= window.innerHeight * 1.1) {
      section.src = src;
      section.classList.remove("productosImgAparece");
    }
  }
}

//function que añade los clears cada 5 4  3 y 2 y colorea el menu
function clearsProductos(cat) {
  document.querySelectorAll(".productosClear").forEach(function (a) {
    a.remove();
  });

  //pintamos la categoria
  var catPuesta = document.getElementById(
    "landingPageCategoriesListItem_" + cat
  );
  if (catPuesta !== null) {
    document.querySelectorAll(".menuElementoP").forEach(function (a) {
      a.classList.remove("menuElementoP");
    });

    catPuesta.classList.add("menuElementoP");
  }
  //

  var productines = document.getElementsByClassName("productos");
  var cuentaProds = 1;
  for (var i = 0; i < productines.length; ++i) {
    var item = productines[i];

    if (cuentaProds % 2 === 0) {
      item.insertAdjacentHTML(
        "afterend",
        '<div class=" productosClear productosClear2"></div>'
      );
    }

    if (cuentaProds % 3 === 0) {
      item.insertAdjacentHTML(
        "afterend",
        '<div class=" productosClear productosClear3"></div>'
      );
    }

    if (cuentaProds % 5 === 0) {
      item.insertAdjacentHTML(
        "afterend",
        '<div class=" productosClear productosClear5"></div>'
      );
    }
    cuentaProds = cuentaProds + 1;
  }
}

window.addEventListener("scroll", function () {
  muestraImgs();
});

class ProductosPage extends Component {
  state = { buscar: "", categoria: {}, productos: [], pedidoContadorItems: 0 };
  ultimosParametrosCargados = null;

  async componentDidMount() {
    await this.cargarProductos(this.props);

    muestraImgs();
    clearsProductos();

    if (this.state.pedidoContadorItems === 0 && this.props.onCantidadChanged) {
      try {
        const contadorItems = await ContadorItems();
        this.setState({ pedidoContadorItems: contadorItems });
        this.props.onCantidadChanged(0, contadorItems);
      } catch (error) {
        //TODO: Log
        console.log(error);
      }
    }
  }

  handleSearch = (textoBuscar) => {
    this.setState({ buscar: textoBuscar, categoria: {} });
  };

  // Esta aqui para responder a cambios en el url y solo cuando realmente sea necesario, no en cada render
  //TODO: Cambiar esta llamada https://reactjs.org/blog/2018/06/07/you-probably-dont-need-derived-state.html#preferred-solutions
  async componentWillReceiveProps(nextProps) {
    await this.cargarProductos(nextProps);
  }

  async cargarProductos(props) {
    const parametros = queryString.parse(props.location.search);

    // Si no cambiaron los parámetros de búsqueda/categoría, no necesita recargar
    if (this.ultimosParametrosCargados !== null) {
      if (
        parametros.buscar &&
        this.ultimosParametrosCargados.buscar &&
        parametros.buscar === this.ultimosParametrosCargados.buscar
      )
        return;
      else if (
        parametros.categoria &&
        this.ultimosParametrosCargados.categoria &&
        parametros.categoria === this.ultimosParametrosCargados.categoria
      )
        return;
    }
    this.ultimosParametrosCargados = parametros;

    try {
      if (parametros.buscar) {
        const prods = await ProductosBuscar(parametros.buscar);
        this.setState({
          buscar: parametros.buscar,
          categoria: {},
          productos: prods,
        });
        muestraImgs();
        clearsProductos();
        window.scrollTo(0, 0);

        RegistrarEvento(4, `${parametros.buscar},${prods.length}`, {
          busqueda: parametros.buscar,
          resultCount: prods.length,
        });
        return;
      }
      this.setState({ buscar: "" });

      if (parametros.categoria) {
        const categoria = await CategoriaProducto(parametros.categoria);
        const productos = await ProductosPorCategoria(categoria.id);
        this.setState({ productos: productos, categoria: categoria });
        muestraImgs();
        clearsProductos(categoria.id);
        window.scrollTo(0, 0);

        return;
      } else this.setState({ categoria: {} });
    } catch (e) {
      //TODO: Mostrar mensaje de error
      //TODO: Log
      console.log(e.response);
    }
  }

  handleCantidadChanged = (nuevaCantidad, totalItemsDelPedido) => {
    if (this.props.onCantidadChanged)
      this.props.onCantidadChanged(nuevaCantidad, totalItemsDelPedido);
    this.setState({ pedidoContadorItems: totalItemsDelPedido });
  };

  render() {
    return (
      <div>
        <ProductosPageBanner categoria={this.state.categoria} />

        {this.state.buscar && this.state.productos.length !== 0 && (
          <h3>
            Se encontraron {this.state.productos.length} productos para "
            {this.state.buscar}"
          </h3>
        )}

        <div className="cont">
          <div className="contF">
            <ProductosPageList
              productos={this.state.productos}
              onCantidadChanged={this.handleCantidadChanged}
            />

            {this.state.buscar && this.state.productos.length === 0 && (
              <h3>
                No se encontraron productos coincidentes para "
                {this.state.buscar}"
              </h3>
            )}
          </div>
        </div>
      </div>
    );
  }
}

export default ProductosPage;

import React from "react";

const MiRutaPedidoDomicilio = ({ pedido, mostrarTelefono }) => {
  return (
    <div id="miRuta-Pedido-Domicilio">
      {mostrarTelefono && (
        <p>
          <a href={"tel:" + pedido.telefono}>{pedido.telefono}</a>
        </p>
      )}
      <p>
        {pedido.calle} {pedido.noExterior}
        {pedido.noInterior && " Int. " + pedido.noInterior}
      </p>
      <p>
        Entre: {pedido.entreCalle1} y {pedido.entreCalle2}
      </p>
      <p>
        Colonia: {pedido.colonia}, {pedido.municipio}
      </p>
      {pedido.notas != "" && (
        <p>
          <strong>{pedido.notas}</strong>
        </p>
      )}
    </div>
  );
};

export default MiRutaPedidoDomicilio;

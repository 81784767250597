import React from "react";

import AreaEntregaDialog from "./areaEntregaDialog";

export default function AreaEntregaButton() {
  const [showDialog, setShowDialog] = React.useState(false);
  const handleClickOpen = () => {
    setShowDialog(true);
  };
  const handleCloseDialog = () => {
    setShowDialog(false);
  };
  return (
    <div className="left">
      <button
        className="areas"
        id="areaEntregaButton"
        onClick={handleClickOpen}
      >
        Ver áreas de entrega
      </button>
      <AreaEntregaDialog open={showDialog} onClose={handleCloseDialog} />
    </div>
  );
}

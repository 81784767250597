import React, { Component } from "react";
import { withRouter } from "react-router-dom";

class SearchBar extends Component {
  state = { texto: "" };

  constructor(props) {
    super(props);
    this.timeout = 0;
  }

  handleKeyDown = (e) => {
    if (e.key === "Enter") this.doSearch();
  };

  handleChange = (e) => {
    this.setState({ texto: e.target.value });

    // Inicializa timeout para busqueda automática después de 1000ms
    if (this.timeout) clearTimeout(this.timeout);
    if (e.target.value !== "")
      this.timeout = setTimeout(() => {
        this.doSearch();
      }, 1000);
  };

  doSearch = () => {
    let { texto } = this.state;
    if (texto !== "") {
      let textoBuscar = texto;
      this.setState({ texto: "" });
      this.props.history.push("/productos?buscar=" + textoBuscar);
      if (this.props.onSearch) this.props.onSearch(textoBuscar);
    }
  };

  render() {
    return (
      <div>
        <input className="inputBuscar"
          type="text"
          placeholder="Buscar productos"
          value={this.state.texto}
          onChange={this.handleChange}
          onKeyDown={this.handleKeyDown}
        ></input>
      </div>
    );
  }
}

export default withRouter(SearchBar);

import webAPIHelper from "../common/utils/webAPIHelper";

const controller = "/CategoriasProductos";

export default async function CategoriasProductos() {
  const appsvc = new webAPIHelper();
  return await appsvc.Get(controller);
}

export async function CategoriaProducto(nombreCategoria) {
  const appsvc = new webAPIHelper();
  return await appsvc.Get(`${controller}/${nombreCategoria}`);
}

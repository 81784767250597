import React, { Component } from "react";
import "./App_v1.css";
import { Route, Switch, BrowserRouter } from "react-router-dom";
import TiendaNav from "./components/tienda/tiendaNav";
import BackofficePage from "./components/backoffice/backofficePage";
import SecureArea from "./components/common/accessControl/secureArea";

class App extends Component {
  state = { pedidoContadorItems: 0 };
  render() {
    return (
      <div className="App">
        <BrowserRouter>
          <Switch>
            <Route
              path="/backoffice"
              component={({ ...others }) => (
                <SecureArea>
                  <BackofficePage {...others} />
                </SecureArea>
              )}
            />
            <Route path="/" component={() => <TiendaNav />} />
          </Switch>
        </BrowserRouter>
      </div>
    );
  }
}

export default App;

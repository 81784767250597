import React, { Component } from "react";
import { Link } from "react-router-dom";

import {
  CargarPedido,
  CambiarNotasPartida,
} from "../../../services/pedidoService";

import CheckoutItemsList from "./checkoutItemsList";
import CheckoutSummary from "./checkoutSummary";
import CheckoutForm from "./checkoutForm";

import Aviso from "../../aviso";
import AvisoDiaInhabil from "../../avisoDiaInhabil";

class CheckoutPage extends Component {
  state = { pedido: {} };

  async componentDidMount() {
    try {
      const pedido = await CargarPedido();
      this.setState({ pedido });

      this.props.onCantidadChanged(0, pedido.partidas.length);
    } catch (error) {
      //TODO: Log
      console.log(error);
    }

    let hoy = new Date();
    if (hoy > new Date(2021, 8, 14) && hoy < new Date(2021, 8, 17))
      this.setState({ showAviso: true });
  }

  async handleCantidadChanged(nuevaCantidad, totalItemsDelPedido, idProducto) {
    try {
      const pedido = await CargarPedido();
      this.setState({ pedido });
      if (this.props.onCantidadChanged)
        this.props.onCantidadChanged(nuevaCantidad, totalItemsDelPedido);
    } catch (error) {
      //TODO: Log
      console.log(error);
    }
  }

  //TODO: Guardar las notas
  async handleNotasChanged(partidaId, notas) {
    CambiarNotasPartida(partidaId, notas);
  }

  render() {
    const { pedido } = this.state;
    if (!pedido)
      return (
        <div className="div100" id="CheckoutPageEmpty">
          <div style={{ clear: "both", height: "120px" }}></div>
          <div className="titulos30 padd20   negrita">
            No has agregado productos a tu carrito
          </div>
          <Link to="/">
            <button className="seguir">Seleccionar productos</button>
          </Link>
        </div>
      );
    return (
      <div className="div100" id="CheckoutPage">
        <AvisoDiaInhabil />
        <Aviso
          show={this.state.showAviso}
          onHide={() => this.setState({ showAviso: false })}
        />
        <div style={{ clear: "both", height: "120px" }}></div>
        <div className="cont">
          <div className="contF">
            <div className="div50 padd10" id="checkoutPageItemList">
              <div className="div100 blanco10">
                <div className="titulos30 padd20 verde negrita">Mi pedido</div>
                <CheckoutItemsList
                  pedido={pedido}
                  onCantidadChanged={(
                    nuevaCantidad,
                    totalItemsDelPedido,
                    idProducto
                  ) =>
                    this.handleCantidadChanged(
                      nuevaCantidad,
                      totalItemsDelPedido,
                      idProducto
                    )
                  }
                  onNotasChanged={(partidaId, notas) =>
                    this.handleNotasChanged(partidaId, notas)
                  }
                />
              </div>
            </div>
            <div className="div50 padd10" id="checkoutPageSummary">
              <div className="div100 blanco10">
                <CheckoutSummary pedido={pedido} />
                <CheckoutForm
                  pedido={pedido}
                  onPedidoChanged={(pedido) => this.state({ pedido: pedido })}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default CheckoutPage;

import React, { useState, useEffect } from "react";
import Aviso from "../aviso";
const ProductosPageBanner = ({ categoria }) => {
  const [showAviso, setShowAviso] = useState(false);
  const [imgBannerUrl, setImgBannerUrl] = useState("");

  useEffect(() => {
    let imgUrl = process.env.REACT_APP_PRODUCTOSPAGE_BANNER_URL;
    if (categoria) {
      imgUrl = categoria.bannerUrl;
      //setShowAviso(categoria.id === 6 && Date.now() < new Date(2021, 4, 3));
    }
    setImgBannerUrl(imgUrl);
  }, [categoria]);

  return (
    <div>
      <div className="div100">
        <div
          className="bannerProductos"
          style={{ backgroundImage: "url(" + imgBannerUrl + ")" }}
        >
          <div className="div100 centrado">
            <div className="titulos50">{categoria.nombre}</div>
          </div>
        </div>
        <div className="clear20"></div>
        <Aviso show={showAviso} onHide={() => setShowAviso(false)} />
      </div>
    </div>
  );
};

export default ProductosPageBanner;

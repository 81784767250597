import React, { useState, useEffect } from "react";
import { Map, GoogleApiWrapper, Marker } from "google-maps-react";

import { GeoCodeFromAddress } from "../../../services/mapsHelper";

const mapStyles = {
  width: "100%",
  height: "100%",
};

const containerStyle = {
  position: "relative",
  width: "100%",
  height: "100%",
};

export const AddressLocationSelector = ({ google, pedido }) => {
  const [currentLocation, setCurrentLocation] = useState();
  const [zoom, setZoom] = useState(14);
  const [markerPosition, setMarkerPosition] = useState();
  const [lastAddressLookup, setLastAddressLookup] = useState();

  useEffect(() => {
    const pedidoCoord = { lat: pedido.latitude, lng: pedido.longitude };
    if (markerPosition != pedidoCoord && pedido.latitude != 0)
      changeMarkerPosition(pedidoCoord);
  }, []);

  function changeMarkerPosition(coords) {
    setCurrentLocation(coords);
    setMarkerPosition(coords);
    setZoom(18);
    pedido.latitude = coords.lat;
    pedido.longitude = coords.lng;
  }

  return (
    <div id="AddressLocationSelector" style={{ height: "300px" }}>
      <div style={{ display: "flex" }}>
        <button
          className="btn btn-sm btn-outline-primary"
          onClick={async (e) => {
            e.preventDefault();

            if (
              !pedido.calle ||
              !pedido.noExterior ||
              !pedido.codigoPostal ||
              !pedido.colonia ||
              !pedido.municipio
            )
              return;

            if (pedido.calle.length < 5) return;

            const lookupAddress =
              pedido.calle +
              " " +
              pedido.noExterior +
              (pedido.noInterior != "" ? "-" + pedido.noInterior : "") +
              "," +
              pedido.colonia +
              "," +
              pedido.codigoPostal +
              "," +
              pedido.municipio +
              ",jalisco, mexico";

            if (lookupAddress == lastAddressLookup) return;
            setLastAddressLookup(lookupAddress);

            try {
              const coords = await GeoCodeFromAddress(lookupAddress);
              changeMarkerPosition(coords);
            } catch (error) {
              alert("No fue posible encontrar el domicilio");
              console.log(error);
            }
          }}
        >
          Buscar mi domicilio
        </button>
        <button
          className="btn btn-sm btn-outline-primary"
          onClick={(e) => {
            e.preventDefault();
            if (navigator && navigator.geolocation) {
              navigator.geolocation.getCurrentPosition((pos) => {
                const coords = {
                  lat: pos.coords.latitude,
                  lng: pos.coords.longitude,
                };
                changeMarkerPosition(coords);
              });
            }
          }}
        >
          Utilizar mi ubicación
        </button>
      </div>

      <Map
        google={google}
        zoom={zoom}
        streetViewControl={false}
        mapTypeControl={false}
        containerStyle={containerStyle}
        style={mapStyles}
        initialCenter={{
          lat: 20.674591349639112,
          lng: -103.38674027858758,
        }}
        center={currentLocation}
        onClick={(p, m, e) => {
          const lat = e.latLng.lat();
          const lng = e.latLng.lng();
          changeMarkerPosition({ lat, lng });
        }}
      >
        <Marker
          position={markerPosition}
          draggable={true}
          onDragend={(e, f, g) => {
            const lat = g.latLng.lat();
            const lng = g.latLng.lng();
            changeMarkerPosition({ lat, lng });
          }}
        />
      </Map>
      <p>Mueve el marcador para indicar la ubicación de tu domicilio</p>
    </div>
  );
};

const googleApiKey = () => {
  return process.env.NODE_ENV === "development"
    ? process.env.REACT_APP_GOOGLE_API_KEY_DEVELOPMENT
    : process.env.REACT_APP_GOOGLE_API_KEY;
};

export default GoogleApiWrapper({
  apiKey: googleApiKey(),
})(AddressLocationSelector);

import React, { useState } from "react";

import { Modal, Card, Button } from "react-bootstrap";
import { Signpost2, ZoomIn, ZoomOut } from "react-bootstrap-icons";

import RutaMapa from "../rutaMapa";
import EntregarPedido from "./entregarPedido";
import MiRutaPedidoDomicilio from "./miRutaPedidoDomicilio";

const MiRuta = ({ ruta, onHide, onRutaChanged }) => {
  const [mapCenter, setMapCenter] = useState(null);
  const [mapZoom, setMapZoom] = useState(null);
  const [pedidoEntregar, setPedidoEntregar] = useState();

  return (
    <Modal show={true} onHide={onHide} id="miRuta-Modal">
      <Modal.Header closeButton>
        <Modal.Title>
          R{ruta.numero} - {ruta.nombre}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div>
          <RutaMapa
            width="100%"
            height="40vh"
            pedidos={ruta.pedidos}
            center={mapCenter}
            zoom={mapZoom}
          />
        </div>
        <hr />
        <div id="miRuta-Pedidos">
          <div className="sectionHeader">
            <h5>Pedidos ({ruta.pedidos.length})</h5>
            <Button
              size="lg"
              onClick={() => {
                setMapCenter(null);
                setMapZoom(null);
              }}
            >
              <ZoomOut />
            </Button>
          </div>
          <div>
            {ruta.pedidos.map((pedido) => (
              <Card
                key={pedido.id}
                id="miRuta-Pedido"
                style={{
                  backgroundColor: pedido.estatus == "4" ? "#d8fcd2" : "",
                }}
              >
                <Card.Title id="miRuta-Pedido-Titulo">
                  R{ruta.numero}-{pedido.ordenRuta} :{pedido.id} {pedido.nombre}
                </Card.Title>
                <MiRutaPedidoDomicilio pedido={pedido} />
                <div id="miRuta-Pedido-Botones">
                  <Button
                    className="btn btn-primary btn-lg"
                    href={`https://www.google.com/maps/dir/?api=1&destination=${pedido.latitude},${pedido.longitude}&travelmode=driving`}
                    target="_blank"
                  >
                    <Signpost2 color="white" size="20px" />
                  </Button>
                  <Button
                    size="lg"
                    onClick={() => {
                      setMapCenter({
                        lat: pedido.latitude,
                        lng: pedido.longitude,
                      });
                      setMapZoom(16);
                    }}
                  >
                    <ZoomIn color="white" size="20px" />
                  </Button>
                  <Button
                    size="lg"
                    style={{ textAlign: "right" }}
                    onClick={() => setPedidoEntregar(pedido)}
                    disabled={pedido.estatus === 1}
                  >
                    Entregar
                  </Button>
                </div>
              </Card>
            ))}
          </div>
          {pedidoEntregar && (
            <EntregarPedido
              pedido={pedidoEntregar}
              onHide={() => {
                setPedidoEntregar();
                onRutaChanged();
              }}
              onRutaChanged={onRutaChanged}
            />
          )}
        </div>
      </Modal.Body>
    </Modal>
  );
};

export default MiRuta;

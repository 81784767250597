import React, { useState, useEffect } from "react";
import moment from "moment";
import { Button, Modal } from "react-bootstrap";
import { Backdrop, CircularProgress } from "@material-ui/core";

import {
  GetOrdenesCompra,
  GetRecepcionesCompra,
  GuardarRecepcionCompra,
} from "../../../services/backoffice/recepcionCompraServices";
import SearchInput from "../../../common/utils/searchInput";
import { Card, Form } from "react-bootstrap";

import "./recepcionCompra.css";
import QuantityInput from "../../../common/utils/quantityInput";
import { TienePermiso } from "../../../services/backofficeService";

//TODO: Link en Pedidos

const RecepcionCompra = ({ sessionId }) => {
  const [ordenesCompra, setOrdenesCompra] = useState([]);
  const [selectedOrdenCompraId, setSelectedOrdenCompraId] = useState(0);
  const [recepciones, setRecepciones] = useState([]);
  const [search, setSearch] = useState("");
  const [loading, setLoading] = useState(true);
  const [pedidosProducto, setPedidosProducto] = useState([]);

  const cargarOrdenesCompra = async () => {
    setLoading(true);
    try {
      let fecha = moment().format("YYYY-MM-DD");
      if (process.env.NODE_ENV === "development") fecha = "2021-04-20";
      let r = await GetOrdenesCompra(fecha);
      setOrdenesCompra(r);
      console.log(r);
    } catch (error) {
      let message = error;
      let logMessage = error;
      if (error.response && error.response.data) {
        message = error.response.data;
        logMessage = error.response;
      }
      console.log("Error al cargar las órdenes de compra", logMessage);
      alert(message);
    }
    setLoading(false);
  };

  useEffect(() => {
    async function tienePermiso() {
      var respuesta = await TienePermiso(sessionId, "Compras/RecepcionCompra");
      if (respuesta !== "") {
        alert(respuesta);
        window.location.href = "/backoffice";
        return;
      }
    }

    tienePermiso();

    cargarOrdenesCompra();
  }, []);

  const seleccionarOrdenCompra = async (id) => {
    if (id === 0) {
      setRecepciones([]);
      return;
    }

    // Si ya tenia una seleccionada y cambio, pide confirmacion
    if (selectedOrdenCompraId !== 0) {
      if (selectedOrdenCompraId === id) return;
      let hasChanges = recepciones.filter((p) => p.recibido > 0).length > 0;
      if (hasChanges) {
        let confirm = window.confirm("Se perderan cambios");
        if (!confirm) return;
      }
    }

    setLoading(true);
    try {
      let r = await GetRecepcionesCompra(id);
      r.forEach((x) => (x.recibido = 0));

      setRecepciones(r);
      setSelectedOrdenCompraId(id);
    } catch (error) {
      let message = error;
      let logMessage = error;
      if (error.response && error.response.data) {
        message = error.response.data;
        logMessage = error.response;
      }
      console.log("Error al cargar la orden de compra", logMessage);
      alert(message);
    }
    setLoading(false);
  };

  const guardar = async () => {
    try {
      let request = [];

      recepciones
        .filter((r) => r.recibido > 0)
        .forEach((r) => {
          request.push({
            ordenCompraPartidaId: r.ordenCompraPartidaId,
            cantidad: r.recibido,
          });
        });
      await GuardarRecepcionCompra(request);

      setSelectedOrdenCompraId(0);
      seleccionarOrdenCompra(0);
    } catch (error) {
      let message;
      if (error.response && error.response.data) {
        message = error.response.data;
        console.log("Error al guardar las recepciones", error.response);
      } else {
        message = error;
        console.log("Error al guardar las recepciones", error);
      }

      alert(message);
    }
  };

  const diferenciaSolicitado = (recepcion) =>
    recepcion.recibido - recepcion.cantidadSolicitada;

  const existenciaNueva = (recepcion) =>
    recepcion.existenciaInicial + recepcion.totalRecibido + recepcion.recibido;

  const diferenciaPedidos = (recepcion) =>
    recepcion.existenciaInicial +
    recepcion.totalRecibido +
    recepcion.recibido -
    recepcion.totalPedidos;

  const diferenciaTotalSolicitado = (recepcion) =>
    recepcion.totalRecibido + recepcion.recibido - recepcion.cantidadSolicitada;

  return (
    <div className="recepcionCompra">
      <div className="backoffice-header fixed-header recepcionCompra__header ">
        <h1>Recepción de compra</h1>
        <div>
          <Form.Control
            className="recepcionCompra__selectOrdenCompra"
            as="select"
            custom
            value={selectedOrdenCompraId}
            onChange={(e) => seleccionarOrdenCompra(e.currentTarget.value)}
          >
            {selectedOrdenCompraId === 0 && (
              <option value={0}>Orden de compra</option>
            )}
            {ordenesCompra.map((orden) => (
              <option key={orden.ordenCompraId} value={orden.ordenCompraId}>
                {orden.nombreProveedor}
              </option>
            ))}
          </Form.Control>
          <SearchInput
            value={search}
            onChange={(search) => setSearch(search.toLowerCase())}
          />
        </div>
      </div>
      {loading && (
        <Backdrop open={true} style={{ zIndex: "999" }}>
          <CircularProgress color="inherit" />
        </Backdrop>
      )}

      <div className="recepcionCompra__body">
        {recepciones
          .filter(
            (f) =>
              !search || f.producto.descripcion.toLowerCase().includes(search)
          )
          .map((recepcion) => (
            <Card
              className="recepcion__partida"
              key={recepcion.ordenCompraPartidaId}
            >
              <div className="recepcion__partida-body">
                <img src={recepcion.producto.imagenUrl} alt="" />

                <div
                  className={`recepcion__partida-column ${
                    diferenciaTotalSolicitado(recepcion) >= 0
                      ? " completada"
                      : ""
                  }`}
                >
                  <div id="recepcion__partida-descripcion">
                    {recepcion.producto.descripcion}
                  </div>
                  <div className="recepcion__partida-two-cols">
                    <div className="recepcion__partida-two-cols__left">
                      Solicitado:
                    </div>
                    <div className="recepcion__partida-two-cols__right">
                      {recepcion.cantidadSolicitada} {recepcion.producto.unidad}
                    </div>
                    <div className="recepcion__partida-two-cols__span">
                      <QuantityInput
                        cantidad={recepcion.recibido}
                        unidad={recepcion.producto.unidad}
                        onChange={(cantidad) => {
                          let recepcionIndex = recepciones.findIndex(
                            (x) =>
                              x.ordenCompraPartidaId ===
                              recepcion.ordenCompraPartidaId
                          );
                          recepciones[recepcionIndex].recibido = Number(
                            cantidad
                          );
                          setRecepciones([...recepciones]);
                        }}
                      />
                    </div>

                    <div className="recepcion__partida-two-cols__left">
                      Recibido
                    </div>
                    <div className="recepcion__partida-two-cols__right">
                      {recepcion.totalRecibido + recepcion.recibido}
                    </div>

                    <div className="recepcion__partida-two-cols__left">
                      Diferencia:
                    </div>
                    <div
                      className={
                        diferenciaTotalSolicitado(recepcion) < 0
                          ? "recepcion__partida-two-cols__right red"
                          : "recepcion__partida-two-cols__right green"
                      }
                    >
                      {diferenciaTotalSolicitado(recepcion)}
                    </div>

                    <div className="recepcion__partida-two-cols__left">
                      Existencia anterior
                    </div>
                    <div className="recepcion__partida-two-cols__right">
                      {recepcion.existenciaInicial}
                    </div>

                    <div className="recepcion__partida-two-cols__left">
                      Existencia nueva
                    </div>
                    <div className="recepcion__partida-two-cols__right">
                      {existenciaNueva(recepcion)}
                    </div>
                    <div className="recepcion__partida-two-cols__left">
                      {recepcion.totalPedidos > 0 && (
                        <a
                          href="#"
                          onClick={() => setPedidosProducto(recepcion.pedidos)}
                        >
                          Pedidos
                        </a>
                      )}
                      {recepcion.totalPedidos === 0 && <span>Pedidos</span>}
                    </div>
                    <div className="recepcion__partida-two-cols__right">
                      {recepcion.totalPedidos}
                    </div>
                    <div className="recepcion__partida-two-cols__left">
                      Diferencia
                    </div>
                    <div
                      className={
                        diferenciaPedidos(recepcion) < 0
                          ? "recepcion__partida-two-cols__right red"
                          : "recepcion__partida-two-cols__right green"
                      }
                    >
                      {diferenciaPedidos(recepcion)}
                    </div>
                  </div>
                </div>
              </div>
            </Card>
          ))}
      </div>
      <div className="fixed-footer">
        <Button variant="primary" block onClick={() => guardar()}>
          Guardar
        </Button>
      </div>

      <Modal
        show={pedidosProducto.length > 0}
        onHide={() => setPedidosProducto([])}
      >
        <Modal.Header closeButton>
          <Modal.Title>Pedidos del producto </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {pedidosProducto.map((pedido) => (
            <div key={pedido.pedidoId}>
              {pedido.pedidoId} {pedido.nombre} {pedido.chofer}
              {pedido.cantidadSolicitada}
            </div>
          ))}
        </Modal.Body>
      </Modal>
    </div>
  );
};

export default RecepcionCompra;

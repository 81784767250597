import { parsePhoneNumberFromString as parseMax } from "libphonenumber-js/max";

export const validateEmail = (email) => {
  const regexp = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return regexp.test(email);
};

export const validatePhone = (telefono) => {
  const parsedPhone = parseMax(telefono, "MX");
  if (parsedPhone == null || !parsedPhone.isValid()) {
    return "El número de teléfono no es válido";
  }

  return null;
};

import { Modal } from "react-bootstrap";
import { TextField } from "@material-ui/core";
import React, { useState } from "react";
import { PostUsuario, PutUsuario } from "../../services/backofficeService";
import PerfilUsuarioCombo from "./perfilUsuarioCombo";
import InputCheckBox from "../../common/utils/inputCheckbox";

const UpdateUsuario = ({ usuario, editAction, onChange, onHide, onSave }) => {
  const [password, setPassword] = useState();
  const [passwordConfirm, setPasswordConfirm] = useState();
  const [errorMessage, setErrorMessage] = useState();

  if (!usuario || !editAction) return <span />;
  return (
    <Modal show={editAction} onHide={() => onHide()}>
      <Modal.Header closeButton>
        <Modal.Title>
          {editAction === "add" ? "Nuevo " : "Editar "} usuario
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div>
          <TextField
            label="Nombre"
            value={usuario.nombre}
            required
            fullWidth
            onChange={(e) => {
              usuario.nombre = e.currentTarget.value;
              onChange();
            }}
          />

          <TextField
            label="eMail"
            value={usuario.eMail}
            required
            fullWidth
            onChange={(e) => {
              usuario.eMail = e.currentTarget.value;
              onChange();
            }}
          />

          <TextField
            label="Contraseña"
            inputProps={{
              autocomplete: "new-password",
              form: {
                autocomplete: "off",
              },
            }}
            value={password}
            type="password"
            required={editAction === "add"}
            fullWidth
            onChange={(e) => {
              setPassword(e.currentTarget.value);
            }}
          />

          <TextField
            label="Confirmar"
            value={passwordConfirm}
            type="password"
            required={editAction === "add"}
            fullWidth
            onChange={(e) => {
              setPasswordConfirm(e.currentTarget.value);
            }}
          />

          <PerfilUsuarioCombo
            value={usuario.perfilUsuarioId}
            onChange={(e) => {
              usuario.perfilUsuarioId = e.target.value;
              onChange();
            }}
          />

          <InputCheckBox
            name="deshabilitado"
            label="Deshabilitado"
            checked={usuario.deshabilitado}
            onChange={(e) => {
              usuario.deshabilitado = e.currentTarget.checked;
              onChange();
            }}
          />
        </div>
      </Modal.Body>
      <Modal.Footer>
        {errorMessage && <div style={{ color: "red" }}>{errorMessage}</div>}
        <button
          className="btn btn-primary"
          onClick={async () => {
            setErrorMessage("");

            if (editAction === "add" && !password) {
              setErrorMessage("Escriba la contraseña para el nuevo usuario");
              return;
            }

            if (password) {
              if (password.length < 4) {
                setErrorMessage(
                  "La contraseña debe tener una longitud minima de 4 caracteres"
                );
                return;
              }
              if (password !== passwordConfirm) {
                setErrorMessage("La contraseña y su confirmación no coinciden");
                return;
              }
              usuario.password = password;
            }

            console.log("perfil", usuario.perfilUsuarioId);

            try {
              if (editAction === "add") await PostUsuario(usuario);
              if (editAction === "edit") await PutUsuario(usuario);
            } catch (error) {
              let msg = "";
              if (error.response) msg = error.response.data;
              setErrorMessage("Error al guardar los cambios" + msg);
              return;
            }

            onSave();
          }}
        >
          Guardar
        </button>
      </Modal.Footer>
    </Modal>
  );
};

export default UpdateUsuario;

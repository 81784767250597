import React from "react";

import { Modal, Button, Form, Alert } from "react-bootstrap";

const YesNoDialog = ({ title, content, onDialogResponse }) => {
  return (
    <Modal show={true}>
      <Modal.Header>
        <Modal.Title>{title}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form>
          <p>{content}</p>
        </Form>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="primary" onClick={() => onDialogResponse("yes")}>
          &nbsp; &nbsp; Si&nbsp; &nbsp;
        </Button>
        <Button variant="secondary" onClick={() => onDialogResponse("no")}>
          &nbsp; &nbsp; No&nbsp; &nbsp;
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default YesNoDialog;

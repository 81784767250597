import React, { useEffect, useState } from "react";

import { useLocation } from "react-router";
import moment from "moment";

import Aviso from "./aviso";
import { DiaInhabil } from "../services/backofficeService";

import ReactHtmlParser, {
  processNodes,
  convertNodeToElement,
  htmlparser2,
} from "react-html-parser";

const AvisoDiaInhabil = () => {
  const [inhabil, setInhabil] = useState({});
  const [showAviso, setShowAviso] = useState(false);

  let query = new URLSearchParams(useLocation().search);

  useEffect(() => {
    async function cargar() {
      let fechaPedido = query.get("fecha");
      if (!fechaPedido) fechaPedido = moment();
      else fechaPedido = moment(fechaPedido);
      console.log("fechap", fechaPedido);
      let inhabil = await DiaInhabil(fechaPedido);
      console.log("inhabil", inhabil);

      setInhabil(inhabil);
      setShowAviso(true);
    }

    cargar();
  }, []);

  if (!inhabil) return <div />;

  return (
    <Aviso
      show={showAviso}
      onHide={() => setShowAviso(false)}
      mensaje={ReactHtmlParser(inhabil.mensaje)}
    />
  );
};

export default AvisoDiaInhabil;

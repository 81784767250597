import React, { Component } from "react";
import moment from "moment";
import "moment/locale/es";
import Input from "../../../common/utils/input";
import CodigosPostalesCombo from "../../../common/codigosPostales/codigosPostalesCombo";
import { AsYouType } from "libphonenumber-js";
import { validatePhone } from "../../../common/utils/validationHelpers";
import Joi from "joi-browser";
import {
  CerrarPedido,
  FechaEstimadaEntrega,
} from "../../../services/pedidoService";
import DialogBox from "../../../common/dialogBox";
import { Redirect, withRouter } from "react-router-dom";
import { RegistrarEvento } from "../../../services/eventosServices";
import AddressLocationSelector from "./checkoutAddressLocation";
import { Backdrop, CircularProgress } from "@material-ui/core";

class CheckoutForm extends Component {
  state = {
    errors: {},
    loading: false,
    pedido: null,
    disableSubmit: false,
    dialogShow: false,
    dialogTitle: "",
    dialogContent: "",
    dialogShouldClose: false,
    ultimaHoraEntregaRevisada: null,
    fechaEstimadaEntrega: {},
    redirectToHome: false,
    resetMarkerPosition: false,
  };

  async componentDidMount() {
    await this.mostrarFechaEntrega();

    const pedido = { ...this.props.pedido };
    if (localStorage.getItem("datosPedidoNombre") !== null)
      pedido.nombre = localStorage.datosPedidoNombre;
    if (localStorage.getItem("datosPedidoEmail") !== null)
      pedido.eMail = localStorage.datosPedidoEmail;
    if (localStorage.getItem("datosPedidoTelefono") !== null)
      pedido.telefono = localStorage.datosPedidoTelefono;
    if (localStorage.getItem("datosPedidoCalle") !== null)
      pedido.calle = localStorage.datosPedidoCalle;
    if (localStorage.getItem("datosPedidoNoExterior") !== null)
      pedido.noExterior = localStorage.datosPedidoNoExterior;
    if (localStorage.getItem("datosPedidoNoInterior") !== null)
      pedido.noInterior = localStorage.datosPedidoNoInterior;
    if (localStorage.getItem("datosPedidoCodigoPostal") !== null)
      pedido.codigoPostal = localStorage.datosPedidoCodigoPostal;
    if (localStorage.getItem("datosPedidoColonia") !== null)
      pedido.colonia = localStorage.datosPedidoColonia;
    if (localStorage.getItem("datosPedidoEntreCalle1") !== null)
      pedido.entreCalle1 = localStorage.datosPedidoEntreCalle1;
    if (localStorage.getItem("datosPedidoEntreCalle2") !== null)
      pedido.entreCalle2 = localStorage.datosPedidoEntreCalle2;
    if (localStorage.getItem("datosPedidoReferencias") !== null)
      pedido.referencias = localStorage.datosPedidoReferencias;
    /*    if (localStorage.getItem("datosPedidoNotas") !== null)
      pedido.notas = localStorage.datosPedidoNotas;*/
    if (localStorage.getItem("datosPedidoLatitude") !== null)
      pedido.latitude = localStorage.datosPedidoLatitude;
    if (localStorage.getItem("datosPedidoLongitude") !== null)
      pedido.longitude = localStorage.datosPedidoLongitude;

    this.setState({ pedido, resetMarkerPosition: true });
  }

  async componentDidUpdate() {
    await this.mostrarFechaEntrega();
  }

  mostrarFechaEntrega = async () => {
    var horaActual = moment().format("HH");

    if (!this.state.ultimaHoraEntregaRevisada)
      this.setState({ ultimaHoraEntregaRevisada: horaActual });
    else if (this.state.ultimaHoraEntregaRevisada !== horaActual)
      this.setState({ ultimaHoraEntregaRevisada: horaActual });
    else return;

    var fechaEntrega = await FechaEstimadaEntrega();

    console.log("Fecha entrega:", fechaEntrega);

    if (fechaEntrega) {
      var fechaFormatted = moment(fechaEntrega.fechaEntrega)
        .locale("es")
        .format("dddd, D [de] MMMM [de] YYYY");
      this.setState({
        ultimaHoraEntregaRevisada: horaActual,
        fechaEstimadaEntrega: fechaFormatted + " " + fechaEntrega.horario,
      });
    }
  };

  handleChange = ({ currentTarget: input }) => {
    const errors = { ...this.state.errors };
    const errorMessage = this.validateProperty(input);
    if (errorMessage) errors[input.name] = errorMessage;
    else delete errors[input.name];

    const pedido = { ...this.state.pedido };
    if (input.name === "telefono") {
      if (input.value.length > 12) return;

      pedido.telefono = new AsYouType("MX").input(input.value);
    } else pedido[input.name] = input.value;

    this.setState({ pedido, errors });
  };

  handleCodigoPostalChanged = async (e) => {
    const codigoPostal = e.currentTarget.value;
    if (codigoPostal && !Number(codigoPostal)) return;
    if (codigoPostal.length > 5) return;
    this.handleChange(e);
  };

  handleCodigoPostalError = (message) => {
    const errors = { ...this.state.errors };
    errors.codigoPostal = message;
    this.setState({ errors });
  };

  schema = {
    nombre: Joi.string()
      .required()
      .min(10)
      .max(100)
      .error((err) => {
        const newError = { ...err[0] };
        switch (newError.type) {
          case "string.base":
          case "any.empty":
          case "string.empty":
            newError.message = "Escribe tu nombre";
            break;
          default:
            newError.message = "Nombre inválido";
            break;
        }
        return newError;
      }),
    eMail: Joi.string()
      .required()
      .email({ minDomainAtoms: 2 })
      .error((err) => {
        const newError = { ...err[0] };
        switch (newError.type) {
          case "string.base":
          case "any.empty":
          case "string.empty":
            newError.message = "Escribe tu correo electrónico";
            break;
          default:
            newError.message = "Correo inválido";
            break;
        }
        return newError;
      }),
    telefono: Joi.string()
      .required()
      .min(10)
      .max(12)
      .error((err) => {
        const newError = { ...err[0] };
        switch (newError.type) {
          case "string.base":
          case "any.empty":
          case "string.empty":
            newError.message = "Escribe tu número telefónico";
            break;
          case "string.min":
          case "string.max":
            newError.message = "Escribe los 10 dígitos de tu número telefónico";
            break;
          default:
            newError.message = "Número de teléfono inválido";
            break;
        }
        return newError;
      }),
    calle: Joi.string()
      .required()
      .min(5)
      .error((err) => {
        const newError = { ...err[0] };
        newError.message = "Escribe el nombre de tu calle";
        return newError;
      }),
    noExterior: Joi.string()
      .required()
      .error((err) => {
        const newError = { ...err[0] };
        newError.message = "Escribe el número exterior de tu domicilio";
        return newError;
      }),
    codigoPostal: Joi.string()
      .required()
      .trim()
      .regex(/^(?:0[1-9]|[1-4]\d|5[0-2])\d{3}$/)
      .error((err) => {
        const newError = { ...err[0] };
        newError.message = "Escribe un código postal válido";
        return newError;
      }),
    colonia: Joi.string()
      .required()
      .error((err) => {
        const newError = { ...err[0] };
        newError.message = "Selecciona la colonia de tu domicilio";
        return newError;
      }),
  };

  validate = () => {
    const pedido = this.state.pedido;

    const pedidoValidar = {};
    for (let item of Object.keys(this.schema))
      pedidoValidar[item] = pedido[item];

    const result = Joi.validate(pedidoValidar, this.schema, {
      abortEarly: false,
    });

    if (!result.error) {
      const errorTelefono = validatePhone(pedido.telefono);

      if (errorTelefono) return errorTelefono;

      if (!pedido.latitude || !pedido.longitude)
        return { message: "Indica en el mapa la ubicación del domicilio" };

      return errorTelefono;
    }

    const errors = {};
    for (let item of result.error.details) errors[item.path[0]] = item.message;
    return errors;
  };

  validateProperty = ({ name, value }) => {
    if (!this.schema[name]) return null;

    if (name === "telefono") {
      const errorTelefono = validatePhone(value);
      if (errorTelefono) return errorTelefono;
    }

    const obj = { [name]: value };
    const schema = { [name]: this.schema[name] };
    const { error } = Joi.validate(obj, schema);
    return error ? error.details[0].message : null;
  };

  handleSubmit = async (e) => {
    e.preventDefault();

    const errors = this.validate();
    this.setState({ errors });
    if (errors !== null) return;

    const pedido = this.state.pedido;

    // Guarda los datos en el navegador
    localStorage.datosPedidoNombre = pedido.nombre;
    localStorage.datosPedidoEmail = pedido.eMail;
    localStorage.datosPedidoTelefono = pedido.telefono;
    localStorage.datosPedidoCalle = pedido.calle;
    localStorage.datosPedidoNoExterior = pedido.noExterior;
    if (pedido.noInterior)
      localStorage.datosPedidoNoInterior = pedido.noInterior;
    else localStorage.datosPedidoNoInterior = "";
    localStorage.datosPedidoCodigoPostal = pedido.codigoPostal;
    localStorage.datosPedidoColonia = pedido.colonia;
    if (pedido.entreCalle1)
      localStorage.datosPedidoEntreCalle1 = pedido.entreCalle1;
    else localStorage.datosPedidoEntreCalle1 = "";
    if (pedido.entreCalle2)
      localStorage.datosPedidoEntreCalle2 = pedido.entreCalle2;
    else localStorage.datosPedidoEntreCalle2 = "";
    if (pedido.referencias)
      localStorage.datosPedidoReferencias = pedido.referencias;
    else localStorage.datosPedidoReferencias = "";
    /*  if (pedido.notas) localStorage.datosPedidoNotas = pedido.notas;
    else */
    localStorage.datosPedidoNotas = "";
    if (pedido.latitude) localStorage.datosPedidoLatitude = pedido.latitude;
    if (pedido.longitude) localStorage.datosPedidoLongitude = pedido.longitude;

    this.setState({ disableSubmit: true, loading: true });

    try {
      const response = await CerrarPedido(this.state.pedido);
      if (response.error) {
        if (response.error.includes("monto mínimo"))
          await RegistrarEvento(15, this.props.pedido.total);
        this.setState({
          dialogShow: true,
          dialogTitle: "¡ Error !",
          dialogShouldClose: false,
          disableSubmit: false,
          dialogContent: (
            <span>
              <b>NO</b> se ha podido guardar el pedido
              <br />
              <b>{response.error}</b>
              <br />
              {!response.errorValidacion && <span>Intente nuevamente.</span>}
            </span>
          ),
        });
      } else {
        await RegistrarEvento(14, response.folioPedido);
        this.setState({
          dialogShow: true,
          dialogTitle: "Se ha creado el pedido",
          dialogShouldClose: true,
          dialogContent: (
            <span>
              Se ha creado el pedido <b>{response.folioPedido}</b>
              <br />
              Se entregará el día{" "}
              <b>
                {" "}
                {moment(response.fechaEntrega)
                  .locale("es")
                  .format("dddd, D [de] MMMM [de] YYYY")}
              </b>
              <br />
              Hemos enviado a tu correo una copia del pedido.
              <br />
            </span>
          ),
        });
      }
    } catch (exception) {
      this.setState({
        dialogShow: true,
        dialogTitle: "¡ Error !",
        dialogShouldClose: false,
        disableSubmit: false,
        dialogContent: (
          <span>
            <b>NO</b> se ha podido guardar el pedido
            <br />
            Por favor intente nuevamente.
          </span>
        ),
      });
    } finally {
      this.setState({ loading: false });
    }
  };

  onDialogClick = () => {
    this.setState({ dialogShow: false });

    if (this.state.dialogShouldClose) this.setState({ redirectToHome: true });
    //    else window.location.reload();
  };

  render() {
    if (this.state.pedido == null) return "";
    const pedido = this.state.pedido;
    let errors = this.state.errors;
    if (errors == null) errors = {};

    if (this.state.redirectToHome) return <Redirect to="/" />;

    return (
      <div className="div100" id="checkoutForm">
        <Backdrop open={this.state.loading} style={{ zIndex: "1" }}>
          <CircularProgress color="inherit" />
        </Backdrop>
        <form>
          <label>
            Fecha de entrega:
            <strong>{this.state.fechaEstimadaEntrega}</strong>
          </label>
          <div className="div100" id="generales">
            <div className="clear20"></div>
            Para poder enviar tu pedido, por favor llena la siguiente forma con
            tus datos
            <div className="clear10"></div>
            <div className="titulos30  verde negrita">Datos Generales</div>
            <Input
              name="nombre"
              label="Nombre y apellido"
              value={pedido.nombre}
              onChange={this.handleChange}
              error={errors.nombre}
            />
            <Input
              name="eMail"
              label="eMail"
              value={pedido.eMail}
              type="email"
              onChange={this.handleChange}
              error={errors.eMail}
            />
            <Input
              label="Celular"
              value={pedido.telefono}
              type="tel"
              name="telefono"
              onChange={this.handleChange}
              error={errors.telefono}
            />
          </div>
          <div id="domicilio">
            <div className="clear30"></div>
            <div className="titulos30  verde negrita">Domicilio de entrega</div>
            <Input
              label="Calle"
              value={pedido.calle}
              type="text"
              name="calle"
              onChange={this.handleChange}
              error={errors.calle}
            />
            <Input
              label="No. Exterior"
              value={pedido.noExterior}
              name="noExterior"
              onChange={this.handleChange}
              error={errors.noExterior}
            />
            <Input
              label="No. Interior"
              value={pedido.noInterior}
              name="noInterior"
              onChange={this.handleChange}
              error={errors.noInterior}
            />
            <Input
              label="Código postal"
              value={pedido.codigoPostal}
              name="codigoPostal"
              onChange={this.handleCodigoPostalChanged}
              error={errors.codigoPostal}
            />

            <div className="divNo">Municipio: {pedido.municipio}</div>

            <div className="divNo">
              <label htmlFor="colonia">Colonia:</label>
              <CodigosPostalesCombo
                name="colonia"
                codigoPostal={pedido.codigoPostal}
                colonia={pedido.colonia}
                errors={errors.colonia}
                onChange={this.handleChange}
                onError={this.handleCodigoPostalError}
              />
            </div>

            <Input
              label="Entre calle"
              value={pedido.entreCalle1}
              name="entreCalle1"
              onChange={this.handleChange}
              errors={errors.entreCalle1}
            />
            <Input
              label="y calle"
              value={pedido.entreCalle2}
              name="entreCalle2"
              onChange={this.handleChange}
              errors={errors.entreCalle2}
            />
            <Input
              label="Referencias"
              value={pedido.referencias}
              name="referencias"
              onChange={this.handleChange}
              errors={errors.referencias}
            />

            <AddressLocationSelector
              pedido={pedido}
              resetMarkerPosition={this.state.resetMarkerPosition}
            />

            <Input
              style={{ marginTop: "90px" }}
              label="notas"
              value={pedido.notas}
              multiline
              rows={3}
              name="notas"
              onChange={this.handleChange}
              errors={errors.notas}
            />
          </div>
          <div className="clear20"></div>
          <div id="botones">
            {errors && (
              <div>
                <ul>
                  {Object.values(errors).map((error) => (
                    <li
                      style={{
                        color: "red",
                        fontSize: "small",
                        textAlign: "left",
                      }}
                    >
                      {error}
                    </li>
                  ))}
                </ul>
              </div>
            )}
            <input
              className="terminar"
              type="submit"
              value="Terminar mi pedido"
              disabled={this.state.disableSubmit}
              onClick={this.handleSubmit}
            />
          </div>

          <div className="clear20"></div>
        </form>{" "}
        <DialogBox
          open={this.state.dialogShow}
          onClose={() => this.onDialogClick()}
          onButtonClick={() => this.onDialogClick()}
          title={this.state.dialogTitle}
          buttonText="Aceptar"
        >
          <div>{this.state.dialogContent}</div>
        </DialogBox>
      </div>
    );
  }
}

export default withRouter(CheckoutForm);

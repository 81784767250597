import React, { useState } from "react";

import { Modal } from "react-bootstrap";
import { Paper, Button, Grid, Box } from "@material-ui/core";

import Input from "../../common/utils/input";
import InputNumeric from "../../common/utils/inputNumeric";
import InputCheckBox from "../../common/utils/inputCheckbox";

import CategoriaProductoCombo from "../../common/utils/categoriaProductoCombo";
import ProveedorCombo from "../proveedores/proveedorCombo";
import {
  PutProducto,
  PostProducto,
  UploadImagenProducto,
} from "../../services/backofficeService";

const UpdateProducto = ({ producto, editAction, onChange, onHide, onSave }) => {
  const [imageToUpload, setImageToUpload] = useState();
  const [disableSubmit, setDisableSubmit] = useState(false);

  const handleChange = ({ currentTarget: input }) => {
    producto[input.name] = input.value;
    onChange();
  };

  const handleDecimalChange = (name, values) => {
    producto[name] = values.floatValue;
    onChange();
  };

  const handleBoolChange = ({ currentTarget: input }) => {
    producto[input.name] = input.checked;
    onChange();
  };

  const selectFileHandler = (event) => {
    // Validate the file type
    const fileTypes = ["image/png", "image/jpeg"];
    let file = event.target.files[0];
    if (fileTypes.every((extension) => file.type != extension)) {
      alert(`The file ${file.type} extension is not supported`);
      return;
    }

    // Set the file to be uploaded on submit
    setImageToUpload(file);

    // Shows the image
    var reader = new FileReader();

    var imgtag = document.getElementById("productImage");
    imgtag.title = file.name;

    reader.onload = function (event) {
      imgtag.src = event.target.result;
    };

    reader.readAsDataURL(file);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setDisableSubmit(true);

    try {
      // Creates or Update the Item
      if (editAction === "add") producto.id = await PostProducto(producto);
      if (editAction === "edit") await PutProducto(producto);

      // If has files to be uploaded, do the upload
      if (imageToUpload != null)
        producto.imagenUrl = await UploadImagenProducto(
          producto.id,
          imageToUpload
        );

      onSave();
    } catch (ex) {
      alert(ex);
    } finally {
      setDisableSubmit(false);
    }
  };

  if (!producto || !editAction) return <span />;
  return (
    <Modal show={editAction} onHide={() => onHide()}>
      <Modal.Header closeButton>
        <Modal.Title>
          {editAction === "add" ? "Nuevo " : "Editar "} producto
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Paper>
          <Box margin={3}>
            <Grid container spacing={3}>
              <Grid item>
                Id: <b>{producto.id}</b>
              </Grid>

              <Grid item xs={12}>
                <Input
                  label="Descripción"
                  value={producto.descripcion}
                  name="descripcion"
                  fullWidth
                  onChange={handleChange}
                />
              </Grid>

              <Grid container>
                <Grid item xs={4}>
                  <CategoriaProductoCombo
                    value={producto.categoriaProductoId}
                    onChange={(e) => {
                      producto.categoriaProductoId = e.target.value;
                      onChange();
                    }}
                    name="categoriaProductoId"
                  />
                </Grid>
                <Grid item xs={3}>
                  <ProveedorCombo
                    value={producto.proveedorId}
                    onChange={(e) => {
                      producto.proveedorId = e.target.value;
                      onChange();
                    }}
                  />
                </Grid>
              </Grid>

              <Grid item xs={3}>
                <Input
                  label="Unidad"
                  name="unidad"
                  tipo="decimal"
                  value={producto.unidad}
                  onChange={handleChange}
                />
              </Grid>
              <Grid item xs={9}></Grid>

              <Grid item xs={3}>
                <InputNumeric
                  label="Intervalo"
                  name="intervalo"
                  value={producto.intervalo}
                  onChange={handleDecimalChange}
                  fixedDecimalScale={2}
                  decimalScale={2}
                  allowNegative={false}
                />
              </Grid>

              <Grid item xs={3}>
                <InputNumeric
                  label="Peso por pieza"
                  name="pesoPorPieza"
                  value={producto.pesoPorPieza}
                  onChange={handleDecimalChange}
                  fixedDecimalScale={2}
                  decimalScale={2}
                  allowNegative={false}
                />
              </Grid>

              <Grid item xs={3}>
                <InputNumeric
                  label="Margen"
                  name="margen"
                  value={producto.margen}
                  onChange={handleDecimalChange}
                  fixedDecimalScale={2}
                  decimalScale={2}
                  allowNegative={false}
                />
              </Grid>
              <Grid item xs={3}>
                <InputNumeric
                  label="Costo"
                  name="costoUnitario"
                  value={producto.costoUnitario}
                  onChange={handleDecimalChange}
                  fixedDecimalScale={2}
                  decimalScale={2}
                  allowNegative={false}
                />
              </Grid>
              <Grid item>
                <InputCheckBox
                  name="activo"
                  label="Activo"
                  checked={producto.activo}
                  onChange={handleBoolChange}
                />
              </Grid>
              <Grid container>
                <Grid item>
                  <img
                    id="productImage"
                    width={150}
                    height={150}
                    src={producto.imagenUrl}
                    alt=""
                  ></img>
                </Grid>
                <Grid item>
                  <input type="file" onChange={selectFileHandler} />
                </Grid>
              </Grid>
            </Grid>
            <Box margin={4}>
              <Grid container justify="flex-end">
                <Button
                  variant="contained"
                  color="primary"
                  disabled={disableSubmit}
                  onClick={handleSubmit}
                  fullWidth
                >
                  Guardar
                </Button>
              </Grid>

              <div>
                <br></br>
              </div>
            </Box>
          </Box>
        </Paper>
      </Modal.Body>
      <Modal.Footer>
        <Button
          variant="contained"
          color="primary"
          onClick={async () => {
            try {
              if (editAction === "add") await PostProducto(producto);
              if (editAction === "edit") await PutProducto(producto);
            } catch (error) {
              alert("Error al guardar los cambios");
              return;
            }
            onSave();
          }}
        >
          Guardar
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default UpdateProducto;

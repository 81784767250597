import React from "react";
import DialogBox from "../../../common/dialogBox";
import Input from "../../../common/utils/input";

const CheckoutItemNotas = (props) => {
  const [open, setOpen] = React.useState(false);
  const [notas, setNotas] = React.useState(props.notas);
  let claseBoton = "botonNotas";
  if (notas) claseBoton += " verde";

  return (
    <div>
      <div
        className={claseBoton}
        onClick={() => {
          setOpen(!open);
        }}
      >
        <div className="material-icons">edit</div>
        <div className="div100"> Notas</div>
      </div>

      <DialogBox
        open={open}
        buttonText="Agregar"
        title="Notas"
        fullWidth
        maxWidth="sm"
        onClose={() => {
          setOpen(false);
        }}
        onButtonClick={() => {
          setOpen(false);
          if (props.onNotasChanged) props.onNotasChanged(notas);
        }}
      >
        <h5>Agrega una nota:</h5>
        <Input
          multiline
          label="Agrega cualquier anotación sobre este producto"
          rowsMax={4}
          rows={4}
          fullWidth
          value={notas}
          onChange={(e) => {
            setNotas(e.currentTarget.value);
          }}
        />
      </DialogBox>
    </div>
  );
};

export default CheckoutItemNotas;

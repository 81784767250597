import { InputLabel, MenuItem, Select } from "@material-ui/core";
import React, { useEffect, useState } from "react";
import { ListaPerfilesUsuario } from "../../services/backofficeService";

const PerfilUsuarioCombo = ({ value, onChange }) => {
  const [perfiles, setPerfiles] = useState([]);

  const cargarPerfiles = async () => {
    const listaPerfiles = await ListaPerfilesUsuario();
    listaPerfiles.sort((a, b) => (a.nombre > b.nombre ? 1 : -1));
    setPerfiles(listaPerfiles);
  };

  useEffect(() => {
    cargarPerfiles();
  }, []);

  return (
    <div>
      <InputLabel id="perfilUsuarioCombo-Label">Perfil</InputLabel>
      <Select
        labelId="perfilUsuarioCombo-Label"
        id="perfilCombo"
        value={value ? value : " "}
        onChange={onChange}
      >
        {perfiles.map((perfil) => (
          <MenuItem key={perfil.id} value={perfil.id}>
            {perfil.nombre}
          </MenuItem>
        ))}
      </Select>
    </div>
  );
};

export default PerfilUsuarioCombo;

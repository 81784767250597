import { SetSessionId } from "../common/utils/sessionHelper";
import webAPIHelper from "../common/utils/webAPIHelper";
import ReactPixel from "react-facebook-pixel";

function GetTrackSessionId() {
  let sessionId = sessionStorage.sessionId;
  if (sessionId === "undefined") return null;
  return sessionId;
}

export async function RegistrarEvento(eventoId, referencia, data) {
  let sessionId = GetTrackSessionId();
  if (!sessionId) {
    sessionId = await RegistrarEventoAPI(null, 1, window.location.href, data);
    sessionStorage.sessionId = sessionId;
    if (eventoId === 1) return;
  }

  const newSessionId = await RegistrarEventoAPI(
    sessionId,
    eventoId,
    referencia,
    data
  );
}

async function RegistrarEventoAPI(sessionId, eventoId, referencia, data) {
  const request = {
    sessionId: sessionId,
    tipoEventoId: eventoId,
    referencia: referencia,
  };

  // Facebook pixel
  ReactPixel.init("2303736183268849", null, { debug: true });
  switch (eventoId) {
    case 1: // Ingresa al sitio
      ReactPixel.pageView();
      break;
    case 2: // Selecciona categoria desde LandingPage
      ReactPixel.track("ViewContent", {
        content_category: data.nombre,
        content_name: `Landing/Categoria: ${data.nombre}`,
      });
      break;
    case 3: // Selecciona categoria desde Productos
      ReactPixel.track("ViewContent", {
        content_category: data.nombre,
        content_name: `Productos/Categoria: ${data.nombre}`,
      });
      break;
    case 4: // Buscar Productos
      ReactPixel.track("Search", {
        search_string: data.busqueda,
        value: data.resultCount,
      });
      break;
    case 7: // Agregar producto al carrito
      ReactPixel.track("AddToCart", {
        content_name: data.descripcion,
      });
      break;
    case 13: // Ver carrito
      ReactPixel.track("InitiateCheckout");
      break;
    case 14: // Cerrar pedido
      ReactPixel.track("Purchase");
      break;
    default:
      break;
  }

  //  fbq("track", "Purchase", { currency: "USD", value: 30.0 });

  const appsvc = new webAPIHelper();
  return await appsvc.Post("/Evento", request);
}

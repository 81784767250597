import React from "react";
import NumberFormat from "react-number-format";
import AddToCartButton from "../addToCartButton";
import CheckoutItemNotas from "./checkoutItemNotas";
const CheckoutItem = ({ partida, onCantidadChanged, onNotasChanged }) => {
  return (
    <div
      className="checkoutLinea"
      key={partida.id}
      id={"checkoutItem_" + partida.id}
    >
      <div className="checkoutLineaImg">
        <img
          style={{ maxWidth: "100%", maxHeight: "100%" }}
          src={partida.producto.imagenUrl}
        />
      </div>
      <div className="checkoutLineaText">
        <div className="checkoutLineaTits">{partida.producto.descripcion}</div>
        <div className="clear"></div>
        <div className="div50f">
          <span id="precioUnitario">
            <NumberFormat
              value={partida.precioUnitario}
              displayType="text"
              decimalScale="2"
              fixedDecimalScale="2"
              thousandSeparator
              prefix={"$"}
            />
            {" / "}
            {partida.producto.unidad}
          </span>
        </div>

        <div className="div50f">
          <CheckoutItemNotas
            notas={partida.notas}
            onNotasChanged={(notas) => {
              if (onNotasChanged) onNotasChanged(partida.id, notas);
            }}
          />
        </div>

        <div className="clear"></div>

        <div className="checkoutLineaSubs" id="importe">
          <b>
            <NumberFormat
              value={partida.importe}
              displayType="text"
              decimalScale="2"
              fixedDecimalScale="2"
              thousandSeparator
              prefix={"$"}
            />
          </b>
        </div>

        <AddToCartButton
          idProducto={partida.productoId}
          cantidad={partida.cantidad}
          onCantidadChanged={onCantidadChanged}
          intervalo={partida.producto.intervalo}
          unidad={partida.producto.unidad}
        />
      </div>
    </div>
  );
};

export default CheckoutItem;

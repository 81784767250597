import React from "react";
import { AgregarAlPedido, CargarPedido } from "../../services/pedidoService";
import {
  DeleteSession,
  GetSessionId,
  SetSessionId,
} from "../../common/utils/sessionHelper";

const CartReset = () => {
  async function resetConservar(e) {
    e.preventDefault();
    let current = await CargarPedido();
    if (!current) {
      resetTotal(e);
      return;
    }
    DeleteSession();
    let sessionId;
    for (let x = 0; x < current.partidas.length; x++) {
      let r = await AgregarAlPedido(
        current.partidas[x].productoId,
        current.partidas[x].cantidad,
        sessionId
      );
      if (!sessionId) sessionId = r.sessionId;
    }
    alert("El carrito ha sido restablecido");
    window.location.href = "/";
  }
  function resetTotal(e) {
    e.preventDefault();
    DeleteSession();
    alert("El carrito ahora esta vacío");
    window.location.href = "/";
  }
  return (
    <div className="div100" id="CheckoutPageEmpty">
      <div style={{ clear: "both", height: "120px" }}></div>
      <h1>Restablecer carrito</h1>
      <button className="btn btn-primary" onClick={resetConservar}>
        Conservar los productos del carrito
      </button>
      <button className="btn btn-danger" onClick={resetTotal}>
        Quitar los productos del carrito
      </button>
    </div>
  );
};

export default CartReset;

import webAPIHelper from "../../common/utils/webAPIHelper";
import moment from "moment";

const controller = "/Backoffice/Rutas";

export async function GetPlaneacion(fechaEntrega) {
  const appsvc = new webAPIHelper();
  const response = await appsvc.Get(
    `${controller}/Planeacion?fechaEntrega=${fechaEntrega.format("YYYY-MM-DD")}`
  );
  return response;
}

export async function SiguienteNumeroRuta(fecha) {
  const appsvc = new webAPIHelper();
  const response = await appsvc.Get(
    `${controller}/SiguienteNumero?fecha=${fecha}`
  );
  return response;
}

export async function CrearRuta(request) {
  const appsvc = new webAPIHelper();
  const response = await appsvc.Post(`${controller}`, request);
  return response;
}

export async function AsignarRutaPedido(rutaId, pedidoId) {
  const appsvc = new webAPIHelper();
  const response = await appsvc.Put(
    `${controller}/${rutaId}/Pedido/${pedidoId}`
  );
  return response;
}

export async function QuitarRutaPedido(pedidoId) {
  const appsvc = new webAPIHelper();
  const response = await appsvc.Delete(
    `${controller}/QuitarPedido/${pedidoId}`
  );
  return response;
}

export async function ActualizarRuta(ruta) {
  const appsvc = new webAPIHelper();
  const response = await appsvc.Put(`${controller}/${ruta.id}`, ruta);
  return response;
}

export async function EliminarRutaService(ruta) {
  const appsvc = new webAPIHelper();
  const response = await appsvc.Delete(`${controller}/${ruta.id}`);
  return response;
}

export async function CambiarOrdenPedidos(desdePedidoId, hastaPedidoId) {
  const appsvc = new webAPIHelper();
  return await appsvc.Put(
    `${controller}/CambiarOrdenPedidos?desdePedidoId=${desdePedidoId}&hastaPedidoId=${hastaPedidoId}`
  );
}

export async function CambiarOrdenRutas(desdeRutaId, hastaRutaId) {
  const appsvc = new webAPIHelper();
  return await appsvc.Put(
    `${controller}/CambiarOrdenRutas?desdeRutaId=${desdeRutaId}&hastaRutaId=${hastaRutaId}`
  );
}

export function GetPedidosDeLaRuta(pedidos, ruta) {
  return pedidos
    .filter((p) => p.rutaEnvio && p.rutaEnvio.id === ruta.id)
    .sort((a, b) => a.ordenRuta - b.ordenRuta);
}

export async function GuardarUbicacionPedido(pedido) {
  const appsvc = new webAPIHelper();
  return await appsvc.Put(
    `${controller}/CambiarUbicacionPedido?pedidoId=${pedido.id}&latitude=${pedido.latitude}&longitude=${pedido.longitude}`
  );
}

export async function GetMisRutas(sessionId, fechaEntrega) {
  const appsvc = new webAPIHelper();
  return await appsvc.Get(
    `${controller}/MisRutas?sessionId=${sessionId}&fechaEntrega=${moment(
      fechaEntrega
    ).format("YYYY-MM-DD")}`
  );
}

export async function GetChoferes() {
  const appsvc = new webAPIHelper();
  return await appsvc.Get(`${controller}/Choferes`);
}

export async function RegistrarEntregaPedido(pedido) {
  const appsvc = new webAPIHelper();
  return await appsvc.Put(`${controller}/EntregarPedido`, pedido);
}

import React from "react";

const PageBanner = (props) => {
  const bannerStyle = {
    minHeight: props.height,
    backgroundImage:
      "url(/img/landingPage/bannerImages/LandingPage_Banner.jpg)",
    backgroundRepeat: "no-repeat",
    backgroundPosition: "left center",
  };

  return (
    <div className="bannerPortada" style={bannerStyle}>
      {props.children}
      <div className="div100 centrado">
        <div className="div100 titulos80">
          La frescura y precio
          <div className="clear"></div>
          del mercado
          <div className="clear"></div>
          hasta tu puerta
        </div>
        <div className="clear10"></div>
      </div>
    </div>
  );
};

export default PageBanner;

import React, { useEffect, useState } from "react";
import { Modal, Button, Form, Alert, Spinner } from "react-bootstrap";
import NumberFormat from "react-number-format";

import {
  CalcularPagosPedido,
  CalcularPedidoTotalEntregado,
  CambiarPartidaCantidadEntregada,
  CalcularImportePartidaEntregado,
} from "../../../../services/backoffice/bakofficeHelpers";
import { RegistrarEntregaPedido } from "../../../../services/backoffice/rutasServices";

import EntregarPedidoPagos from "./entregarPedidoPagos";
import MiRutaPedidoDomicilio from "./miRutaPedidoDomicilio";

const EntregarPedido = ({ pedido, onHide, onRutaChanged }) => {
  const [inicializado, setInicializado] = useState(false);
  const [error, setError] = useState();
  const [porPagar, setPorPagar] = useState();
  const [showDomicilio, setShowDomicilio] = useState(true);
  const [saving, setSaving] = useState(false);

  useEffect(() => {
    console.log(pedido);
    if (!pedido.cantidadesEntregaInicializadas && pedido.estatus === 1) {
      pedido.partidas.forEach((partida) => {
        console.log("p", partida);
        CambiarPartidaCantidadEntregada(partida, partida.cantidad);
      });
      pedido.cantidadesEntregaInicializadas = true;
    }
    if (!pedido.cantidadesEntregaInicializadas && pedido.estatus === 4) {
      pedido.partidas.forEach(
        (partida) =>
          (partida.importeEntregado = CalcularImportePartidaEntregado(partida))
      );
    }

    if (!pedido.notasEntrega) pedido.notasEntrega = "";
    CalcularPedidoTotalEntregado(pedido);

    setInicializado(true);
  }, [pedido]);

  const cambiarCantidad = (partida, cantidadNueva) => {
    if (cantidadNueva == "") {
      cantidadNueva = 0;
    }

    if (cantidadNueva > partida.cantidadSurtida) return;

    CambiarPartidaCantidadEntregada(partida, cantidadNueva);
    CalcularPedidoTotalEntregado(pedido);
    onRutaChanged();
  };

  const guardar = async () => {
    setError();
    setPorPagar();

    let info = CalcularPagosPedido(pedido);
    if (info.excedente) {
      setError(
        <span>
          Los pagos exceden el total a pagar por $
          <strong>
            {" "}
            <NumberFormat
              value={info.excedente}
              displayType="text"
              decimalScale="2"
              fixedDecimalScale="2"
              thousandSeparator
            />
          </strong>
        </span>
      );
      return;
    }

    // Guarda las coordenadas actuales en el pedido
    setSaving(true);
    if (navigator && navigator.geolocation) {
      /*     
      // Se deshabilita temporalmente hasta resolver problemas en algunos dispositivos
       const pos = await new Promise((resolve, reject) => {
        navigator.geolocation.getCurrentPosition(resolve, reject);
      });

      pedido.latitudeEntrega = pos.coords.latitude;
      pedido.longitudeEntrega = pos.coords.longitude;
      */
    }

    // Si hay pendiente de pagar, pide confirmacion
    if (info.porPagar > 1) {
      setPorPagar(info.porPagar);
      return;
    } else {
      await completarGuardar();
    }
  };

  const completarGuardar = async () => {
    try {
      await RegistrarEntregaPedido(pedido);
      pedido.estatus = 4;
      onHide();
    } catch (error) {
      if (error.response) alert("Error " + error.response.data);
      else alert("Error " + error);
    } finally {
      setSaving(false);
    }
  };

  if (!inicializado) return <div />;

  return (
    <Modal show={true} onHide={onHide}>
      <Modal.Header closeButton>
        <Modal.Title>
          <p>Entregar pedido {pedido.id}</p>
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div>
          <div>
            <div id="entregarPedido-Domicilio">
              <h5>{pedido.nombre}</h5>
              <Button onClick={() => setShowDomicilio(!showDomicilio)}>
                {showDomicilio ? "-" : "+"}
              </Button>
            </div>
            {showDomicilio && (
              <MiRutaPedidoDomicilio pedido={pedido} mostrarTelefono />
            )}
          </div>
          <hr />
        </div>
        <div
          id="entregarPedido-Partidas"
          style={{ height: showDomicilio ? "15rem" : "25rem" }}
        >
          {pedido.partidas
            .sort((a, b) =>
              a.producto.descripcion
                .toLowerCase()
                .localeCompare(b.producto.descripcion.toLowerCase())
            )
            .map((partida) => (
              <div key={partida.id} id="entregarPedido-Partida">
                <div id="entregarPedido-Partida-Producto">
                  <div id="entregarPedido-Partida-Image">
                    <img
                      src={partida.producto.imagenUrl}
                      alt={partida.producto.descripcion}
                    />
                  </div>

                  <div>
                    <p id="entregarPedido-Partida-Descripcion">
                      {partida.producto.descripcion}
                    </p>
                    {partida.notas && (
                      <p>
                        <i>{partida.notas}</i>
                      </p>
                    )}
                    <div id="entregarPedido-Partida-CantidadImporte">
                      <div id="entregarPedido-Partida-CantidadSolicitada">
                        <div>
                          <strong>Solicitado</strong>
                        </div>
                        <div>
                          {partida.cantidad} {partida.producto.unidad}
                        </div>
                      </div>
                      <div id="entregarPedido-Partida-CantidadSolicitada">
                        <div>
                          <strong>Surtido</strong>
                        </div>
                        <div
                          className={
                            partida.cantidad > partida.cantidadSurtida
                              ? "color-rojo"
                              : partida.cantidad < partida.cantidadSurtida
                              ? "color-diferencia"
                              : "color-ok"
                          }
                        >
                          <strong>
                            {partida.cantidadSurtida} {partida.producto.unidad}
                          </strong>
                        </div>
                      </div>
                    </div>
                    <div id="entregarPedido-Partida-Entregado-Importe">
                      <div id="entregarPedido-Partida-Entregado">
                        <Button
                          onClick={() =>
                            cambiarCantidad(
                              partida,
                              (
                                partida.cantidadEntregada -
                                partida.producto.intervalo
                              ).toFixed(2)
                            )
                          }
                        >
                          -
                        </Button>
                        <Form>
                          <Form.Group>
                            <Form.Control
                              type="number"
                              pattern="[0-9]*"
                              inputMode="decimal"
                              value={partida.cantidadEntregada}
                              onChange={(e) =>
                                cambiarCantidad(partida, e.currentTarget.value)
                              }
                            ></Form.Control>
                          </Form.Group>
                        </Form>
                        <Button
                          onClick={() =>
                            cambiarCantidad(
                              partida,
                              (
                                partida.cantidadEntregada +
                                partida.producto.intervalo
                              ).toFixed(2)
                            )
                          }
                        >
                          +
                        </Button>
                      </div>
                      <p id="entregarPedido-Partida-Importe">
                        {"$ "}
                        <NumberFormat
                          value={partida.importeEntregado}
                          displayType="text"
                          decimalScale="2"
                          fixedDecimalScale="2"
                          thousandSeparator
                        />
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            ))}
        </div>

        <div>
          <hr />
          <div>
            <div className="flex flex-end">
              <div>SubTotal:</div>
              <div>
                {"$ "}
                <strong>
                  <NumberFormat
                    value={pedido.subTotalEntregado}
                    displayType="text"
                    decimalScale="2"
                    fixedDecimalScale="2"
                    thousandSeparator
                  />
                </strong>
              </div>
            </div>
            <div className="flex flex-end">
              <div>Gastos de envio: </div>
              <div>
                <strong>
                  <NumberFormat
                    value={pedido.gastosEnvio}
                    displayType="text"
                    decimalScale="2"
                    fixedDecimalScale="2"
                    thousandSeparator
                  />
                </strong>
              </div>
            </div>
            <div className="flex flex-end">
              <div>Total:</div>
              <div>
                {"$ "}
                <strong>
                  <NumberFormat
                    value={pedido.totalEntregado}
                    displayType="text"
                    decimalScale="2"
                    fixedDecimalScale="2"
                    thousandSeparator
                  />
                </strong>
              </div>
            </div>
          </div>

          <div>
            <hr />
            <EntregarPedidoPagos
              pedido={pedido}
              onPedidoChanged={onRutaChanged}
            />
          </div>
          <div className="mb-2">
            <Form.Control
              as="textarea"
              placeholder="Notas de entrega"
              value={pedido.notasEntrega}
              onChange={(e) => {
                pedido.notasEntrega = e.currentTarget.value;
                onRutaChanged();
              }}
            />
          </div>
          <div>
            {error && <Alert variant="danger">{error}</Alert>}
            <Button
              variant="primary"
              block
              disabled={saving}
              onClick={() => guardar()}
            >
              Guardar
              {saving && <Spinner size="sm" animation="border" role="status" />}
            </Button>
          </div>
          {porPagar && (
            <Modal
              show={true}
              onHide={() => setPorPagar()}
              aria-labelledby="contained-modal-title-vcenter"
            >
              <Modal.Header closeButton style={{ backgroundColor: "#f59e42" }}>
                <Modal.Title>
                  <p>Pedido por cobrar</p>
                </Modal.Title>
              </Modal.Header>
              <Modal.Body style={{ backgroundColor: "#f2d4b3" }}>
                <p>
                  El pedido tiene pendiente por pagar un monto de ${" "}
                  <NumberFormat
                    value={porPagar}
                    displayType="text"
                    decimalScale="2"
                    fixedDecimalScale="2"
                    thousandSeparator
                  />
                </p>
                <p>¿ Desea dejar la Cuenta por Cobrar ?</p>
              </Modal.Body>
              <Modal.Footer style={{ backgroundColor: "#f2d4b3" }}>
                <Button
                  size="lg"
                  variant="secondary"
                  onClick={() => {
                    setPorPagar();
                    completarGuardar();
                  }}
                >
                  Si
                </Button>
                <Button
                  size="lg"
                  onClick={() => {
                    setSaving(false);
                    setPorPagar();
                  }}
                >
                  No
                </Button>
              </Modal.Footer>
            </Modal>
          )}
        </div>
      </Modal.Body>
    </Modal>
  );
};

export default EntregarPedido;

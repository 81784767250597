import React, { useState, useEffect } from "react";
import GoogleMapReact from "google-map-react";

import { GetRouteColor } from "./rutasHelpers";
import "./simple-marker.css";

export const Marker = ({ pedido, text, color, onClick }) => (
  <div onClick={(e) => onClick && onClick(pedido)}>
    <div className="pin" style={{ backgroundColor: color }}></div>
    <div className="pintext">{text}</div>
  </div>
);

//https://www.npmjs.com/package/google-map-react
const RutaMapa = ({
  pedidos,
  selectedRuta,
  onPedidoSetRuta,
  setColorOnEstatus,
  height,
  width,
  onMapClick,
  center,
  zoom,
  children,
}) => {
  const defaultProps = {
    center: {
      lat: 20.65224273158634,
      lng: -103.37559838449548,
    },
    zoom: 11,
  };

  const [currentCenter, setCurrentCenter] = useState();
  const [currentZoom, setCurrentZoom] = useState();
  useEffect(() => {
    if (center) setCurrentCenter(center);
    else setCurrentCenter(defaultProps.center);

    if (zoom) setCurrentZoom(zoom);
    else setCurrentZoom(defaultProps.zoom);
  }, [center, zoom]);

  const setPinColor = (pedido) => {
    if (setColorOnEstatus && pedido.estatus === 4) return "#1df056";
    return GetRouteColor(pedido.rutaEnvio);
  };
  return (
    <div style={{ height: height, width: width }}>
      <GoogleMapReact
        bootstrapURLKeys={{ key: process.env.REACT_APP_GOOGLE_API_KEY }}
        defaultCenter={defaultProps.center}
        defaultZoom={defaultProps.zoom}
        center={currentCenter}
        zoom={currentZoom}
        onClick={(e) => {
          if (onMapClick) onMapClick(e);
        }}
      >
        <Marker
          lat={defaultProps.center.lat}
          lng={defaultProps.center.lng}
          color={"#ffb700"}
          text="TAMP"
        />
        {pedidos &&
          pedidos.map((pedido) => (
            <Marker
              key={pedido.id}
              lat={pedido.latitude}
              lng={pedido.longitude}
              text={pedido.id}
              pedido={pedido}
              color={setPinColor(pedido)} // Take color from order's route
              onClick={(e) => {
                if (selectedRuta) onPedidoSetRuta(e, selectedRuta);
              }}
            ></Marker>
          ))}
        {children}
      </GoogleMapReact>
    </div>
  );
};

export default RutaMapa;

import React, { useState, useEffect } from "react";
import { Backdrop, CircularProgress } from "@material-ui/core";
import "./existencias.css";
import { GetExistencias } from "../../../services/backoffice/inventariosServices";
import SearchInput from "../../../common/utils/searchInput";

const Existencias = () => {
  const [existencias, setExistencias] = useState([]);
  const [soloConExistencia, setSoloConExistencia] = useState(true);
  const [items, setItems] = useState([]);
  const [search, setSearch] = useState("");

  const [loading, setLoading] = useState(true);

  const cargarExistencias = async () => {
    setLoading(true);
    try {
      let e = await GetExistencias();
      setExistencias(e);
      filtrarItems(soloConExistencia, e);
    } catch (error) {
      let message;
      if (error.response && error.response.data) {
        message = error.response.data;
        console.log("Error al aplicar cargar las existencias", error.response);
      } else {
        message = error;
        console.log("Error al cargar las existencias", error);
      }

      alert(message);
    } finally {
      setLoading(false);
    }
  };
  useEffect(() => {
    cargarExistencias();
  }, []);

  const cambiarFiltroExistencias = (filtrarSoloConExistencia) => {
    setSoloConExistencia(filtrarSoloConExistencia);
    filtrarItems(filtrarSoloConExistencia, existencias);
  };

  const filtrarItems = (filtrarSoloConExistencia, source) => {
    setSoloConExistencia(filtrarSoloConExistencia);
    let itms;
    if (filtrarSoloConExistencia)
      itms = source.filter((x) => x.existencia !== 0);
    else itms = [...source];
    setItems(itms);
  };

  return (
    <div>
      <div className="backoffice-header fixed-header existencias-header_container">
        <div className="existencias-header">
          <h1>Existencias</h1>
        </div>
        <div className="existencias-filters__div">
          <SearchInput value={search} onChange={(text) => setSearch(text)} />
          <div className="existencias-filter-existencia">
            <div
              className={
                soloConExistencia
                  ? "existencias-filter-existencia__selected"
                  : ""
              }
              onClick={() => cambiarFiltroExistencias(true)}
            >
              Solo con existencia
            </div>
            <div
              className={
                !soloConExistencia
                  ? "existencias-filter-existencia__selected"
                  : ""
              }
              onClick={() => cambiarFiltroExistencias(false)}
            >
              Todos
            </div>
          </div>
        </div>
        <div className="existencias-grid existencias-lista__header">
          <div>Descripción</div>
          <div>Existencia</div>
          <div>Unidad</div>
        </div>
      </div>
      <div className="existencias-lista">
        {items
          .filter((x) =>
            x.descripcion.toLowerCase().includes(search.toLowerCase())
          )
          .map((e) => {
            return (
              <div
                key={e.productoId}
                className="existencias-grid existencias-lista__item"
              >
                <div>{e.descripcion}</div>
                <div style={{ textAlign: "right", marginRight: "1rem" }}>
                  {e.existencia}
                </div>
                <div>{e.unidad}</div>
              </div>
            );
          })}
      </div>
      <Backdrop open={loading} style={{ zIndex: "999" }}>
        <CircularProgress color="inherit" />
      </Backdrop>
    </div>
  );
};

export default Existencias;

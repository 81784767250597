import React, { useEffect, useState } from "react";
import NumberFormat from "react-number-format";
import {
  CambiarPedidoCheckLiquidacion,
  PutPedido,
} from "../../../../services/backofficeService";

const LiquidarRutaPedido = ({ pedido, onPedidoClicked, onPedidoChanged }) => {
  const [pagos, setPagos] = useState([
    { forma: "Efectivo", monto: 0 },
    { forma: "", monto: 0 },
    { forma: "", monto: 0 },
  ]);

  const [check, setCheck] = useState(false);

  useEffect(() => {
    let arrPagos = [...pagos];

    let propinasIncluidas = false;
    let fpIndex = 1;
    pedido.pagos
      .sort((a, b) => (a.formaPagoId > b.formaPagoId ? 1 : -1))
      .forEach((p) => {
        if (p.formaPago.efectivo) {
          arrPagos[0].monto += p.monto;
        } else {
          arrPagos[fpIndex].monto += p.monto;
          if (!propinasIncluidas) {
            arrPagos[fpIndex].monto += pedido.propinaTarjeta;
            propinasIncluidas = true;
          }
          if (arrPagos[fpIndex].forma === "") {
            arrPagos[fpIndex].forma = p.formaPago.nombre;
            fpIndex++;
          }
        }
      });

    setPagos(arrPagos);

    setCheck(pedido.checkLiquidacion);
  }, []);
  return (
    <div
      className={
        "liqPedido" + (pedido.estatus === 4 ? "" : " liqPedido-NoEntregado")
      }
    >
      <div className="liqPedido-heading">
        <a
          href="/"
          onClick={(e) => {
            e.preventDefault();
            onPedidoClicked(pedido);
          }}
        >
          {pedido.id} {pedido.nombre}
        </a>
        <input
          type="checkbox"
          checked={check}
          disabled={pedido.estatus !== 4}
          onChange={(e) => {
            try {
              pedido.checkLiquidacion = !pedido.checkLiquidacion;
              CambiarPedidoCheckLiquidacion(pedido.id, pedido.checkLiquidacion);
              setCheck(pedido.checkLiquidacion);
            } catch (error) {
              alert(error);
            }
          }}
        />
      </div>
      <div className="liqPedido-grid">
        <p className="liqPedido-titulo">Total</p>
        {pedido.estatus === 4 && <p className="liqPedido-titulo">Pagos</p>}
        {pedido.estatus === 4 && <p className="liqPedido-titulo">Saldo</p>}

        <p className="liqPedido-monto">
          <NumberFormat
            value={pedido.estatus === 4 ? pedido.totalEntregado : pedido.total}
            displayType="text"
            decimalScale="2"
            fixedDecimalScale="2"
            thousandSeparator
          />
        </p>

        <p className="liqPedido-monto">
          {pedido.estatus === 4 && (
            <NumberFormat
              value={pedido.totalPagos}
              displayType="text"
              decimalScale="2"
              fixedDecimalScale="2"
              thousandSeparator
            />
          )}
        </p>
        <p
          className={
            "liqPedido-monto" +
            (pedido.totalEntregado - pedido.totalPagos > 1 ? " monto-cxc" : "")
          }
        >
          {pedido.estatus === 4 && (
            <NumberFormat
              value={pedido.totalEntregado - pedido.totalPagos}
              displayType="text"
              decimalScale="2"
              fixedDecimalScale="2"
              thousandSeparator
            />
          )}
        </p>

        {pedido.estatus === 4 && (
          <p className="liqPedido-titulo">{pagos[0].forma}</p>
        )}
        {pedido.estatus === 4 && (
          <p className="liqPedido-titulo">{pagos[1].forma}</p>
        )}
        {pedido.estatus === 4 && (
          <p className="liqPedido-titulo">{pagos[2].forma}</p>
        )}

        <p className="liqPedido-monto">
          {pedido.estatus === 4 && pagos[0].forma && (
            <NumberFormat
              value={pagos[0].monto}
              displayType="text"
              decimalScale="2"
              fixedDecimalScale="2"
              thousandSeparator
            />
          )}
        </p>
        <p className="liqPedido-monto">
          {pedido.estatus === 4 && pagos[1].forma && (
            <NumberFormat
              value={pagos[1].monto}
              displayType="text"
              decimalScale="2"
              fixedDecimalScale="2"
              thousandSeparator
            />
          )}
        </p>
        <p className="liqPedido-monto">
          {pedido.estatus === 4 && pagos[2].forma && (
            <NumberFormat
              value={pagos[2].monto}
              displayType="text"
              decimalScale="2"
              fixedDecimalScale="2"
              thousandSeparator
            />
          )}
        </p>

        {pedido.estatus === 4 && pedido.propinaTarjeta > 0 && (
          <p className="liqPedido-titulo">Propinas:</p>
        )}
        <p className="liqPedido-monto">
          {pedido.estatus === 4 && pedido.propinaTarjeta > 0 && (
            <NumberFormat
              value={pedido.propinaTarjeta}
              displayType="text"
              decimalScale="2"
              fixedDecimalScale="2"
              thousandSeparator
            />
          )}
        </p>

        {pedido.estatus === 4 && (
          <div>
            {" "}
            <p className="liqPedido-titulo">Costo envío</p>
            <p className="liqPedido-monto">
              <a
                href="/"
                onClick={(e) => {
                  e.preventDefault();
                  let valor = window.prompt(
                    "Costo de envío",
                    pedido.costoEnvio
                  );
                  if (!valor) return;
                  pedido.costoEnvio = valor;
                  PutPedido(pedido, false);
                  onPedidoChanged(pedido);
                }}
              >
                <NumberFormat
                  value={pedido.costoEnvio}
                  displayType="text"
                  decimalScale="2"
                  fixedDecimalScale="2"
                  thousandSeparator
                />
              </a>
            </p>
          </div>
        )}
      </div>
    </div>
  );
};

export default LiquidarRutaPedido;

import { moment } from "moment";
import { Logout } from "../../services/backofficeService";

export function GetSessionId() {
  let sessionId = localStorage.sessionId;
  if (sessionId === "undefined") {
    SetSessionId(null);
    return null;
  }
  return sessionId;
}

export function SetSessionId(sessionId) {
  if (sessionId === "undefined") localStorage.sessionId = null;
  else localStorage.sessionId = sessionId;
}

export function DeleteSession() {
  localStorage.removeItem("sessionId");
}

export function GetBackofficeUserSession() {
  let backofficeUserSessionId = sessionStorage.backofficeUserSessionId;
  let backofficeUserSessionExpires =
    sessionStorage.backofficeUserSessionExpires;

  let expires = Date.now();
  if (backofficeUserSessionExpires)
    expires = new Date(backofficeUserSessionExpires);

  if (backofficeUserSessionId && expires > Date.now())
    return {
      id: backofficeUserSessionId,
      expires: backofficeUserSessionExpires,
    };

  return null;
}

export function SetBackofficeUserSession(session) {
  sessionStorage.backofficeUserSessionId = session.id;
  sessionStorage.backofficeUserSessionExpires = session.expires;
}

export async function LogoutBackofficeUserSession(sessionId) {
  sessionStorage.removeItem("backofficeUserSessionId");
  sessionStorage.removeItem("backofficeUserSessionExpires");
  await Logout(sessionId);
}

import React from "react";
import { CircularProgress } from "@material-ui/core";

const BackofficeBanner = (props) => {
  return (
    <div className="alert alert-secondary">
      <h6>
        {props.showProgress && <CircularProgress color="secondary" />}
        {props.title}
      </h6>
      {props.children}
    </div>
  );
};

export default BackofficeBanner;

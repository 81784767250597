import React from "react";
import NumberFormat from "react-number-format";

const LiquidarRutaTotales = ({ totales, ruta }) => {
  console.log(ruta);
  return (
    <div className="liqPedidoTotales">
      <div className="liqPedidoTotales-grid">
        <div>
          <p className="liqPedidoTotales-titulo">
            ({totales.countPedidos}) Pedidos:
          </p>
          <p className="liqPedidoTotales-monto">
            <NumberFormat
              value={totales.totalPedidos}
              displayType="text"
              decimalScale="2"
              fixedDecimalScale="2"
              thousandSeparator
            />
          </p>
        </div>
        <div>
          <p className="liqPedidoTotales-titulo">
            ({totales.countEntregados}) Entregado:
          </p>
          <p className="liqPedidoTotales-monto">
            <NumberFormat
              value={totales.totalEntregado}
              displayType="text"
              decimalScale="2"
              fixedDecimalScale="2"
              thousandSeparator
            />
          </p>
        </div>
        <div>
          <p className="liqPedidoTotales-titulo">Pagos:</p>
          <p className="liqPedidoTotales-monto">
            <NumberFormat
              value={totales.totalPagos}
              displayType="text"
              decimalScale="2"
              fixedDecimalScale="2"
              thousandSeparator
            />
          </p>
        </div>
        <div>
          <p className="liqPedidoTotales-titulo">
            ({totales.countPorCobrar}) por Cobrar:
          </p>
          <p className="liqPedidoTotales-monto">
            <NumberFormat
              value={totales.totalPorCobrar}
              displayType="text"
              decimalScale="2"
              fixedDecimalScale="2"
              thousandSeparator
            />
          </p>
        </div>
      </div>

      <p className="liqPedidoTotales-subTitulo">Pagos</p>

      <div className="liqPedidoTotales-grid">
        {totales.pagos.map((pago) => (
          <div key={pago.id}>
            <p className="liqPedidoTotales-titulo">{pago.nombre}:</p>
            <p className="liqPedidoTotales-monto">
              <NumberFormat
                value={pago.monto}
                displayType="text"
                decimalScale="2"
                fixedDecimalScale="2"
                thousandSeparator
              />
            </p>
          </div>
        ))}
        <div>
          <p className="liqPedidoTotales-titulo">
            ({totales.countPagosNoEfectivo}) Total tarjeta y vales:
          </p>
          <p className="liqPedidoTotales-monto">
            <NumberFormat
              value={totales.totalPagosNoEfectivo}
              displayType="text"
              decimalScale="2"
              fixedDecimalScale="2"
              thousandSeparator
            />
          </p>
        </div>
        <div>
          <p className="liqPedidoTotales-titulo">Propinas:</p>
          <p className="liqPedidoTotales-monto">
            <NumberFormat
              value={totales.totalPropinas}
              displayType="text"
              decimalScale="2"
              fixedDecimalScale="2"
              thousandSeparator
            />
          </p>
        </div>

        <p className="liqPedidoTotales-subTitulo">Inventario</p>
        <div>
          <div className="liqPedidoTotales-titulo">Cajas</div>
          <div className="liqPedidoTotales-monto">{ruta.cajas}</div>
        </div>
        <div>
          <div className="liqPedidoTotales-titulo">Hieleras</div>
          <div className="liqPedidoTotales-monto">{ruta.hieleras}</div>
        </div>
        <div>
          <div className="liqPedidoTotales-titulo">Terminal</div>
          <div className="liqPedidoTotales-monto">{ruta.serieTerminal}</div>
        </div>
      </div>
    </div>
  );
};

export default LiquidarRutaTotales;

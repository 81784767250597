import React, { Component } from "react";
import CheckoutItem from "./checkoutItem";
class CheckoutItemsList extends Component {
  state = {};
  render() {
    const { pedido } = this.props;
    if (!pedido.partidas) return "";

    return (
      <div>
        {pedido.partidas.length === 0 && (
          <h1>No ha agregado productos a su carrito.</h1>
        )}
        {pedido.partidas.map((partida) => {
          return (
            <CheckoutItem
              key={partida.id}
              partida={partida}
              onCantidadChanged={this.props.onCantidadChanged}
              onNotasChanged={this.props.onNotasChanged}
            />
          );
        })}
      </div>
    );
  }
}

export default CheckoutItemsList;

export const ValidarRuta = (numero, nombre, choferId) => {
  if (!numero || numero == "" || numero <= 0)
    return "Indique el numero de ruta";

  if (!nombre || nombre == "") return "Indique el nombre de la ruta";

  console.log(choferId);

  if (!choferId || choferId <= 0) return "Seleccione al chofer";

  return null;
};

const RoutesColor = [
  "#1f62ff",
  "#8a1cff",
  "#ff1ce5",
  "#1cfbff",
  "#1cffa4",
  "#1cff2b",
  "#ffdd1c",
  "#aabd4a",
  "#186163",
  "#3f466e",
];

export function GetRouteColor(ruta) {
  if (!ruta) return "#FF0000";
  return RoutesColor[ruta.numero];
}

import React from "react";
import { KeyboardDatePicker } from "@material-ui/pickers";

const InputDate = ({ id, label, value, onChange }) => {
  return (
    <KeyboardDatePicker
      disableToolbar
      variant="modal"
      format="MM/DD/yyyy"
      margin="normal"
      id={id}
      label={label}
      invalidDateMessage="Fecha inválida"
      okLabel="Aceptar"
      cancelLabel="Cancelar"
      todayLabel="Hoy"
      KeyboardButtonProps={{
        "aria-label": "change date",
      }}
      value={value}
      onChange={onChange}
    />
  );
};

export default InputDate;

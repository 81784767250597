import React, { Component } from "react";
import { Colonias } from "../../services/codigosPostalesService";

class CodigosPostalesCombo extends Component {
  state = { ultimoCodigoPostal: "", colonias: [] };

  componentDidUpdate() {
    if (this.props.codigoPostal !== this.state.ultimoCodigoPostal) {
      const codigoPostal = this.props.codigoPostal;
      if (!codigoPostal || codigoPostal.length !== 5) {
        if (this.state.colonias.length > 0) {
          this.setState({ colonias: [], ultimoCodigoPostal: "" });
          if (this.props.onChange) {
            this.props.onChange({
              currentTarget: { name: this.props.name, value: "" },
            });
            this.props.onChange({
              currentTarget: { name: "municipio", value: "" },
            });
          }
        }
      } else if (codigoPostal !== this.state.ultimoCodigoPostal)
        Colonias(codigoPostal).then((res) => {
          this.setState({
            colonias: res,
            ultimoCodigoPostal: codigoPostal,
          });
          if (this.props.onChange) {
            if (res.length > 0) {
              // Si ya tiene colonia y corresponde al código postal, la toma
              const coloniaItem = res.find(
                (c) => c.colonia === this.props.colonia
              );
              let colonia = res[0].colonia;
              if (coloniaItem) colonia = this.props.colonia;

              this.props.onChange({
                currentTarget: { name: this.props.name, value: colonia },
              });
              this.props.onChange({
                currentTarget: { name: "municipio", value: res[0].municipio },
              });
            } else {
              if (this.props.onError) {
                this.props.onError(
                  "El código postal" +
                    codigoPostal +
                    " está fuera del área de cobertura"
                );
              }
            }
          }
        });
    }
  }
  render() {
    return (
      <select className="selects"
        id="colonia"
        label="Colonia"
        value={this.props.colonia == null ? "" : this.props.colonia}
        type="select"
        name={this.props.name}
        onChange={this.props.onChange}
        errors={this.props.errors}
        placeholder="Escriba el código postal"
      >
        {this.state.colonias.length === 0 && (
          <option>Escriba el código postal</option>
        )}
        {this.state.colonias.map((colonia) => (
          <option key={colonia.colonia} value={colonia.colonia}>
            {colonia.colonia}
          </option>
        ))}
      </select>
    );
  }
}

export default CodigosPostalesCombo;

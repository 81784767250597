import Axios from "axios";
//TODO: Authetntication
export default class webAPIHelper {
  async Get(endPoint) {
    // Para poder invocar sitios sin SSL valido (development)
    process.env.NODE_TLS_REJECT_UNAUTHORIZED = "0";
    const result = await Axios.get(this.BaseUrl() + endPoint);
    return result.data;
  }

  async GetBlob(endPoint) {
    // Para poder invocar sitios sin SSL valido (development)
    process.env.NODE_TLS_REJECT_UNAUTHORIZED = "0";
    const result = await Axios.get(this.BaseUrl() + endPoint, {
      responseType: "blob",
    });
    return result.data;
  }
  async Post(endPoint, requestBody, config) {
    // Para poder invocar sitios sin SSL valido (development)
    process.env.NODE_TLS_REJECT_UNAUTHORIZED = "0";
    const result = await Axios.post(
      this.BaseUrl() + endPoint,
      requestBody,
      config
    );
    return result.data;
  }

  async Put(endPoint, requestBody) {
    // Para poder invocar sitios sin SSL valido (development)
    process.env.NODE_TLS_REJECT_UNAUTHORIZED = "0";
    const result = await Axios.put(this.BaseUrl() + endPoint, requestBody);
    return result.data;
  }

  async Patch(endPoint, requestBody) {
    // Para poder invocar sitios sin SSL valido (development)
    process.env.NODE_TLS_REJECT_UNAUTHORIZED = "0";
    const result = await Axios.patch(this.BaseUrl() + endPoint, requestBody);
    return result.data;
  }

  async Delete(endPoint, requestBody) {
    // Para poder invocar sitios sin SSL valido (development)
    process.env.NODE_TLS_REJECT_UNAUTHORIZED = "0";
    const result = await Axios.delete(this.BaseUrl() + endPoint, {
      data: requestBody,
    });
    return result.data;
  }

  BaseUrl() {
    if (process.env.NODE_ENV === "development")
      return process.env.REACT_APP_WEBAPI_URL_Development;
    else return process.env.REACT_APP_WEBAPI_URL;
  }
}

import React, { useState, useEffect } from "react";
import moment from "moment";
import { useHistory } from "react-router";

import { Card, Button, Tabs, Tab } from "react-bootstrap";
import { Pencil, PlayBtn, Plus, Search } from "react-bootstrap-icons";
import { Backdrop, CircularProgress } from "@material-ui/core";
import "./inventariofisico.css";
import {
  CargarListaInventariosFisicos,
  AplicarInventarioFisico,
} from "../../../services/backoffice/inventariosServices";
import { TienePermiso } from "../../../services/backofficeService";

const InventariosFisicos = ({ sessionId }) => {
  const [inventariosFisicos, setInventariosFisicos] = useState([]);
  const [loading, setLoading] = useState(true);
  const [filterStatus, setFilterStatus] = useState("0");
  useEffect(() => {
    cargarInventariosFisicos();
  }, []);

  //TODO: Validar acceso

  const history = useHistory();

  const cargarInventariosFisicos = async () => {
    setLoading(true);
    try {
      let r = await CargarListaInventariosFisicos();
      setInventariosFisicos(r);
    } catch (error) {
      alert(error);
    }
    setLoading(false);
  };

  const aplicarInventario = async (inventarioFisicoId) => {
    // Valida que tenga el permiso
    var respuesta = await TienePermiso(
      sessionId,
      "Inventarios/InventarioFisico/Aplicar"
    );
    if (respuesta !== "") {
      alert(respuesta);
      return;
    }

    // Pide confirmacion al usuario
    if (
      !window.confirm(
        `¿ Está seguro que desea aplicar el levantamiento ${inventarioFisicoId} ?`
      )
    )
      return;

    setLoading(true);
    try {
      await AplicarInventarioFisico(inventarioFisicoId);
      alert(`Se ha aplicado el inventario ${inventarioFisicoId}`);
      cargarInventariosFisicos();
    } catch (error) {
      let message;
      if (error.response && error.response.data) {
        message = error.response.data;
        console.log("Error al aplicar inventario fisico", error.response);
      } else {
        message = error;
        console.log("Error al aplicar inventario fisico", error);
      }

      alert(message);
    }
    setLoading(false);
  };

  return (
    <div>
      <div className="backoffice-header fixed-header inventarios-fisicos-header_container">
        <div className="inventarios-fisicos-header">
          <h1>Inventarios físicos</h1>
          <Button
            onClick={() =>
              history.push("/backoffice/inventario/inventariofisico")
            }
          >
            <Plus color="white" />
            Agregar
          </Button>
        </div>
        <div className="inventarios-fisicos-filter">
          <Tabs
            id="fiter-tab"
            activeKey={filterStatus}
            onSelect={(k) => setFilterStatus(k)}
          >
            <Tab eventKey="0" title="por Aplicar" />
            <Tab eventKey="1" title="Aplicados" />
          </Tabs>
        </div>
      </div>
      <div className="lista-inventarios-fisicos">
        {inventariosFisicos
          .filter((i) => i.estatus == filterStatus)
          .map((inventarioFisico) => {
            return (
              <Card key={inventarioFisico.id}>
                <Card.Body>
                  <div className="lista-inventarios-fisicos_body">
                    <div className="lista-inventarios-fisicos__fecha">
                      <div>
                        Id:<strong>{inventarioFisico.id}</strong>
                      </div>
                      {inventarioFisico.fechaAplicacion !==
                        "0001-01-01T00:00:00" &&
                        moment(inventarioFisico.fechaAplicacion).format(
                          "DD/MM/YY HH:mm"
                        )}
                      {inventarioFisico.fechaAplicacion ===
                        "0001-01-01T00:00:00" &&
                        moment(inventarioFisico.fecha).format("DD/MM/YY HH:mm")}
                    </div>
                    <div className="lista-inventarios-fisicos__buttons">
                      <Button
                        size="lg"
                        onClick={() =>
                          history.push(
                            `/backoffice/inventario/inventariofisico/${inventarioFisico.id}`
                          )
                        }
                      >
                        {inventarioFisico.estatus == "0" ? (
                          <Pencil color="white" />
                        ) : (
                          <Search color="white" />
                        )}
                      </Button>
                      {inventarioFisico.estatus == "0" && (
                        <Button
                          variant="warning"
                          size="lg"
                          onClick={() => aplicarInventario(inventarioFisico.id)}
                        >
                          <PlayBtn />
                        </Button>
                      )}
                    </div>
                  </div>
                </Card.Body>
              </Card>
            );
          })}
      </div>
      <Backdrop open={loading} style={{ zIndex: "999" }}>
        <CircularProgress color="inherit" />
      </Backdrop>
    </div>
  );
};

export default InventariosFisicos;

import React from "react";

import { Modal } from "react-bootstrap";
import { Button, TextField, Checkbox } from "@material-ui/core";

import InputCheckBox from "../../common/utils/inputCheckbox";
import { PostProveedor, PutProveedor } from "../../services/backofficeService";

const UpdateProveedor = ({
  proveedor,
  editAction,
  onChange,
  onHide,
  onSave,
}) => {
  if (!proveedor || !editAction) return <span />;
  return (
    <Modal show={true} onHide={() => onHide()}>
      <Modal.Header closeButton>
        <Modal.Title>
          {editAction === "add" ? "Nuevo " : "Editar "} proveedor
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div>
          <TextField
            label="Nombre"
            value={proveedor.nombre}
            required
            fullWidth
            onChange={(e) => {
              proveedor.nombre = e.currentTarget.value;
              onChange();
            }}
          />
          <TextField
            label="eMail ventas"
            value={proveedor.eMailVentas}
            onChange={(e) => {
              proveedor.eMailVentas = e.currentTarget.value;
              onChange();
            }}
            fullWidth
          />
          <TextField
            label="Teléfono ventas"
            value={proveedor.telefonoVentas}
            onChange={(e) => {
              proveedor.telefonoVentas = e.currentTarget.value;
              onChange();
            }}
            required
            fullWidth
          />

          <hr />
          <TextField
            fullWidth
            label="Domicilio"
            value={proveedor.domicilio}
            onChange={(e) => {
              proveedor.domicilio = e.currentTarget.value;
              onChange();
            }}
          />
          <TextField
            fullWidth
            label="Colonia"
            value={proveedor.colonia}
            onChange={(e) => {
              proveedor.colonia = e.currentTarget.value;
              onChange();
            }}
          />
          <TextField
            fullWidth
            label="Municipio"
            value={proveedor.municipio}
            onChange={(e) => {
              proveedor.municipio = e.currentTarget.value;
              onChange();
            }}
          />
          <TextField
            fullWidth
            label="C.P."
            value={proveedor.codigoPostal}
            onChange={(e) => {
              proveedor.codigoPostal = e.currentTarget.value;
              onChange();
            }}
          />
          <TextField
            fullWidth
            label="Entre calles"
            value={proveedor.entreCalle1}
            onChange={(e) => {
              proveedor.entreCalle1 = e.currentTarget.value;
              onChange();
            }}
          />
          <TextField
            fullWidth
            label="Referencias"
            value={proveedor.referencias}
            onChange={(e) => {
              proveedor.referencias = e.currentTarget.value;
              onChange();
            }}
          />
          <TextField
            fullWidth
            multiline
            rowsMax={4}
            label="Notas"
            value={proveedor.notas}
            onChange={(e) => {
              proveedor.notas = e.currentTarget.value;
              onChange();
            }}
          />
          <TextField
            fullWidth
            multiline
            rowsMax={4}
            label="Notas orden compra"
            value={proveedor.notasOrdenCompra}
            onChange={(e) => {
              proveedor.notasOrdenCompra = e.currentTarget.value;
              onChange();
            }}
          />

          <hr />
          <div>
            <InputCheckBox
              name="deshabilitado"
              label="Deshabilitado"
              checked={proveedor.deshabilitado}
              onChange={({ currentTarget: input }) => {
                proveedor.deshabilitado = input.checked;
                onChange();
              }}
            />
          </div>
          <div>
            <InputCheckBox
              name="desglosarReporte"
              label="Desglosar OC"
              checked={proveedor.desglosarReporte}
              onChange={({ currentTarget: input }) => {
                proveedor.desglosarReporte = input.checked;
                onChange();
              }}
            />
          </div>
        </div>
      </Modal.Body>
      <Modal.Footer>
        <Button
          variant="contained"
          color="primary"
          onClick={async () => {
            try {
              if (editAction === "add") await PostProveedor(proveedor);
              if (editAction === "edit") await PutProveedor(proveedor);
            } catch (error) {
              alert("Error al guardar los cambios");
              return;
            }
            onSave();
          }}
        >
          Guardar
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default UpdateProveedor;

import React from "react";
import AddToCartButton from "../pedido/addToCartButton";

function ProductosPageListItem(props) {
  const {
    productoId,
    imagenUrl,
    descripcion,
    precio,
    unidad,
    cantidad,
    importe,
    intervalo,
    agotado,
  } = props;

  var divStyle = {};
  return (
    <div
      className="productos "
      key={`productosPageListItem_${productoId}`}
      id={`productosPageListItem_${productoId}`}
    >
      <div className="productosInt" id={`productosInt${productoId}`}>
        <div className="productosImgCont ">
          <div
            className={"productosImg " + (agotado ? " producto-agotado" : "")}
            style={divStyle}
          >
            <img
              data-src={imagenUrl}
              id={`productosPageListItemFoto_${productoId}`}
              className="productosImgImg productosImgAparece"
              alt=""
            ></img>
          </div>
        </div>
        <div className="divi"></div>
        <div className={"productosTit" + (agotado ? " producto-agotado" : "")}>
          {descripcion}
        </div>
        <div className="clear5"></div>
        <span className={agotado ? " producto-agotado" : ""}>
          <strong>
            ${precio}
            {" / "}
            {unidad}
          </strong>
        </span>
        <div className="clear10"></div>
        <AddToCartButton
          idProducto={productoId}
          cantidad={cantidad}
          onCantidadChanged={props.onCantidadChanged}
          intervalo={intervalo}
          unidad={unidad}
          deshabilitado={agotado}
          descripcion={descripcion}
        />
        {importe && <span>{importe}</span>}
        <div className="clear20"></div>
      </div>
    </div>
  );
}
export default ProductosPageListItem;

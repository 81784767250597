import webAPIHelper from "../common/utils/webAPIHelper";
import {
  GetSessionId,
  SetSessionId,
  DeleteSession,
} from "../common/utils/sessionHelper";

const controller = "/Pedido";

export async function AgregarAlPedido(idProducto, cantidad, sessionId) {
  const appsvc = new webAPIHelper();
  if (!sessionId) sessionId = GetSessionId();
  const request = {
    idProducto: idProducto,
    cantidad: cantidad,
    sessionId: sessionId,
  };
  const response = await appsvc.Post(controller, request);
  if (response === null) return null;

  SetSessionId(response.sessionId);

  return response;
}

export async function QuitarDelPedido(idProducto, cantidad) {
  const appsvc = new webAPIHelper();
  const request = {
    idProducto: idProducto,
    cantidad: cantidad,
    sessionId: GetSessionId(),
  };
  const response = await appsvc.Delete(controller, request);
  return response;
}

export async function EliminarDelPedido(idProducto, cantidad) {
  const appsvc = new webAPIHelper();
  const request = {
    idProducto: idProducto,
    eliminar: true,
    sessionId: GetSessionId(),
  };
  const response = await appsvc.Delete(controller, request);
  if (response === null) return null;

  return response;
}

export async function CambiarNotasPartida(partidaId, notas) {
  const appsvc = new webAPIHelper();
  const request = {
    sessionId: GetSessionId(),
    partidaId: partidaId,
    notas: notas,
  };
  const response = await appsvc.Put(`${controller}/NotasPartida`, request);
  return response;
}

export async function ContadorItems() {
  let sessionId = GetSessionId();
  if (!sessionId) return 0;
  const appsvc = new webAPIHelper();
  const response = await appsvc.Get(`${controller}/${sessionId}/TotalItems`);

  return response;
}

export async function CargarPedido() {
  let sessionId = GetSessionId();
  if (!sessionId) return null;
  const appsvc = new webAPIHelper();
  const response = await appsvc.Get(`${controller}/${sessionId}`);

  return response;
}

export async function FechaEstimadaEntrega() {
  const appsvc = new webAPIHelper();
  const response = await appsvc.Get(`${controller}/FechaEstimadaEntrega`);
  return response;
}

export async function CerrarPedido(pedido) {
  let sessionId = GetSessionId();
  if (!sessionId) return null;
  const appsvc = new webAPIHelper();
  const request = {
    sessionId: sessionId,
    nombre: pedido.nombre,
    email: pedido.eMail,
    telefono: pedido.telefono,
    calle: pedido.calle,
    noExterior: pedido.noExterior,
    noInterior: pedido.noInterior,
    codigoPostal: pedido.codigoPostal,
    colonia: pedido.colonia,
    municipio: pedido.municipio,
    entreCalle1: pedido.entreCalle1,
    entreCalle2: pedido.entreCalle2,
    referencias: pedido.referencias,
    notas: pedido.notas,
    latitude: pedido.latitude,
    longitude: pedido.longitude,
  };

  const response = await appsvc.Put(controller, request);
  if (response && !response.error) {
    DeleteSession(null);
  }

  return response;
}

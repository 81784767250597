import React, { useEffect, useState } from "react";

import { Button, Backdrop, CircularProgress } from "@material-ui/core";

import BackofficeBanner from "../backofficeBanner";

import {
  ListaProveedores,
  TienePermiso,
} from "../../services/backofficeService";
import UpdateProveedor from "./updateProveedor";

const ProveedoresPage = ({ sessionId }) => {
  const [proveedores, setProveedores] = useState([]);
  const [searchText, setSearchText] = useState("");
  const [proveedor, setProveedor] = useState({});
  const [editAction, setEditAction] = useState();

  const cargarProveedores = async () => {
    var respuesta = await TienePermiso(sessionId, "Catálogos/Proveedores/ABC");
    if (respuesta !== "") {
      alert(respuesta);
      window.location.href = "/backoffice";
      return;
    }

    const listaProveedores = await ListaProveedores();
    listaProveedores.sort((a, b) => (a.nombre > b.nombre ? 1 : -1));
    setProveedores(listaProveedores);
  };

  useEffect(() => {
    cargarProveedores();
  }, []);

  if (!proveedores)
    return (
      <div>
        <Backdrop open={true}>
          <CircularProgress color="inherit" />
        </Backdrop>
      </div>
    );

  return (
    <div className="App">
      <BackofficeBanner title="Proveedores" />
      <div className="container-md mb-2">
        <input
          className="form-control form-control-lg"
          type="text"
          placeholder="Buscar"
          value={searchText}
          onChange={(e) => setSearchText(e.currentTarget.value.toLowerCase())}
        />
      </div>
      <div className="container">
        <Button
          variant="contained"
          color="primary"
          onClick={() => {
            setProveedor({ nombre: "", domicilio: "" });
            setEditAction("add");
          }}
        >
          Agregar proveedor
        </Button>
        <table className="table">
          <thead className="thead-dark">
            <tr>
              <th>Id</th>
              <th>Nombre</th>
              <th></th>
            </tr>
          </thead>
          <tbody>
            {proveedores
              .filter((p) => p.nombre.toLowerCase().includes(searchText))
              .map((proveedor) => (
                <tr key={proveedor.id}>
                  <td className="text-left ">{proveedor.id}</td>
                  <td className="text-left">{proveedor.nombre}</td>
                  <td>
                    <Button
                      variant="contained"
                      color="primary"
                      onClick={() => {
                        setProveedor(proveedor);
                        setEditAction("edit");
                      }}
                    >
                      Editar
                    </Button>
                  </td>
                </tr>
              ))}
          </tbody>
        </table>

        <UpdateProveedor
          proveedor={proveedor}
          editAction={editAction}
          onChange={() => setProveedor({ ...proveedor })}
          onHide={() => setEditAction()}
          onSave={async () => {
            const provs = [...proveedores]; // Create a copy of the array
            const provIndex = provs.findIndex((p) => p.id === proveedor.id);
            provs[provIndex] = proveedor;
            setProveedores(provs);
            setEditAction();
            setProveedor();
          }}
        />
      </div>
    </div>
  );
};

export default ProveedoresPage;

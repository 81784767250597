import React, { useState, useEffect } from "react";
import { Modal, Button, Form, Alert } from "react-bootstrap";
import {
  CrearRuta,
  GetChoferes,
  SiguienteNumeroRuta,
} from "../../../services/backoffice/rutasServices";

import { ValidarRuta } from "./rutasHelpers";

const NuevaRuta = ({ planeacion, onComplete, onHide }) => {
  const [numero, setNumero] = useState(0);
  const [nombre, setNombre] = useState("");
  const [choferes, setChoferes] = useState();
  const [choferId, setChoferId] = useState(0);
  const [error, setError] = useState();

  useEffect(() => {
    const cargasIniciales = async (planeacion) => {
      // Sugiere el siguiente numero de ruta
      const siguienteNumero = await SiguienteNumeroRuta(
        planeacion.fechaEntrega
      );
      setNumero(siguienteNumero);

      // Obtiene la lista de choferes
      const choferes = await GetChoferes();
      setChoferes(choferes);

      if (choferes.length > 0) setChoferId(choferes[0].id);
    };
    cargasIniciales(planeacion);
  }, []);

  async function handleSave() {
    var err = ValidarRuta(numero, nombre, choferId);
    setError(err);
    if (err) return;

    let chofer = choferes.find((c) => c.id == choferId);

    try {
      var ruta = await CrearRuta({
        fecha: planeacion.fechaEntrega,
        numero: numero,
        nombre: nombre,
        choferId: choferId,
      });
      ruta.chofer = chofer;

      onComplete(ruta);
    } catch (exception) {
      alert("Error " + exception);
    }
  }

  if (!choferes) return <h1>Cargando ...</h1>;
  return (
    <Modal show={true} onHide={() => onHide()}>
      <Modal.Header closeButton>
        <Modal.Title>Nueva ruta</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form>
          <Form.Group>
            <Form.Label>Numero</Form.Label>
            <Form.Control
              value={numero}
              type="number"
              onChange={(e) => setNumero(e.currentTarget.value)}
            />
          </Form.Group>
          <Form.Group>
            <Form.Label>Nombre</Form.Label>
            <Form.Control
              type="text"
              value={nombre}
              onChange={(e) => setNombre(e.currentTarget.value)}
            />
          </Form.Group>
          <Form.Group>
            <Form.Label>Chofer</Form.Label>
            <Form.Control
              as="select"
              custom
              value={choferId}
              onChange={(e) => {
                setChoferId(e.currentTarget.value);
              }}
            >
              {choferes.map((chofer) => (
                <option key={chofer.id} value={chofer.id}>
                  {chofer.nombre}
                </option>
              ))}
            </Form.Control>
          </Form.Group>
        </Form>
      </Modal.Body>
      <Modal.Footer style={{ flexDirection: "column" }}>
        {error && (
          <p>
            <Alert variant="danger">{error}</Alert>
          </p>
        )}
        <Button variant="primary" onClick={() => handleSave()}>
          Guardar
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default NuevaRuta;

import React, { useState, useEffect } from "react";
import BackofficeBanner from "../../backofficeBanner";
import { Button } from "react-bootstrap";
import InputDate from "../../../common/utils/inputDate";

import {
  AsignarRutaPedido,
  GetPlaneacion,
} from "../../../services/backoffice/rutasServices";
import { TienePermiso } from "../../../services/backofficeService";
import RutaMapa from "./rutaMapa";

import styles from "./rutas.css";

import PedidosPorAsignar from "./pedidosPorAsignar";
import ListaRutas from "./listaRutas";
import { ArrowRepeat } from "react-bootstrap-icons";
import { FechaEntregaDefault } from "../../../services/backoffice/bakofficeHelpers";

const Rutas = ({ sessionId }) => {
  const [fechaEntrega, setFechaEntrega] = useState();
  const [planeacion, setPlaneacion] = useState();
  const [selectedRuta, setSelectedRuta] = useState();
  const [mapCenter, setMapCenter] = useState(null);
  const [mapZoom, setMapZoom] = useState(null);

  useEffect(() => {
    async function tienePermiso() {
      var respuesta = await TienePermiso(sessionId, "Rutas/ArmarRutas");
      if (respuesta !== "") {
        alert(respuesta);
        window.location.href = "/backoffice";
        return;
      }
    }

    tienePermiso();

    cargarRutas(FechaEntregaDefault());
  }, []);

  const cargarRutas = async (fechaEntrega) => {
    let planeacion = await GetPlaneacion(fechaEntrega);

    setFechaEntrega(fechaEntrega);
    setPlaneacion(planeacion);
    setMapCenter(null);
    setMapZoom(null);
  };

  const asignaRutaPedido = async (pedido, ruta) => {
    try {
      let response = await AsignarRutaPedido(ruta.id, pedido.id);
      let itemIndex = planeacion.pedidos.findIndex((r) => r.id === pedido.id);
      planeacion.pedidos[itemIndex].rutaEnvio = ruta;
      planeacion.pedidos[itemIndex].ordenRuta = response.ordenRuta;
      setPlaneacion({ ...planeacion });
    } catch (error) {
      alert("Error " + error);
    }
  };

  const actualizarPedidoUbicacion = (pedido) => {
    if (pedido) {
      let currentIndex = planeacion.pedidos.findIndex(
        (p) => p.id === pedido.id
      );
      planeacion.pedidos[currentIndex] = pedido;
      setPlaneacion({ ...planeacion });
    } else cargarRutas(fechaEntrega);
  };

  if (!planeacion) return <div></div>;

  return (
    <div className="App">
      <div
        className="alert alert-secondary"
        style={{
          marginBottom: "0",
          display: "flex",
          height: "4.5rem",
          padding: "0",
        }}
      >
        <InputDate
          id="pedidosPorEntregarPage-dateFilter"
          label="Fecha de entrega"
          value={fechaEntrega}
          onChange={async (date) => {
            await cargarRutas(date);
          }}
        />
        <Button onClick={() => cargarRutas(fechaEntrega)}>
          <ArrowRepeat color="white" />
        </Button>
      </div>
      <RutaMapa
        pedidos={planeacion.pedidos}
        selectedRuta={selectedRuta}
        height="30vh"
        width="100%"
        onPedidoSetRuta={(pedido, ruta) => asignaRutaPedido(pedido, ruta)}
        center={mapCenter}
        zoom={mapZoom}
      ></RutaMapa>
      <ListaRutas
        planeacion={planeacion}
        selectedRutaChanged={(ruta) => setSelectedRuta(ruta)}
        onPedidosPorAsignarChanged={() => setPlaneacion({ ...planeacion })}
        onPedidoUbicacionChanged={actualizarPedidoUbicacion}
      ></ListaRutas>
      <hr></hr>
      <PedidosPorAsignar
        planeacion={planeacion}
        onPedidoSetRuta={(pedido, ruta) => asignaRutaPedido(pedido, ruta)}
        onPedidoUbicacionChanged={(pedido) => actualizarPedidoUbicacion(pedido)}
        onCenterMap={(pedido) => {
          setMapCenter({ lat: pedido.latitude, lng: pedido.longitude });
          setMapZoom(16);
        }}
      ></PedidosPorAsignar>
    </div>
  );
};

export default Rutas;

import React from "react";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import { TextField, DialogActions } from "@material-ui/core";
import Slide from "@material-ui/core/Slide";
import { DentroAreaCobertura } from "../../services/codigosPostalesService";
import DentroDelAreaDialog from "./dentroDelAreaDialog";
import FueraDelAreaDialog from "./fueraDelAreaDialog";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="down" ref={ref} {...props} />;
});

const AreaEntregaDialog = (props) => {
  const [codigoPostal, setCodigoPostal] = React.useState("");
  const [
    codigoPostalValidationMessage,
    setcodigoPostalValidationMessage,
  ] = React.useState("");
  const [showDentroDelAreaDialog, setShowDentroDelAreaDialog] = React.useState(
    false
  );
  const [showFueraDelAreaDialog, setShowFueraDelAreaDialog] = React.useState(
    false
  );

  if (!props.open) return "";

  const handleCodigoPostalChange = (event) => {
    const codigo = event.target.value;

    if (
      codigo === "" ||
      codigo === "0" ||
      (Number(codigo) && codigo.length <= 5)
    )
      setCodigoPostal(codigo);
  };

  const handleBuscar = async () => {
    if (codigoPostal.length < 5) {
      setcodigoPostalValidationMessage(
        "Escribe los 5 dígitos de tu código postal"
      );
      return;
    }
    setcodigoPostalValidationMessage("");
    //TODO: Buscar el código postal
    setShowFueraDelAreaDialog(false);
    setShowDentroDelAreaDialog(false);
    var dentro = await DentroAreaCobertura(codigoPostal);
    if (dentro === true) setShowDentroDelAreaDialog(true);
    else setShowFueraDelAreaDialog(true);
  };

  const handleFueraDelAreaClosed = () => {
    setShowFueraDelAreaDialog(false);
    props.onClose();
  };

  const handleDentroDelAreaClosed = () => {
    setShowDentroDelAreaDialog(false);
    props.onClose();
  };

  return (
    <div id="areaEntregaDialog">
      <Dialog
        open={props.open}
        onClose={props.onClose}
        onButtonClick={props.onClose}
        TransitionComponent={Transition}
      >
        <DialogContent>
          <h3>¿ A dónde te enviaremos tu pedido ?</h3>
          <h4>
            Ingresa tu código postal para ver si estás dentro de nuestra área de
            entrega
          </h4>

          <TextField
            autoFocus
            label="Código postal"
            onChange={handleCodigoPostalChange}
            value={codigoPostal}
            helperText={codigoPostalValidationMessage}
          />
          <DialogActions>
            <button
              className="areas"
              style={{ display: "block" }}
              onClick={handleBuscar}
              disabled={codigoPostal.length !== 5}
            >
              Buscar
            </button>
            <button
              className="botonCerrar material-icons"
              onClick={props.onClose}
            >
              clear
            </button>
          </DialogActions>
        </DialogContent>
      </Dialog>
      <DentroDelAreaDialog
        open={showDentroDelAreaDialog}
        onClose={handleDentroDelAreaClosed}
      />
      <FueraDelAreaDialog
        open={showFueraDelAreaDialog}
        onClose={handleFueraDelAreaClosed}
        codigoPostal={codigoPostal}
      />
    </div>
  );
};

export default AreaEntregaDialog;

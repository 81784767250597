import React from "react";
import { Checkbox, FormControlLabel } from "@material-ui/core";

const InputCheckBox = ({ name, label, checked, error, onChange, ...props }) => {
  return (
    <FormControlLabel
      control={<Checkbox checked={checked} onChange={onChange} name={name} />}
      label={label}
    />
  );
};

export default InputCheckBox;

import webAPIHelper from "../common/utils/webAPIHelper";

const controller = "/CodigosPostales";

export async function Colonias(codigoPostal) {
  if (codigoPostal === null || codigoPostal === "") return null;
  if (codigoPostal.length !== 5) return null;
  const appsvc = new webAPIHelper();
  const response = await appsvc.Get(`${controller}/${codigoPostal}/colonias`);

  return response;
}

export async function CodigosPostales(colonia) {
  if (colonia === null || colonia === "") return null;
  const appsvc = new webAPIHelper();
  const response = await appsvc.Get(`${controller}?colonia=${colonia}`);

  return response;
}

export async function DentroAreaCobertura(codigoPostal) {
  var result = await Colonias(codigoPostal);
  if (!result) return false;
  return result.length > 0;
}

import webAPIHelper from "../common/utils/webAPIHelper";
import moment from "moment";

const controller = "/Backoffice";

export async function LlenarPrecios() {
  const appsvc = new webAPIHelper();
  const response = await appsvc.Get(`${controller}/Precios`);

  return response;
}

export async function RecalcularPrecio(productoId, propiedad, valor) {
  const appsvc = new webAPIHelper();
  var request = {
    ProductoId: productoId,
    GuardarCambios: true,
  };
  if (propiedad === "costoUnitario") request.Costo = Number(valor);
  if (propiedad === "margen") request.Margen = Number(valor);
  const response = await appsvc.Put(`${controller}/Precios`, request);

  return response;
}

export async function LlenarProductos() {
  const appsvc = new webAPIHelper();
  const response = await appsvc.Get(`${controller}/Productos`);

  return response;
}

export async function GetProducto(productoId) {
  const appsvc = new webAPIHelper();
  const response = await appsvc.Get(`${controller}/Productos/${productoId}`);

  return response;
}

export async function PutProducto(producto) {
  const appsvc = new webAPIHelper();
  return await appsvc.Put(`${controller}/Productos/${producto.id}`, producto);
}

export async function PostProducto(producto) {
  const appsvc = new webAPIHelper();
  return await appsvc.Post(`${controller}/Productos`, producto);
}

export async function PedidosPorEntregar(fechaEntrega) {
  const appsvc = new webAPIHelper();
  return await appsvc.Get(
    `${controller}/Pedidos/PedidosPorEntregar?fechaEntrega=${fechaEntrega.format(
      "YYYY-MM-DD"
    )}`
  );
}

export async function GetPedido(PedidoId) {
  const appsvc = new webAPIHelper();
  return await appsvc.Get(`${controller}/Pedidos/Pedido/${PedidoId}`);
}

export async function CargarPedidosDeLaRuta(ruta) {
  const appsvc = new webAPIHelper();
  return await appsvc.Get(`${controller}/Pedidos/Ruta/${ruta.id}`);
}

export async function PutPedido(pedido, enviarCorreo) {
  const appsvc = new webAPIHelper();
  return await appsvc.Put(
    `${controller}/Pedidos/Pedido/${pedido.id}?enviarCorreo=${enviarCorreo}`,
    pedido
  );
}

export async function PutOrdenPreparacionPedido(pedidoId, ordenPreparacion) {
  const appsvc = new webAPIHelper();
  return await appsvc.Put(
    `${controller}/Pedidos/Pedido/${pedidoId}/OrdenPreparacion/${ordenPreparacion}`
  );
}

export async function DeletePedido(pedidoId, razones) {
  const appsvc = new webAPIHelper();
  return await appsvc.Delete(`${controller}/Pedidos/Pedido/${pedidoId}`, {
    Razones: razones,
  });
}

export async function PrintPedido(pedidoId) {
  const appsvc = new webAPIHelper();
  return await appsvc.GetBlob(`${controller}/Pedidos/PrintPedido/${pedidoId}`);
}

export async function UploadImagenProducto(productoId, imageToUpload) {
  const formData = new FormData();
  formData.append("file", imageToUpload);
  const appsvc = new webAPIHelper();
  const config = {
    headers: {
      "content-type": "multipart/form-data",
    },
  };
  return await appsvc.Post(
    `${controller}/Productos/${productoId}/Imagen`,
    formData,
    config
  );
}

export async function GetOrdenesCompra(fecha) {
  const appsvc = new webAPIHelper();
  var parameter = "";
  if (fecha) parameter = `?fecha=${moment(fecha).format("yyyy-MM-DD")}`;
  return await appsvc.Get(`${controller}/compras/ordencompra${parameter}`);
}

export async function RegistrarCompraSurtida(
  ordenCompraId,
  partidaId,
  cantidad,
  precio,
  referencia
) {
  const appsvc = new webAPIHelper();
  console.log({
    PartidaId: partidaId,
    CantidadSurtida: cantidad,
    precioSurtido: precio,
    referencia: referencia,
  });
  return await appsvc.Put(
    `${controller}/compras/OrdenCompra/${ordenCompraId}/Partida`,
    {
      PartidaId: partidaId,
      CantidadSurtida: cantidad,
      precioSurtido: precio,
      referencia: referencia,
    }
  );
}

export async function AnalisisCompras(fecha) {
  const appsvc = new webAPIHelper();
  return await appsvc.Get(
    `${controller}/compras/AnalisisCompras?fecha=${fecha.format("YYYY-MM-DD")}`
  );
}

export async function ListaProveedores() {
  const appsvc = new webAPIHelper();
  return await appsvc.Get(`${controller}/proveedores`);
}

export async function PostProveedor(proveedor) {
  const appsvc = new webAPIHelper();
  return await appsvc.Post(`${controller}/proveedores`, proveedor);
}

export async function PutProveedor(proveedor) {
  const appsvc = new webAPIHelper();
  return await appsvc.Put(`${controller}/proveedores`, proveedor);
}

export async function Login(userName, password) {
  const appsvc = new webAPIHelper();
  return await appsvc.Post(`/controlacceso/login`, {
    nombreUsuario: userName,
    password,
  });
}

export async function Logout(sessionId) {
  const appsvc = new webAPIHelper();
  let response = await appsvc.Post(
    `/controlacceso/session/${sessionId}/logout`
  );
}

export async function TienePermiso(sessionId, permisoClave) {
  if (!sessionId) return "No ha iniciado sesión";
  const appsvc = new webAPIHelper();
  try {
    let response = await appsvc.Get(
      `/controlacceso/session/${sessionId}/Permiso?clave=${permisoClave}`
    );
    if (response) return "";
    else return "No tiene permiso para esta opción";
  } catch (error) {
    if (error.response) {
      if (error.response.status === 401) return "Credenciales no válidas";

      if (error.response.status === 440)
        return "Ha caducado la sesión, vuelva a firmarse";

      return "Acceso denegado";
    } else return "Ocurrió un error al validar permiso";
  }
}

export async function CargarUsuarios() {
  const appsvc = new webAPIHelper();
  return await appsvc.Get("/controlacceso/usuario");
}

export async function PostUsuario(usuario) {
  const appsvc = new webAPIHelper();
  return await appsvc.Post("/controlacceso/usuario", usuario);
}

export async function PutUsuario(usuario) {
  const appsvc = new webAPIHelper();
  return await appsvc.Put("/controlacceso/usuario", usuario);
}

export async function ListaPerfilesUsuario() {
  const appsvc = new webAPIHelper();
  return await appsvc.Get(`/controlacceso/perfil`);
}

export async function PostPerfilUsuario(perfil) {
  const appsvc = new webAPIHelper();
  return await appsvc.Post("/controlacceso/perfil", perfil);
}

export async function PutPerfilUsuario(usuario) {
  const appsvc = new webAPIHelper();
  return await appsvc.Put("/controlacceso/perfil", usuario);
}

export async function PermisosPerfilUsuario(perfil) {
  const appsvc = new webAPIHelper();
  return await appsvc.Get(`/controlacceso/perfil/${perfil.id}/permiso`);
}

export async function ListaPermisos() {
  const appsvc = new webAPIHelper();
  return await appsvc.Get("/controlacceso/permiso");
}

export async function PatchPermisosPerfilUsuario(perfil, permisos) {
  const appsvc = new webAPIHelper();
  return await appsvc.Patch(
    `/controlacceso/perfil/${perfil.id}/permiso`,
    permisos
  );
}

export async function CambiarPedidoCheckLiquidacion(pedidoId, check) {
  const appsvc = new webAPIHelper();
  return await appsvc.Patch(
    `${controller}/Pedidos/Pedido/${pedidoId}/CheckLiquidacion/${check}`
  );
}

export async function DiaInhabil(fechaPedido) {
  const appsvc = new webAPIHelper();
  var requestUrl = `${controller}/Inhabiles/${fechaPedido.format(
    "YYYY-MM-DD"
  )}`;
  const response = await appsvc.Get(requestUrl);
  return response;
}

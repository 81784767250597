import React, { Component } from "react";

import DxGrid from "../../common/utils/dxGrid";
import { Button } from "@material-ui/core";

import {
  LlenarProductos,
  TienePermiso,
} from "../../services/backofficeService";
import BackofficeBanner from "../backofficeBanner";
import UpdateProducto from "./updateProducto";

class Productos extends Component {
  state = {
    todosLosProductos: [],
    productos: [],
    searchText: "",
    showBackdrop: true,
    producto: {},
    editAction: undefined,
  };

  async componentDidMount() {
    var respuesta = await TienePermiso(
      this.props.sessionId,
      "Catálogos/Productos/ABC"
    );
    if (respuesta !== "") {
      alert(respuesta);
      window.location.href = "/backoffice";
      return;
    }

    await this.CargarProductos();
  }

  async CargarProductos() {
    var productos = await LlenarProductos();
    this.setState({
      todosLosProductos: productos,
      productos: productos,
      showBackdrop: false,
    });
  }

  columns = [
    {
      name: "categoriaProducto.nombre",
      title: "Categoría",
      getCellValue: (row) => row.categoriaProducto.nombre,
    },
    { name: "id", title: "Id" },
    { name: "descripcion", title: "Descripción", width: "auto" },
    { name: "unidad", title: "Unidad" },
    { name: "pesoPorPieza", title: "Peso x Pieza" },
    { name: "intervalo", title: "Intervalo" },
    { name: "costoUnitario", title: "Costo" },
    { name: "margen", title: "Márgen" },
    { name: "precio", title: "Precio" },
    { name: "activo", title: "activo" },
    { name: "agotado", title: "Agotado" },
  ];

  defaultColumnWidths = [
    { columnName: "categoriaProducto.nombre", width: 100 },
    { columnName: "id", width: 100 },
    { columnName: "descripcion", width: 250 },
    { columnName: "unidad", width: 100 },
    { columnName: "pesoPorPieza", width: 100 },
    { columnName: "intervalo", width: 100 },
    { columnName: "costoUnitario", width: 100 },
    { columnName: "margen", width: 100 },
    { columnName: "precio", width: 100 },
    { columnName: "activo", width: 100 },
    { columnName: "agotado", width: 100 },
  ];

  render() {
    return (
      <div className="App">
        <BackofficeBanner
          title="Catálogo de productos"
          showProgress={this.state.showBackdrop}
        />

        <div className="container">
          <Button
            variant="contained"
            color="primary"
            onClick={() => this.setState({ producto: {}, editAction: "add" })}
          >
            Agregar producto
          </Button>
          <DxGrid
            rows={this.state.productos}
            columns={this.columns}
            defaultColumnWidths={this.defaultColumnWidths}
            height={500}
            onRowClick={async (row) => {
              this.setState({ producto: row, editAction: "edit" });
            }}
            showFilterSelector={false}
          />
        </div>

        <UpdateProducto
          producto={this.state.producto}
          editAction={this.state.editAction}
          onChange={() => this.setState({ ...this.state.producto })}
          onHide={() => this.setState({ editAction: undefined })}
          onSave={async () => {
            if (this.state.editAction === "add") await this.CargarProductos();
            // En altas vuelve a cargar desde bd
            else {
              const prods = [...this.state.productos]; // Crea una copia del arreglo
              const prodIndex = prods.findIndex(
                (p) => p.id === this.state.producto.id
              );
              prods[prodIndex] = this.state.producto;
              this.setState({ productos: prods });
            }
            this.setState({ editAction: undefined, proveedor: undefined });
          }}
        />
      </div>
    );
  }
}

export default Productos;

import React, { useEffect, useState } from "react";

import { Card, DropdownButton, Dropdown, Button } from "react-bootstrap";
import styles from "./rutas.css";
import { GeoFill, Search } from "react-bootstrap-icons";

import VerPedidoRuta from "./verPedidoRuta";

const PedidosPorAsignar = ({
  planeacion,
  onPedidoSetRuta,
  onPedidoUbicacionChanged,
  onCenterMap,
}) => {
  const [pedidosPorAsignar, setPedidosPorAsignar] = useState();
  const [pedidoConsultar, setPedidoConsultar] = useState();
  useEffect(() => {
    let ped = planeacion.pedidos.filter((p) => !p.rutaEnvio);
    setPedidosPorAsignar(ped);
  }, [planeacion]);

  if (!pedidosPorAsignar)
    return (
      <div className="sectionHeader">
        <h5>
          <i>No hay pedidos por asignar</i>
        </h5>
      </div>
    );
  return (
    <div id="pedidosPorAsignar">
      <div className="sectionHeader">
        <h5>
          <strong>{pedidosPorAsignar.length}</strong>&nbsp; Pedidos por asignar
        </h5>
      </div>
      <div>
        {pedidosPorAsignar.map((pedido) => (
          <Card key={pedido.id} className="cardStyle">
            <Card.Body>
              <Card.Title>
                {pedido.id} {pedido.nombreCliente}
              </Card.Title>
              <div className="cardStyle-subTitle">
                <div id="pedidosPorAsignar-Domicilio">
                  <p>{pedido.domicilio}</p>
                </div>
                <div id="pedidosPorAsignar-buttons">
                  <Button
                    id="pedidosPorAsignar-btnNavegacion"
                    onClick={() => onCenterMap(pedido)}
                  >
                    <GeoFill color="white" size="20px" />
                  </Button>
                  <Button
                    id="pedidosPorAsignar-btnVerPedido"
                    variant="info"
                    onClick={() => setPedidoConsultar(pedido)}
                  >
                    <Search color="white" size="20px" />
                  </Button>
                  <DropdownButton
                    id="pedidosPorAsignar-btnAsignarRuta"
                    title="Asignar ruta"
                    disabled={planeacion.rutas.length === 0}
                  >
                    {planeacion.rutas.map((ruta) => (
                      <Dropdown.Item
                        key={ruta.id}
                        onClick={(e) => onPedidoSetRuta(pedido, ruta)}
                      >
                        {ruta.nombre}
                      </Dropdown.Item>
                    ))}
                  </DropdownButton>
                </div>
              </div>
            </Card.Body>
          </Card>
        ))}
      </div>
      {pedidoConsultar && (
        <VerPedidoRuta
          pedido={pedidoConsultar}
          onHide={() => {
            setPedidoConsultar(null);
          }}
          onPedidoUbicacionChanged={onPedidoUbicacionChanged}
        />
      )}
    </div>
  );
};

export default PedidosPorAsignar;

import webAPIHelper from "../../common/utils/webAPIHelper";
const controller = "/Backoffice/FormaPago";

export async function GetFormasDePago() {
  let cache = sessionStorage.formasDePago;
  if (cache) return JSON.parse(cache);

  const appsvc = new webAPIHelper();
  const response = await appsvc.Get(controller);

  console.log("to be cached", response);
  sessionStorage.formasDePago = JSON.stringify(response);

  return response;
}

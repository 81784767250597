import React, { useEffect, useState } from "react";

import { Select, InputLabel, MenuItem } from "@material-ui/core";

import { ListaProveedores } from "../../services/backofficeService";

const ProveedorCombo = ({ value, onChange }) => {
  const [proveedores, setProveedores] = useState([]);

  const cargarProveedores = async () => {
    const listaProveedores = await ListaProveedores();
    listaProveedores.sort((a, b) => (a.nombre > b.nombre ? 1 : -1));
    setProveedores(listaProveedores);
  };

  useEffect(() => {
    cargarProveedores();
  }, []);

  return (
    <div>
      <InputLabel id="proveedorCombo-Label">Proveedor</InputLabel>
      <Select
        labelId="proveedorCombo-Label"
        id="proveedorCombo"
        value={value ? value : " "}
        onChange={onChange}
      >
        {proveedores.map((proveedor) => (
          <MenuItem key={proveedor.id} value={proveedor.id}>
            {proveedor.nombre}
          </MenuItem>
        ))}
      </Select>
    </div>
  );
};

export default ProveedorCombo;

import webAPIHelper from "../../common/utils/webAPIHelper";
const controller = "/Backoffice/Inventarios";

export async function CargarListaInventariosFisicos() {
  const appsvc = new webAPIHelper();
  const response = await appsvc.Get(`${controller}/InventariosFisicos`);
  return response;
}

export async function CrearInventarioFisico() {
  const appsvc = new webAPIHelper();
  const response = await appsvc.Get(`${controller}/InventarioFisico`);
  return response;
}

export async function CargarInventarioFisico(inventarioFisicoId) {
  const appsvc = new webAPIHelper();
  const response = await appsvc.Get(
    `${controller}/InventarioFisico/${inventarioFisicoId}`
  );
  return response;
}

export async function AplicarInventarioFisico(inventarioFisicoId) {
  const appsvc = new webAPIHelper();
  const response = await appsvc.Patch(
    `${controller}/InventarioFisico/${inventarioFisicoId}`
  );
  return response;
}

export async function GuardarInventarioFisico(inventario) {
  const appsvc = new webAPIHelper();
  if (inventario.id === 0) {
    return await appsvc.Post(`${controller}/InventarioFisico`, inventario);
  } else {
    return await appsvc.Put(
      `${controller}/InventarioFisico/${inventario.id}`,
      inventario
    );
  }
}

export async function GetExistencias() {
  const appsvc = new webAPIHelper();
  const response = await appsvc.Get(`${controller}/Existencias`);
  return response;
}

import webAPIHelper from "../common/utils/webAPIHelper";
import { GetSessionId } from "../common/utils/sessionHelper";

const controller = "/Productos";

export async function ProductosPorCategoria(idCategoria) {
  return await InvocarProductos(
    `${controller}?sessionId=${GetSessionId()}&idCategoria=${idCategoria}`
  );
}

export async function ProductosBuscar(textoBuscar) {
  return await InvocarProductos(
    `${controller}?sessionId=${GetSessionId()}&buscar=${textoBuscar}`
  );
}

async function InvocarProductos(url) {
  const appsvc = new webAPIHelper();
  const response = await appsvc.Get(url);
  if (response === null) return null;

  return response;
}

import React, { useEffect, useState } from "react";

import { Card } from "react-bootstrap";
import { useLocation } from "react-router";

import { GetMisRutas } from "../../../../services/backoffice/rutasServices";
import { GetRouteColor } from "../rutasHelpers";
import { Backdrop, CircularProgress } from "@material-ui/core";
import MiRuta from "./miRuta";

import "../rutas.css";
import moment from "moment";

const MisRutas = ({ sessionId }) => {
  const [misRutas, setMisRutas] = useState();
  const [miRuta, setMiRuta] = useState();
  const [fechaEntrega, setFechaEntrega] = useState();

  let query = new URLSearchParams(useLocation().search);

  useEffect(() => {
    const cargarMisRutas = async () => {
      let fecha = query.get("fecha");
      if (!fecha) fecha = moment();

      console.log("fecha", fecha);
      let rutasCargadas = await GetMisRutas(sessionId, fecha);

      setMisRutas(rutasCargadas);
      setFechaEntrega(fecha);
    };

    cargarMisRutas();
  }, [sessionId]);

  if (!misRutas)
    return (
      <Backdrop open={true}>
        <CircularProgress color="inherit" />
      </Backdrop>
    );

  return (
    <div>
      <div className="sectionHeader">
        <h5>Mis Rutas ({misRutas.length})</h5>
        <h6>{fechaEntrega && moment(fechaEntrega).format("dddd D MMM")}</h6>
      </div>
      <div id="misRutas-Rutas">
        {misRutas.map((ruta) => (
          <Card
            key={ruta.rutaId}
            id="misRutas-Ruta"
            onClick={(e) => setMiRuta(ruta)}
          >
            <Card.Body>
              <div id="misRutas-Ruta-Titulo" className="flex-container">
                <div
                  id="misRutas-Ruta-Pin"
                  style={{
                    backgroundColor: GetRouteColor(ruta),
                  }}
                />

                <div
                  id="misRutas-Ruta-Titulo1"
                  className="flex-container column"
                >
                  R{ruta.numero}-{ruta.nombre}
                </div>
              </div>
              <p id="misRutas-Ruta-Titulo2">{ruta.pedidos.length} pedidos</p>
            </Card.Body>
          </Card>
        ))}
      </div>
      {miRuta && (
        <MiRuta
          ruta={miRuta}
          onHide={() => setMiRuta()}
          onRutaChanged={() => setMiRuta({ ...miRuta })}
        />
      )}
    </div>
  );
};

export default MisRutas;

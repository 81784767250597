import React, { useState, useEffect } from "react";
import { Form } from "react-bootstrap";
import NumberFormat from "react-number-format";
import { CalcularPagosPedido } from "../../../../services/backoffice/bakofficeHelpers";

import { GetFormasDePago } from "../../../../services/backoffice/formasPagoServices";
import EntregarPedidoPago from "./entregarPedidoPago";

const EntregarPedidoPagos = ({ pedido, onPedidoChanged }) => {
  const [inicializado, setInicializado] = useState(false);
  const [totalPagos, setTotalPagos] = useState(0);
  const [cambio, setCambio] = useState(0);
  const [porPagar, setPorPagar] = useState(0);
  const [tienePagosTarjeta, setTienePagosTarjeta] = useState(false);

  useEffect(() => {
    inicializarPagos();
  }, []);

  useEffect(() => {
    // Si cambia el total Entregado del pedido, recalcula x pagar, cambio, etc
    if (inicializado) calcularPagos();
  }, [pedido.totalEntregado]);

  const inicializarPagos = async () => {
    // Se asegura que tenga hasta 3 formas de pago
    if (pedido.pagos.length < 3) {
      const formasPagoCargadas = await GetFormasDePago();
      let pagoId = 0;
      formasPagoCargadas.map((formaPago) => {
        pagoId++;
        if (pedido.pagos.length < 3)
          pedido.pagos.push({
            id: pagoId,
            formaPago: formaPago,
            monto: undefined,
          });
      });
    }
    calcularPagos();

    setInicializado(true);
  };

  const calcularPagos = () => {
    let info = CalcularPagosPedido(pedido);

    pedido.cambio = info.cambio;
    pedido.porPagar = info.porPagar;

    if (info.sumaPagosOtros > 0) {
      setTienePagosTarjeta(true);
    } else {
      pedido.propinaTarjeta = 0;
      setTienePagosTarjeta(false);
    }

    setTotalPagos(info.sumaPagos);
    setPorPagar(info.porPagar);
    setCambio(pedido.cambio);
  };

  if (!inicializado) return <div />;

  return (
    <div>
      <h5>Pagos</h5>
      {pedido.pagos.map((pago) => (
        <EntregarPedidoPago
          key={pago.id}
          pago={pago}
          onChange={() => calcularPagos()}
        />
      ))}
      <div>
        <div className="flex flex-end">
          <div>Total de pagos:</div>
          <div>
            <strong>
              <NumberFormat
                value={totalPagos}
                displayType="text"
                decimalScale="2"
                fixedDecimalScale="2"
                thousandSeparator
              />
            </strong>
          </div>
        </div>
        <div className="flex flex-end">
          <div>Cambio:</div>
          <div>
            <strong>
              <NumberFormat
                value={cambio}
                displayType="text"
                decimalScale="2"
                fixedDecimalScale="2"
                thousandSeparator
              />
            </strong>
          </div>
        </div>
        <div className="flex flex-end">
          <div>Por pagar:</div>
          <div>
            <strong>
              <NumberFormat
                value={porPagar}
                displayType="text"
                decimalScale="2"
                fixedDecimalScale="2"
                thousandSeparator
              />
            </strong>
          </div>
        </div>
      </div>
      <div>
        <div className="flex flex-end">
          <div>Propina con tarjeta</div>
          <Form.Control
            type="number"
            pattern="[0-9]*"
            inputMode="decimal"
            placeholder="Propina con tarjeta"
            value={pedido.propinaTarjeta}
            onChange={(e) => {
              pedido.propinaTarjeta = Number(e.currentTarget.value);
              onPedidoChanged();
            }}
            disabled={!tienePagosTarjeta}
          />
        </div>
      </div>
    </div>
  );
};

export default EntregarPedidoPagos;

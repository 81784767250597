import React, { Component } from "react";

import LandingPageBanner from "./landingPageBanner";
import LandingPageCategoriesList from "./landingPageCategoriesList";

import { ContadorItems } from "../../services/pedidoService";

class LandingPage extends Component {
  state = { pedidoContadorItems: 0 };
  async componentDidMount() {
    if (this.state.pedidoContadorItems === 0 && this.props.onCantidadChanged) {
      try {
        const contadorItems = await ContadorItems();
        this.setState({ pedidoContadorItems: contadorItems });
        this.props.onCantidadChanged(0, contadorItems);
      } catch (error) {
        //TODO: Log
        console.log(error);
      }
    }
  }
  render() {
    return (
      <div className="div100" id="landingPage">
        <LandingPageBanner />
        <div className="cont">
          <div className="contF" style={{ marginTop: "-150px" }}>
            <div className="clear20"></div>
            <div
              className="div100 padd10 centrado titulos20 blanco sombra"
              style={{ fontWeight: 600 }}
            >
              Elige los productos y los enviamos a tu casa, paga al recibirlos
              con efectivo o tarjeta.
            </div>
            <div className="clear20"></div>
            <LandingPageCategoriesList />

            <div className="clear30"></div>

            <div className="div100 centrado padd10 titulos40 verde">
              ¿Cómo funciona?
            </div>
            <div className="div100" id="elProceso">
              <div className="proceso">
                <img
                  src="/img/landingPage/proceso_revisa.svg"
                  height="120"
                ></img>
                <div className="clear"></div>
                <div className="procesoTit">Encuentra ..</div>
                <div className="div100">
                  Encuentra los productos más frescos y a los mejores precios.
                  <br />
                  Busca por categoría o busca por cualquier palabra.
                </div>
              </div>

              <div className="proceso">
                <img
                  src="/img/landingPage/proceso_selecciona.svg"
                  height="120"
                ></img>
                <div className="clear"></div>
                <div className="procesoTit">Selecciona ..</div>
                <div className="div100">
                  Selecciona los productos que deseas e indica la cantidad que
                  deseas de cada producto y añadela a tu carrito.
                </div>
              </div>

              <div className="proceso">
                <img
                  src="/img/landingPage/proceso_recibe.svg"
                  height="120"
                ></img>
                <div className="clear"></div>
                <div className="procesoTit">Recibe ..</div>
                <div className="div100">
                  Realiza tu pedido antes de las <b>10:00 PM</b>, y lo tendrás
                  en tu puerta al siguiente día hábil (con entrega de lunes a
                  sábado).
                </div>
              </div>
            </div>

            <div className="clear50"></div>

            <div className="div100 centrado padd10 titulos40 verde">
              Beneficios
            </div>
            <div className="div100" id="lasVentajas">
              <div className="ventajas">
                <div className="ventajasInt">
                  <img
                    src="/img/landingPage/icono_calidad.svg"
                    height="120"
                  ></img>
                  <div className="clear"></div>
                  <div className="ventajasTit">Calidad ..</div>
                  <div className="div100">
                    Llevamos hasta tu puerta los productos más frescos y de
                    mejor calidad. Directo del mercado de Abastos, apoyando a
                    productores y comercio local.
                  </div>
                </div>
              </div>

              <div className="ventajas">
                <div className="ventajasInt">
                  <img
                    src="/img/landingPage/icono_frescura.svg"
                    height="120"
                  ></img>
                  <div className="clear"></div>
                  <div className="ventajasTit">Frescura ..</div>
                  <div className="div100">
                    La frescura del mercado a la puerta de tu casa.{" "}
                    <b>#QuedateEnCasa</b>
                  </div>
                </div>
              </div>

              <div className="ventajas">
                <div className="ventajasInt">
                  <img
                    src="/img/landingPage/icono_entrega.svg"
                    height="120"
                  ></img>
                  <div className="clear"></div>
                  <div className="ventajasTit">Entrega GRATIS</div>
                  <div className="div100">
                    Disfruta, por tiempo limitado, entregas en toda la zona
                    metropolitana de Guadalajara <b>SIN COSTO</b>
                  </div>
                </div>
              </div>

              <div className="ventajas">
                <div className="ventajasInt">
                  <img src="/img/landingPage/icono_pago.svg" height="120"></img>
                  <div className="clear"></div>
                  <div className="ventajasTit">Pago ..</div>
                  <div className="div100">
                    Paga tu pedido al recibirlo, en efectivo o con tarjeta de
                    débito o crédito.
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default LandingPage;

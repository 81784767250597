import { Backdrop, CircularProgress } from "@material-ui/core";
import React, { useState, useEffect } from "react";
import { CargarUsuarios, TienePermiso } from "../../services/backofficeService";
import BackofficeBanner from "../backofficeBanner";
import UpdateUsuario from "./updateUsuario";

const UsuariosPage = ({ sessionId }) => {
  const [showBackdrop, setShowBackdrop] = useState(true);
  const [usuarios, setUsuarios] = useState([]);
  const [usuario, setUsuario] = useState({});
  const [editAction, setEditAction] = useState();
  useEffect(() => {
    cargarUsuarios();
  }, []);

  const cargarUsuarios = async () => {
    let respuesta = await TienePermiso(sessionId, "Catálogos/Usuarios/ABC");
    if (respuesta !== "") {
      alert(respuesta);
      window.location.href = "/backoffice";
      return;
    }

    let listaUsuarios = await CargarUsuarios();
    listaUsuarios.sort((a, b) => (a.nombre > b.nombre ? 1 : -1));
    setUsuarios(listaUsuarios);
    setShowBackdrop(false);
  };

  if (!usuarios)
    return (
      <div>
        <Backdrop open={true}>
          <CircularProgress color="inherit" />
        </Backdrop>
      </div>
    );

  return (
    <div className="App">
      <BackofficeBanner
        title="Catálogo de usuarios"
        showProgress={showBackdrop}
      />
      <div className="container">
        <table className="table">
          <thead className="thead-dark">
            <tr>
              <th>Nombre</th>
              <th>Perfil</th>
              <th>
                <button
                  className="btn btn-success"
                  onClick={() => {
                    setUsuario({
                      nombre: "",
                      perfilUsuarioId: 0,
                      password: "",
                      eMail: "",
                    });
                    setEditAction("add");
                  }}
                >
                  Agregar
                </button>
              </th>
            </tr>
          </thead>
          <tbody>
            {usuarios.map((usuario) => (
              <tr key={usuario.id}>
                <td className="text-left">{usuario.nombre}</td>
                <td className="text-left">{usuario.perfilUsuarioNombre}</td>
                <td>
                  <button
                    className="btn btn-primary"
                    onClick={() => {
                      setUsuario(usuario);
                      setEditAction("edit");
                    }}
                  >
                    Editar
                  </button>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>

      <UpdateUsuario
        usuario={usuario}
        editAction={editAction}
        onChange={() => setUsuario({ ...usuario })}
        onHide={() => setEditAction()}
        onSave={async () => {
          const users = [...usuarios];
          const userIndex = users.findIndex((u) => u.id === usuario.id);
          if (userIndex === -1) users.push(usuario);
          else users[userIndex] = usuario;
          users.sort((a, b) => (a.nombre > b.nombre ? 1 : -1));
          setUsuarios(users);
          setEditAction();
          setUsuario();
        }}
      />
    </div>
  );
};

export default UsuariosPage;

import React, { Component } from "react";
import { TabPane } from "react-bootstrap";
import { RegistrarEvento } from "../../services/eventosServices";
import {
  AgregarAlPedido,
  QuitarDelPedido,
  EliminarDelPedido,
} from "../../services/pedidoService";

class AddToCartButton extends Component {
  state = { cantidad: 0, intervalo: 0, unidad: "", deshabilitado: false };

  componentDidMount() {
    this.setState({
      cantidad: this.props.cantidad,
      intervalo: this.props.intervalo,
      unidad: this.props.unidad,
      deshabilitado: this.props.deshabilitado,
    });
  }

  async handleAgregarAlCarrito(tipoEvento) {
    try {
      const respuesta = await AgregarAlPedido(
        this.props.idProducto,
        this.props.intervalo
      );
      this.setState({ cantidad: respuesta.cantidadPartida });
      if (this.props.onCantidadChanged != null)
        this.props.onCantidadChanged(
          respuesta.cantidadPartida,
          respuesta.totalDeItemsPedido
        );
      var registrarEventoData = {
        descripcion: this.props.descripcion,
      };
      if (tipoEvento === 8)
        RegistrarEvento(
          tipoEvento,
          `${this.props.idProducto},${respuesta.cantidadPartida}`,
          registrarEventoData
        );
      else
        RegistrarEvento(tipoEvento, this.props.idProducto, registrarEventoData);
    } catch (error) {
      //TODO: Cambiar por un dialog
      //TODO: Como loggear esto en un repositorio nuestro y obtener alertas cualquier error en este punto tenemos que resolverlo nosotros
      console.log(error);
      alert(
        "No fue posible agregar el producto al pedido. Por favor intente de nuevo."
      );
    }
  }

  async handleQuitarDelCarrito() {
    try {
      const respuesta = await QuitarDelPedido(
        this.props.idProducto,
        this.props.intervalo
      );
      this.setState({ cantidad: respuesta.cantidadPartida });
      if (this.props.onCantidadChanged != null)
        this.props.onCantidadChanged(
          respuesta.cantidadPartida,
          respuesta.totalDeItemsPedido,
          this.props.idProducto
        );

      await RegistrarEvento(
        10,
        `${this.props.idProducto},${respuesta.cantidadPartida}`
      );
      var element = document.getElementById(
        "productosInt" + this.props.idProducto
      );
      if (element) element.classList.remove("productosP");
    } catch (error) {
      //TODO: Cambiar por un dialog
      //TODO: Como loggear esto en un repositorio nuestro y obtener alertas cualquier error en este punto tenemos que resolverlo nosotros
      console.log(error);
      alert(
        "No fue posible disminuir del producto al pedido. Por favor intente de nuevo."
      );
    }
  }

  async handleEliminarPartida() {
    try {
      const respuesta = await EliminarDelPedido(this.props.idProducto);
      this.setState({ cantidad: respuesta.cantidadPartida });
      if (this.props.onCantidadChanged != null)
        this.props.onCantidadChanged(
          respuesta.cantidadPartida,
          respuesta.totalDeItemsPedido,
          this.props.idProducto
        );

      await RegistrarEvento(12, this.props.idProducto);

      var element = document.getElementById(
        "productosInt" + this.props.idProducto
      );
      if (element) element.classList.remove("productosP");
    } catch (error) {
      //TODO: Cambiar por un dialog
      //TODO: Como loggear esto en un repositorio nuestro y obtener alertas cualquier error en este punto tenemos que resolverlo nosotros
      console.log(error);
      alert(
        "No fue posible quitar el producto del pedido. Por favor intente de nuevo."
      );
    }
  }

  render() {
    if (this.state.cantidad === 0) {
      return (
        <button
          className="botonAgregar"
          onClick={() => this.handleAgregarAlCarrito(7)}
          disabled={this.state.deshabilitado}
        >
          <span className="material-icons">add</span>{" "}
          <div className="div100">Agregar</div>
        </button>
      );
    } else {
      var element = document.getElementById(
        "productosInt" + this.props.idProducto
      );
      if (element) element.classList.add("productosP");

      return (
        <div className="botonCant">
          <button
            disabled={this.state.deshabilitado}
            className="botonCant1"
            onClick={() => this.handleQuitarDelCarrito()}
          >
            -
          </button>
          <span className="botonCantT">
            {this.state.cantidad} {/* this.state.unidad */}
          </span>
          <button
            className="botonCant1"
            onClick={() => this.handleAgregarAlCarrito(8)}
          >
            +
          </button>
          <button
            className="botonCantDel material-icons"
            onClick={() => this.handleEliminarPartida()}
          >
            delete
          </button>
        </div>
      );
    }
  }
}

export default AddToCartButton;
